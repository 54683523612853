/*
 *
 * LiveStream actions
 *
 */

import {
  CHANGE_LIVE_STREAM_PROVIDER,
  CHANGE_ROOM,
  CLEAR_GROUP_CALL_INFO,
  CLEAR_LIVE_STREAM_INFO,
  END_STREAM,
  ENTER_GROUP_CALL_SUCCESS,
  ENTER_LIVE_STREAM,
  ENTER_LIVE_STREAM_SUCCESS,
  GET_ENTER_LIVE_STREAM_INFO,
  GET_LIVE_STREAM_INFO,
  GROUP_CALL_ACTION,
  GROUP_CALL_MEMBER_STATUS,
  GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED,
  GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE,
  KEEP_STREAM,
  KEEP_STREAM_POLLING,
  KEEP_VIEW_STREAM_POLLING,
  LEAVE_LIVE_STREAM,
  LEFT_LIVE_STREAM,
  ON_RECEIVED_LIVE_STREAM_INFO,
  PUBLISH_STREAM,
  RECEIVE_MARQUEE,
  RECEIVED_CONCERT_INFO,
  RECEIVED_KEEP_STREAM,
  RECEIVED_KEEP_VIEW_RESULT,
  RECEIVED_LIVE_STREAM_INFO,
  RUN_KEEP_VIEW_STREAM_POLLING,
  SET_COMMENT_ERROR,
  SET_GROUP_CALL_WRAPPER_HOVER,
  SET_IS_LIVE_STREAM_LOADING,
  SET_ROOMID_ALIVE_CALLED,
  SUBSCRIBE_GLOBAL_CHANNEL,
  UNLOCK_PREMIUM_ROOM,
  UPDATE_BARRAGE_INFOS,
  UPDATE_LIVE_STREAM_STATUS,
} from './constants';

export const setGroupCallRaiseHandQRCodeIsOpenedOnce = (payload: boolean) => ({
  type: GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED_ONCE,
  payload,
});

export const setGroupCallRaiseHandQRCodeIsOpened = (payload: boolean) => ({
  type: GROUP_CALL_SET_RAISE_HAND_QRCODE_IS_OPENED,
  payload,
});

export const setCommentError = payload => ({
  type: SET_COMMENT_ERROR,
  payload,
});

export const receiveMarquee = (payload = { roomID: {}, message: {} }) => ({
  type: RECEIVE_MARQUEE,
  payload,
});

export const unlockPremiumRoom = (roomID: string) => ({
  type: UNLOCK_PREMIUM_ROOM,
  payload: roomID,
});

export const getLiveStreamInfo = (roomID, additionalInfo = {}) => ({
  type: GET_LIVE_STREAM_INFO,
  payload: {
    roomID,
    additionalInfo,
  },
});

export const getEnterLiveStreamInfo = (
  roomID: string,
  additionalInfo = {}
) => ({
  type: GET_ENTER_LIVE_STREAM_INFO,
  payload: {
    roomID,
    additionalInfo,
  },
});

export const receivedLiveStreamInfo = payload => ({
  type: RECEIVED_LIVE_STREAM_INFO,
  payload,
});

export const clearLiveStreamInfoByRoomID = roomID => ({
  type: CLEAR_LIVE_STREAM_INFO,
  payload: roomID,
});

export const changeLiveStreamProvider = payload => ({
  type: CHANGE_LIVE_STREAM_PROVIDER,
  payload,
});

export const receivedKeepViewResult = payload => ({
  type: RECEIVED_KEEP_VIEW_RESULT,
  payload,
});

export const updateBarrageInfos = payload => ({
  type: UPDATE_BARRAGE_INFOS,
  payload,
});

export const onReceivedLiveStreamInfo = payload => ({
  type: ON_RECEIVED_LIVE_STREAM_INFO,
  payload,
});

export const enterLiveStream = (roomID, customParameters) => ({
  type: ENTER_LIVE_STREAM,
  payload: { roomID, customParameters },
});

export const enterLiveStreamSuccess = payload => ({
  type: ENTER_LIVE_STREAM_SUCCESS,
  payload,
});

export const leaveLiveStream = roomID => ({
  type: LEAVE_LIVE_STREAM,
  payload: roomID,
});

export const leftLiveStream = roomID => ({
  type: LEFT_LIVE_STREAM,
  payload: roomID,
});

export const publishStream = (liveStreamID, roomID) => ({
  type: PUBLISH_STREAM,
  payload: { liveStreamID, roomID },
});

export const endStream = (liveStreamID, roomID) => ({
  type: END_STREAM,
  payload: { liveStreamID, roomID },
});

export const keepStream = (liveStreamID, roomID) => ({
  type: KEEP_STREAM,
  payload: { liveStreamID, roomID },
});

export const keepStreamPolling = (liveStreamID, roomID) => ({
  type: KEEP_STREAM_POLLING,
  payload: { liveStreamID, roomID },
});

export const keepViewStreamPolling = (roomID, isConcert) => {
  return {
    type: KEEP_VIEW_STREAM_POLLING,
    payload: { roomID, isConcert },
  };
};

export const receivedKeepStreamResult = payload => ({
  type: RECEIVED_KEEP_STREAM,
  payload,
});

export const receiveGroupCallAction = payload => ({
  type: GROUP_CALL_ACTION,
  payload,
});

export const receiveGroupCallMemberStatus = payload => ({
  type: GROUP_CALL_MEMBER_STATUS,
  payload,
});

export const receiveChangeRoom = payload => ({
  type: CHANGE_ROOM,
  payload,
});

export const enterGroupCallSuccess = payload => ({
  type: ENTER_GROUP_CALL_SUCCESS,
  payload,
});

export const receivedConcertInfo = payload => ({
  type: RECEIVED_CONCERT_INFO,
  payload,
});

export const clearGroupCallInfo = roomID => ({
  type: CLEAR_GROUP_CALL_INFO,
  payload: { roomID },
});

export const setGroupCallWrapperHover = (isHovered: boolean) => ({
  type: SET_GROUP_CALL_WRAPPER_HOVER,
  payload: isHovered,
});

export const setRoomIDAliveCalled = (
  roomID: string,
  isAliveCalled: boolean
) => {
  return {
    type: SET_ROOMID_ALIVE_CALLED,
    payload: {
      roomID,
      isAliveCalled,
    },
  };
};

export const runKeepViewStreamPolling = payload => {
  return {
    type: RUN_KEEP_VIEW_STREAM_POLLING,
    payload,
  };
};

export const subscribeGlobalChannel = (payload: { roomID: string }) => {
  return {
    type: SUBSCRIBE_GLOBAL_CHANNEL,
    payload,
  };
};

export const setIsLiveStreamLoading = (isLoading: boolean) => {
  return {
    type: SET_IS_LIVE_STREAM_LOADING,
    payload: isLoading,
  };
};

export const updateLiveStreamStatus = (payload: {
  roomID: string;
  status: number;
}) => {
  return {
    type: UPDATE_LIVE_STREAM_STATUS,
    payload,
  };
};
