// @flow
import { stringify } from 'query-string';
import { empty, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { authWithLine as authWithLineService } from '@/services/17App';
import { isMacOS, isSafari } from '@/services/Device';
import {
  getFacebookUserID,
  getLoginStatus as getFacebookLoginStatus,
  login as loginFacebook,
} from '@/services/Facebook';
import { login as loginGoogle } from '@/services/Google';

import {
  AUTH_WINDOW_CLOSED,
  LINE_AUTH_URL,
  THIRD_PARTY_TYPES,
  TWITTER_AUTH_URL,
} from './constants';
import type { ThirdPartyActions } from './types';
import { generateAuthWindow, getReturnPath, processAuthWindow } from './utils';

/**
 * authWithThirdParty
 */
const handleAuthWindowResponse = data => {
  /**
   * throw when Line & Twitter popup was closed
   * FB, Google will just throw error directly and been caught in epic
   */
  const { error } = data;

  if (error && error === AUTH_WINDOW_CLOSED) {
    throw new Error(error);
  }

  /**
   * throw if missing data
   */
  const values = Object.values(data);

  if (!values.length || !values.every(Boolean)) {
    throw new Error('missing token');
  }

  return data;
};

const authWithGenerateWindow = (
  authURL: string,
  state,
  action: ThirdPartyActions
) =>
  of(
    generateAuthWindow(
      `${authURL}?returnPath=${getReturnPath()}&action=${action}`
    )
  ).pipe(
    switchMap(authWindow => processAuthWindow(authWindow, state)),
    map(handleAuthWindowResponse)
  );

export const authWithFacebook = (action: ThirdPartyActions) =>
  /**
   * Related to #1060.
   * getFacebookLoginStatus() returns an Observable,
   * and authWithFacebook works in Chrome but failed in FF/Edge,
   * wrapped the fn call here with Observable makes it works.
   * TODO: Figure out why it does the trick here.
   */
  of(getFacebookLoginStatus()).pipe(
    mergeMap(({ userID, accessToken }) => {
      // user already logged in with Facebook
      if (userID && accessToken) {
        return of({ userID, accessToken });
      }

      return loginFacebook({ returnPath: getReturnPath(), action }).pipe(
        catchError(err => throwError(err))
      );
    }),
    // filter out empty from mobile redirect
    filter(Boolean)
  );

export const authWithTwitter = (state, action: ThirdPartyActions) =>
  authWithGenerateWindow(TWITTER_AUTH_URL, state, action);

export const authWithLine = (state, action: ThirdPartyActions) =>
  authWithGenerateWindow(LINE_AUTH_URL, state, action);

export const authWithGoogle = action =>
  loginGoogle({ action }).pipe(map(handleAuthWindowResponse));

export const authWithApple = action =>
  of(
    generateAuthWindow(
      `https://appleid.apple.com/auth/authorize?${stringify({
        redirect_uri: `${location.origin}/api/apple-auth`,
        state: JSON.stringify({ action }),
        client_id: 'live.17',
        // scope: 'name%20email',
        response_type: 'code id_token',
        response_mode: 'form_post',
      })}`,
      // [Workaround]
      // issue: [APP-15636] Mac Safari cannot use Apple login
      // root cause: Apple login will open system dialog instead of browser window
      // which cause processAuthWindow detect authWindow was closed then throw error
      {
        usingOriginalWindow: isMacOS() && isSafari(),
      }
    )
  ).pipe(switchMap(processAuthWindow), map(handleAuthWindowResponse));

export const processAuthWithThirdParty = (
  type: string,
  state,
  action: ThirdPartyActions
) => {
  switch (type) {
    case THIRD_PARTY_TYPES.FACEBOOK:
      return authWithFacebook(action);
    case THIRD_PARTY_TYPES.TWITTER:
      return authWithTwitter(state, action);
    case THIRD_PARTY_TYPES.LINE:
      return authWithLine(state, action);
    case THIRD_PARTY_TYPES.GOOGLE:
      return authWithGoogle(action);
    case THIRD_PARTY_TYPES.APPLE:
      return authWithApple(action);
    default:
      return empty();
  }
};

/**
 * getThirdPartyPayload
 */
export const getFacebookPayload = data =>
  of(data).pipe(
    // handle url redirect
    // eslint-disable-next-line camelcase
    map(({ access_token, ...rest }) => ({
      accessToken: access_token,
      ...rest,
    })),
    mergeMap(({ userID, accessToken }) => {
      if (!userID && accessToken) {
        return getFacebookUserID(accessToken).pipe(
          catchError(err => throwError(err))
        );
      }

      return of({
        facebookID: userID,
        facebookAccessToken: accessToken,
      });
    })
  );

export const getLinePayload = data =>
  of(data).pipe(
    switchMap(({ code, state }) => authWithLineService({ code, state })),
    map(({ access_token: lineAccessToken }) => ({ lineAccessToken }))
  );

export const getGooglePayload = data =>
  of({
    // to match server convention
    googleAccessToken: data.googleIDToken || data.id_token,
  });

export const getThirdPartyPayload = (type, data) => {
  switch (type) {
    case THIRD_PARTY_TYPES.FACEBOOK:
      return getFacebookPayload(data);
    case THIRD_PARTY_TYPES.TWITTER:
      return of(data);
    case THIRD_PARTY_TYPES.LINE:
      return getLinePayload(data);
    case THIRD_PARTY_TYPES.GOOGLE:
      return getGooglePayload(data);
    case THIRD_PARTY_TYPES.APPLE:
      return of(data);
    default:
      return empty();
  }
};

/**
 * getRegisterOauthInfo
 */
export const getRegisterOauthInfo = (type, data) => {
  switch (type) {
    case THIRD_PARTY_TYPES.FACEBOOK:
      return {
        facebookID: data.id,
        facebookAccessToken: data.facebookAccessToken,
      };
    case THIRD_PARTY_TYPES.TWITTER:
      return {
        twitterID: data.id,
        twitterAccessToken: data.twitterAccessToken,
        twitterAccessTokenSecret: data.twitterAccessTokenSecret,
      };
    case THIRD_PARTY_TYPES.LINE:
      return {
        lineID: data.id,
        lineAccessToken: data.lineAccessToken,
      };
    case THIRD_PARTY_TYPES.GOOGLE:
      return {
        googleID: data.id,
        googleAccessToken: data.googleAccessToken,
      };
    case THIRD_PARTY_TYPES.APPLE:
      return {
        appleID: data.id,
        appleAuthorizationCode: data.appleAuthorizationCode,
        appleIdentityToken: data.appleIdentityToken,
      };
    default:
      return {};
  }
};
