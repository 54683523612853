import { REGION } from '@17live/app/constants/langs';

export const TERMS_LINK = 'https://17.media/policies/terms';
const JP_TERMS_LINK = 'https://jp.17.live/copy-of-service/';
export const JP_TERM_OF_USE_LINK = 'https://jp.17.live/term-of-use/';

export const termsRegionMapping = {
  [REGION.JP]: JP_TERMS_LINK,
  [REGION.HK]: `${TERMS_LINK}?lang=zh-Hant-TW`,
  [REGION.TW]: `${TERMS_LINK}?lang=zh-Hant-TW`,
  [REGION.SG]: `${TERMS_LINK}?lang=zh-Hant-TW`,
  [REGION.US]: `${TERMS_LINK}?lang=en-US`,
  [REGION.MY]: `${TERMS_LINK}?lang=en`,
  [REGION.PH]: `${TERMS_LINK}?lang=en`,
  [REGION.ID]: `${TERMS_LINK}?lang=en`,
  [REGION.TH]: `${TERMS_LINK}?lang=en`,
  [REGION.VN]: `${TERMS_LINK}?lang=en`,
  [REGION.MM]: `${TERMS_LINK}?lang=en`,
  [REGION.IN]: `${TERMS_LINK}?lang=en`,

  [REGION.GLOBAL]: `${TERMS_LINK}?lang=en`,
};

/**
 * 在做 New Subscription 專案時，法務要求更換 JP 服務條款
 * 連結為 https://jp.17.live/term-of-use/
 * ref: https://17media.slack.com/archives/C05P3R0K2V7/p1700120188292659?thread_ts=1699515597.925589&cid=C05P3R0K2V7
 */
export const armyTermsRegionMapping = {
  ...termsRegionMapping,
  [REGION.JP]: JP_TERM_OF_USE_LINK,
};
