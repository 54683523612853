import { stringify } from 'query-string';
import { map } from 'rxjs/operators';

import { CELLS_TYPES } from '@/services/17App/cells';
import apis from '@/services/17App/shared/apis';

import {
  SectionResponse,
  VliverSectionResponse,
} from '@17live/app/types/Section';

const API_PATH: string = 'sections';

export const SECTION_TABS = Object.freeze({
  VLIVER: 'vliver',
});

/**
 * refs models/section/section.go
 */
enum SectionIDName {
  NONE = 'None',
  TOP_BANNER = 'TopBanner',
  OFFC_RECOMM = 'OffcRecomm',
  SUGGESTION = 'Suggestion',
  LABEL = 'Label',
  TOPIC_TAB = 'TopicTab',
  RED_ENVELOPE = 'RedEnvelope',
  VIRTUAL_STREAMER = 'VirtualStreamer',
  SHOW = 'Show',
  VOD = 'Vod',
  TODAY_HOT_TOPIC = 'TodayHotTopic',
  MUSIC = 'Music',
  GAMING = 'Gaming',
  CRAZY_ZOO = 'CrazyZoo',
  NEWBIEQUEST = 'Newbiequest',
  PK = 'PK',
  LATEST = 'Latest',
  NEWEST_STREAMER = 'NewestStreamer',
  GROUP_CALL = 'GroupCall',
}

/**
 * refs app/api/api/section/section.go
 */
type SectionOptions = {
  cursor?: string;
  count: number;
  preferredLabelIDs?: string[];
  typeTab?: number;
  region?: string;
};

export const getSections = (
  sectionID: SectionIDName,
  options?: SectionOptions
) => {
  const { cursor = '', count = 50 } = options;
  const qs = new URLSearchParams({ count: String(count), cursor });

  return apis.getJSON(`${API_PATH}/${sectionID}?${qs.toString()}`);
};

export const getVliverSections = (options?: SectionOptions) => {
  const { typeTab = 2, cursor = '' } = options ?? {};

  const params = stringify({
    // vliver section List API count 的 max 數量為 20，如果超過的話會 fetch 錯誤
    // @see https://github.com/17media/api/blob/1ccf525016eed84d1fea28b312a8080c956b5a3d/app/api/api/section/section.go#L550-L562
    count: 20,
    typeTab,
    // 因為只有日本開虛擬主播開播功能，所以強制覆蓋這隻 API 在任何地區都只拿日本的主播資料，不然這隻 API 在其他地區會傳真人主播
    // 如果要判斷其他地區是否有開啟功能，可以查 feature config: 131(VliverExposure)
    // @see https://17media.atlassian.net/browse/APP-82141
    region: 'JP',
    cursor,
  });

  const IGNORE_VLIVER_SECTION_IDS = ['TopBanner', 'ArchiveVideo', 'Vod'];

  // @see https://17media.slack.com/archives/C029NS3F8AE/p1693293803303339?thread_ts=1692086343.841089&cid=C029NS3F8AE
  // 跟 BE 討論取得 Vliver 資料的 slack
  return apis.getJSON(`${API_PATH}?${params}`).pipe(
    map(({ sections }: VliverSectionResponse) => {
      let list = [];
      sections.forEach(({ id, grids }) => {
        if (!grids || IGNORE_VLIVER_SECTION_IDS.includes(id)) return;
        const streams = grids
          .filter(({ stream }) => !!stream)
          .map(({ stream }) => stream);

        list = [...list, ...streams];
      });

      return {
        list,
        cursor,
      };
    })
  );
};

export const getGroupCallSections = (payload: SectionOptions) => {
  return getSections(SectionIDName.GROUP_CALL, { ...payload }).pipe(
    map(({ grids, cursor }: SectionResponse) => {
      const list = grids.map(({ stream, groupCallInfo }) => {
        return {
          ...stream,
          groupCallInfo,
          roomID: stream.liveStreamID,
          beginTime: groupCallInfo.startTime,
          isFollowing: stream.userInfo.isFollowing,
          isOnline: !!groupCallInfo,
          type: CELLS_TYPES.STREAM,
        };
      });

      return {
        list,
        cursor,
      };
    })
  );
};
