/**
 *
 * ResponsiveWindowSize
 *
 * @flow
 */

import { Component } from 'react';
import type { Subscription } from 'rxjs/Subscription';
import onWindowResize$ from './onWindowResize';

class ResponsiveWindowSize extends Component<{}> {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setWindowSize();
      this.windowResize$ = onWindowResize$.subscribe(this.setWindowSize);
    }
  }

  componentWillUnmount() {
    if (this.windowResize$) {
      this.windowResize$.unsubscribe();
    }
  }

  setWindowSize = () => {
    const doc = document.documentElement;

    if (doc) {
      doc.style.setProperty('--window-height', `${window.innerHeight}px`);
      doc.style.setProperty('--window-width', `${window.innerWidth}px`);
    }
  };

  windowResize$: ?Subscription = null;

  render() {
    return null;
  }
}

export default ResponsiveWindowSize;
