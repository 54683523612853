// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Props = {
  description: string,
  ogDescription: string,
  twitterDescription: string,
};

const Description = ({
  description,
  ogDescription,
  twitterDescription,
}: Props) => {
  if (!description) {
    return null;
  }

  return (
    <ReactHelmet>
      <meta name="description" content={description} />
      <meta property="og:description" content={ogDescription || description} />
      <meta
        name="twitter:description"
        content={twitterDescription || description}
      />
    </ReactHelmet>
  );
};

export default Description;
