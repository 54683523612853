/* eslint-disable no-underscore-dangle */

import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';
import { PerimeterXSetting } from '@17live/app/services/Perimeterx/constants';
import usePXCaptchaStore from '@17live/app/stores/usePerimeterXStore';

import PerimeterXCaptchaModal from './PerimeterXCaptchaModal';

const PerimeterX: React.FC = () => {
  const isLoaded = useRef(false);
  const intl = useIntl();
  const { lang } = useLocale();

  const {
    pxData,
    isCaptchaModalVisible,
    captchaCallback,
    closeCaptchaModal,
  } = usePXCaptchaStore();

  const {
    appId,
    jsClientSrc,
    firstPartyEnabled,
    vid,
    uuid,
    hostUrl,
    blockScript,
  } = pxData || {};

  useEffect(() => {
    if (appId) {
      window._pxAppId = appId ?? '';
      window._pxJsClientSrc = jsClientSrc ?? '';
      window._pxFirstPartyEnabled = firstPartyEnabled ?? true;
      window._pxVid = vid ?? '';
      window._pxUuid = uuid ?? '';
      window._pxHostUrl = hostUrl ?? '';
      window._pxSelectedLocale = lang;

      (window as any)[`_${appId}`] = {
        ...PerimeterXSetting,
        challenge: {
          ...PerimeterXSetting.challenge,
          translation: {
            default: {
              btn: intl.formatMessage({ id: 'perimeterx_modal_btn' }),
              failed: ' ',
            },
            [lang]: {
              btn: intl.formatMessage({ id: 'perimeterx_modal_btn' }),
              // fill with space to hide the default error text and hint user in other way
              failed: ' ',
            },
          },
        },
      };

      if (blockScript) {
        const script = document.createElement('script');
        script.src = blockScript;
        document.getElementsByTagName('head')[0].appendChild(script);
        isLoaded.current = true;
      }
    }
  }, [
    appId,
    jsClientSrc,
    firstPartyEnabled,
    vid,
    uuid,
    hostUrl,
    blockScript,
    intl,
    lang,
  ]);

  return isCaptchaModalVisible && pxData ? (
    <PerimeterXCaptchaModal
      callback={captchaCallback}
      onClose={closeCaptchaModal}
    />
  ) : null;
};

export default PerimeterX;
