export const CONCERT_PREPARE_MINUTES = 30;

export enum ConcertStatus {
  OFF_LIVE = 1,
  ON_LIVE,
  PREPARE,
  VOD_WAITING,
  VOD_AVAILABLE,
  VOD_END,
}
