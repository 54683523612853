// @flow
import { parse } from 'query-string';
import { type Observable, concat, of } from 'rxjs';
import { delay, map, retryWhen } from 'rxjs/operators';

import { openModal } from '@/containers/Modal/actions';
import { type MODALS } from '@/containers/Modal/constants';
import type { ThirdPartyType } from '@/containers/ThirdPartyAction/types';
import { isHandheld, isMacOS, isSafari } from '@/services/Device';
import PreferenceSession from '@/services/Preference/session';

import { loginFailed } from '@17live/app/containers/LoginProvider/actions';
import { getPathWithoutLang } from '@17live/app/utils';

import { loginWithThirdParty, openPhoneVerifyModal } from './actions';
import {
  AUTH_WINDOW_CLOSED,
  AUTH_WINDOW_HEIGHT,
  AUTH_WINDOW_NAME,
  AUTH_WINDOW_WIDTH,
  THIRD_PARTY_ACTIONS,
  THIRD_PARTY_TYPES,
} from './constants';

export const isFromIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const generateAuthWindow = (
  url: string,
  { usingOriginalWindow = false } = {}
): any => {
  if ((isHandheld() && !isFromIframe()) || usingOriginalWindow) {
    window.location.assign(url);
    return;
  }

  const options = `height=${AUTH_WINDOW_HEIGHT},width=${AUTH_WINDOW_WIDTH}`;

  return window.open(url, AUTH_WINDOW_NAME, options);
};

export const processAuthWindow = (authWindow: any) =>
  /**
   * Accessing like `Observable.of(authWindow)` will throw security error in some browser,
   * since it try to check the type under the hood, which is not allowed when cross-origin.
   * We simply bypass the accessing by wrapping it inside an object.
   * Also see the test case of this issue.
   */
  of({ authWindow }).pipe(
    map(() => {
      if (authWindow.closed) {
        return { error: AUTH_WINDOW_CLOSED };
      }

      if (
        authWindow.location.origin !== window.location.origin ||
        !getPathWithoutLang(authWindow.location.pathname).startsWith(
          `/redirect`
        )
      ) {
        throw new Error();
      }

      return parse(authWindow.location.search);
    }),
    map(params => {
      if (authWindow.closed) {
        return params;
      }

      // throw error for retry when auth window is still open but cannot get authorization code
      if (!authWindow.closed && !Object.keys(params).length) {
        throw params;
      }

      authWindow.close();

      return params;
    }),
    // access authWindow may trigger cross-origin error or simply not yet parsed
    retryWhen(err$ => err$.pipe(delay(1000)))
  );

export const getReturnPath = (): string =>
  PreferenceSession.get('returnPath') || window.location.pathname;

export const handleLoginOrRegisterFailed = (
  modal: MODALS,
  error: Error | string,
  thirdPartyType: ?ThirdPartyType
): Observable<*> => {
  // Apple login will open a system dialog and redirect the original window (tab) in Safari on MacOS.
  // Therefore, we need to open a modal when an error occurs to display the message to the user.
  const isSafariOnMacOSWithApple =
    isMacOS() && isSafari() && thirdPartyType === THIRD_PARTY_TYPES.APPLE;

  if (isSafariOnMacOSWithApple || isHandheld()) {
    return concat(
      // modal will be closed after URL redirect on mobile, so open modal first then display error message
      of(openModal({ name: modal })),
      of(loginFailed(error))
    );
  }
  return of(loginFailed(error));
};

// $FlowFixMe
export const getThirdPartyAction = name =>
  ({
    [THIRD_PARTY_ACTIONS.LOGIN]: loginWithThirdParty,
    [THIRD_PARTY_ACTIONS.REGISTER]: openPhoneVerifyModal,
  }[name]);
