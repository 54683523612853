import Loadable from '@/components/Loadable';
import Loading from '@/components/Loading';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AvatarInfo" */ '@17live/app/containers/AvatarInfo'
    ),
  loading: Loading,
});
