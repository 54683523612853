import styled from 'styled-components';

import { COLOR_PRIMARY_BLACK } from '@17live/design-system/color';

const VLiveCharacterSubtitle = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
  color: ${COLOR_PRIMARY_BLACK};
`;

export default VLiveCharacterSubtitle;
