import { useMemo } from 'react';

import create from 'zustand';

import { LangType } from '@17live/app/constants';
import {
  ViolationAction,
  ViolationFeature,
} from '@17live/app/containers/Violation/Violation.enums';
import { useFetchViolationReasons } from '@17live/app/hooks/apis/useFetchViolation';
import { getBackendLanguageCode } from '@17live/app/utils';

export type UseViolationAlertStoreState = {
  isKillStreamAlertOpen: boolean;
  killStreamReason: string | null;
  openKillStreamAlert: (reason: string) => void;
  closeKillStreamAlert: () => void;

  isWarnStreamAlertOpen: boolean;
  warnStreamReason: string | null;
  openWarnStreamAlert: (reason: string) => void;
  closeWarnStreamAlert: () => void;
};

export const useViolationAlertStore = create<UseViolationAlertStoreState>(
  set => ({
    isKillStreamAlertOpen: false,
    killStreamReason: null,
    openKillStreamAlert: (reason: string) =>
      set({
        isKillStreamAlertOpen: true,
        killStreamReason: reason,
      }),
    closeKillStreamAlert: () =>
      set({
        isKillStreamAlertOpen: false,
        killStreamReason: null,
      }),

    isWarnStreamAlertOpen: false,
    warnStreamReason: null,
    openWarnStreamAlert: (reason: string) =>
      set({
        isWarnStreamAlertOpen: true,
        warnStreamReason: reason,
      }),
    closeWarnStreamAlert: () =>
      set({
        isWarnStreamAlertOpen: false,
        warnStreamReason: null,
      }),
  })
);

export const useViolationReasons = (
  feature: ViolationFeature,
  action: ViolationAction,
  lang: LangType
) => {
  const violationLang = getBackendLanguageCode(lang);
  const result = useFetchViolationReasons(violationLang);
  const reasons = useMemo(() => result?.data?.[feature]?.[action], [
    action,
    feature,
    result,
  ]);
  return {
    isLoading: result.isLoading,
    reasons,
  } as const;
};
