import apis from '@/services/17App/shared/apis';

const API_PATH = 'groupCalls';

export const getGroupCallInfoService = userID =>
  apis.getJSON(
    `${API_PATH}/users/${userID}/info`,
    {},
    {},
    {
      retries: 0, // do not retry
    }
  );
