// @flow
import type { ComponentType } from 'react';
import { Store } from 'redux';

import flatten from 'lodash/flatten';

import Loadable from '@/components/Loadable';
import { getStore } from '@/store';
import { errorLoading } from '@/utils/asyncInjectors';

type Load = (store: Store) => Promise<*>;

const withLoader = (...args: Array<Load | Load[]>) => (
  Component: ComponentType<*>
): ComponentType<*> => {
  const LoadableComponent = Loadable({
    loader: () => {
      const store = getStore();
      const results = { default: Component };

      if (args.length === 0) {
        return Promise.resolve(results);
      }

      const loads = flatten(args);

      return Promise.all(loads.map(load => load(store)))
        .then(() => results)
        .catch(errorLoading);
    },
  });

  LoadableComponent.displayName = Component.displayName || Component.name;

  return LoadableComponent;
};

export default withLoader;
