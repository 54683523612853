import { useEffect } from 'react';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';
import { useLogin } from '@17live/app/containers/LoginProvider/hooks';
import { setBrazeCustomAttribute } from '@17live/app/services/Braze';
import { BRAZE_DEFAULT_CUSTOM_ATTRIBUTES } from '@17live/app/services/Braze/constants';

export const useBraze = () => {
  const { region } = useLocale();
  const { isLoggedIn } = useLogin();

  useEffect(() => {
    if (isLoggedIn) {
      setBrazeCustomAttribute(
        BRAZE_DEFAULT_CUSTOM_ATTRIBUTES.WEB_REGION,
        region
      );
    }
  }, [isLoggedIn, region]);
};
