// @flow
import {
  animationFrameScheduler,
  empty,
  fromEvent,
  type Observable,
} from 'rxjs';
import { observeOn, share } from 'rxjs/operators';

const onWindowResize$: Observable<void> =
  typeof window === 'undefined'
    ? empty()
    : fromEvent(window, 'resize').pipe(
        observeOn(animationFrameScheduler),
        share()
      );

export default onWindowResize$;
