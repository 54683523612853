import { Epic } from 'redux-observable';

import { filter, ignoreElements, map, tap } from 'rxjs/operators';

import { filterMessageType } from '@17live/app/containers/ChatRoom/utils';
import { LiveStreamEndReason } from '@17live/app/containers/LiveStream/enums';
import { makeSelectLiveStreamUserID } from '@17live/app/containers/LiveStream/selectors';
import { makeSelectLoginUserID } from '@17live/app/containers/LoginProvider/selectors';
import { useViolationAlertStore } from '@17live/app/containers/Violation/Violation.hooks';
import { MessageType } from '@17live/app/services/Message/Message.enums';
import {
  LivestreamEndMsgPayload,
  LivestreamWarnMsgPayload,
} from '@17live/app/types';

export const killStreamNotificationEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(filterMessageType(MessageType.LIVE_STREAM_END)),
    map(({ payload }) => payload),
    filter((payload: LivestreamEndMsgPayload) => {
      const streamerID = makeSelectLiveStreamUserID()(state$.value, {
        roomID: payload.roomID,
      });
      const loginUserID = makeSelectLoginUserID()(state$.value);

      return (
        streamerID === loginUserID &&
        payload.message.endStreamMsg.closeBy ===
          LiveStreamEndReason.KillBySkyeye
      );
    }),
    tap(payload =>
      useViolationAlertStore
        .getState()
        .openKillStreamAlert(payload.message.endStreamMsg.reason)
    ),
    ignoreElements()
  );

export const warnStreamNotificationEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(filterMessageType(MessageType.LIVE_STREAM_WARN)),
    map(({ payload }) => payload),
    filter((payload: LivestreamWarnMsgPayload) => {
      const streamerID = makeSelectLiveStreamUserID()(state$.value, {
        roomID: payload.roomID,
      });
      const loginUserID = makeSelectLoginUserID()(state$.value);
      return streamerID === loginUserID;
    }),
    tap(payload =>
      useViolationAlertStore
        .getState()
        .openWarnStreamAlert(payload.message.warnStreamMsg.reasonI18nKey)
    ),
    ignoreElements()
  );

export default [killStreamNotificationEpic, warnStreamNotificationEpic];
