/*
 * Terms Messages
 *
 * This contains all the text for the Terms component.
 */
import defineMessages from 'define-messages';

export default defineMessages({
  agreementsLink: {
    id: 'app.containers.Terms.agreementsLink',
    defaultMessage: 'https://17.media/policies/agreements?lang=en',
  },
  agreementsLinkJapan: {
    id: 'app.containers.Terms.agreementsLink.Japan',
    defaultMessage: 'https://17media.jp/copy-of-jp-privacy1/',
  },
  invoiceLinkMessage: {
    id: 'app.containers.Terms.invoiceLinkMessage',
    defaultMessage: 'Check Terms of Service',
  },
  termsLabel: {
    id: 'app.containers.Terms.termsLabel',
    defaultMessage: 'Terms of Service',
  },
  agreementsLabel: {
    id: 'app.containers.Terms.agreementsLabel',
    defaultMessage: 'Guidelines & Agreement',
  },
  aboutLabel: {
    id: '17.live_footer_about',
    defaultMessage: 'About',
  },
  aboutLink: {
    id: '17.live_footer_about_link',
    defaultMessage: 'https://about.17.live',
  },
  FAQLabel: {
    id: 'setting_faq',
    defaultMessage: 'FAQ',
  },
});
