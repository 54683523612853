import React, { useCallback } from 'react';

import styled from 'styled-components';

import { VliverModel, VliverTab } from '@17live/app/enums/Vliver.enums';
import $t from '@17live/app/utils/intl';
import Chip from '@17live/design-system/Chip/Chip';

const TabListWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 12px 8px;
  overflow-x: scroll;
`;

interface VLiveCharacterTabListProps {
  tabs: VliverTab[];
  activeTabID: VliverModel;
  onTabChange: (tabId: number) => void;
  isTheater?: boolean;
}

const VLiveCharacterTabList = ({
  tabs,
  activeTabID,
  onTabChange,
  isTheater = false,
}: VLiveCharacterTabListProps) => {
  const handleTabClick = useCallback(
    (id: VliverModel) => {
      onTabChange(id);
    },
    [onTabChange]
  );

  if (tabs && Array.isArray(tabs)) {
    return (
      <TabListWrapper>
        {tabs.map(tab => (
          <Chip
            key={tab.id}
            chipLabel={tab.label ? $t(tab.label) : ''}
            isActive={tab.id === activeTabID}
            isTheater={isTheater}
            onClick={() => handleTabClick(tab.id)}
          />
        ))}
      </TabListWrapper>
    );
  }

  return null;
};

export default VLiveCharacterTabList;
