export const isHistoryItemValid = historyItem => !!(
  historyItem.roomID
    && historyItem.openID
    && historyItem.userID
    && historyItem.level
    && historyItem.timestamp
);

export const getValidHistoryList = historyList => (
  Object.values(historyList || {})
    // filter out older spec
    .filter(isHistoryItemValid)
    .reduce((obj, cur) => ({
      ...obj,
      [cur.roomID]: cur,
    }), {})
);
