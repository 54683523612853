/*
 *
 * UserProvider actions
 *
 */
import { STATUS } from '@/constants/services';
import { GET_USER_INFO, RECEIVED_USER_INFO, SET_STATUS } from './constants';

export const getUserInfo = userID => ({
  type: GET_USER_INFO,
  payload: userID,
});

export const receivedUserInfo = payload => ({
  type: RECEIVED_USER_INFO,
  payload,
});

export const setStatus = (status, error) => ({
  type: SET_STATUS,
  error: status === STATUS.ERROR,
  payload: status === STATUS.ERROR ? error : { status },
});
