import * as React from 'react';
function SvgIgAiCoHostFaqGame(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 720 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M361.89 332.197c120.694 0 168.862-10.186 199.457-76.289 30.595-66.103-23.924-216.17-203.673-204.033-179.75 12.137-234.996 113.296-199.949 187.193s83.47 93.129 204.165 93.129z"
        fill="#F1F4FF"
      />
      <path
        d="M269.112 109.612h-68.98a4 4 0 00-4 4V184a4 4 0 004 4h68.98a4 4 0 004-4v-70.388a4 4 0 00-4-4z"
        fill="#E4E8FF"
      />
      <path
        d="M233.072 162.482c2.27 0 4.048.668 5.334 2.005 1.287 1.337 1.93 3.039 1.93 5.107s-.643 3.77-1.93 5.107c-1.286 1.337-3.064 2.005-5.334 2.005-2.269 0-4.047-.668-5.334-2.005-1.286-1.337-1.929-3.039-1.929-5.107s.643-3.77 1.929-5.107c1.287-1.337 3.065-2.005 5.334-2.005zm3.211-41.576c6.809 0 11.96 1.514 15.451 4.544 3.491 3.03 5.237 6.997 5.237 11.902 0 9.077-6.303 14.947-18.907 17.61.045 3.094-1.954 4.957-5.996 5.59-5.848.915-8.338-2.823-7.469-11.215-.002-.042-.002-.084-.002-.126 0-1.789.476-3.131 1.428-4.025.953-.895 2.352-1.573 4.199-2.034l6.751-1.645c2.886-.75 4.328-2.135 4.328-4.155 0-1.096-.534-2.049-1.601-2.857-1.068-.807-2.9-1.211-5.497-1.211-2.366 0-4.587.303-6.665.909-2.077.605-3.792 1.332-5.035 1.332-1.243 0-1.803-.871-2.496-1.938-.692-1.068-1.038-2.352-1.038-3.852 0-1.501.433-2.814 1.298-3.939.866-1.125 2.063-2.048 3.592-2.77 1.53-.721 3.347-1.255 5.454-1.601 2.106-.346 4.429-.519 6.968-.519z"
        fill="#C7CFFF"
      />
      <path
        d="M352.49 109.612h-68.98a4 4 0 00-4 4V184a4 4 0 004 4h68.98a4 4 0 004-4v-70.388a4 4 0 00-4-4z"
        fill="#E4E8FF"
      />
      <path
        d="M316.451 162.482c2.269 0 4.048.668 5.334 2.005 1.286 1.337 1.929 3.039 1.929 5.107s-.643 3.77-1.929 5.107c-1.286 1.337-3.065 2.005-5.334 2.005-2.27 0-4.048-.668-5.334-2.005-1.287-1.337-1.93-3.039-1.93-5.107s.643-3.77 1.93-5.107c1.286-1.337 3.064-2.005 5.334-2.005zm3.21-41.576c6.809 0 11.96 1.514 15.451 4.544 3.491 3.03 5.237 6.997 5.237 11.902 0 9.077-6.302 14.947-18.907 17.61.045 3.094-1.954 4.957-5.996 5.59-5.848.915-8.338-2.823-7.469-11.215l-.002-.126c0-1.789.477-3.131 1.429-4.025.952-.895 2.351-1.573 4.198-2.034l6.752-1.645c2.885-.75 4.328-2.135 4.328-4.155 0-1.096-.534-2.049-1.602-2.857-1.067-.807-2.9-1.211-5.496-1.211-2.366 0-4.588.303-6.666.909-2.077.605-3.792 1.332-5.034 1.332-1.243 0-1.804-.871-2.496-1.938-.693-1.068-1.039-2.352-1.039-3.852 0-1.501.433-2.814 1.298-3.939.866-1.125 2.063-2.048 3.593-2.77 1.529-.721 3.347-1.255 5.453-1.601 2.106-.346 4.429-.519 6.968-.519z"
        fill="#C7CFFF"
      />
      <path
        d="M435.868 109.612h-68.98a4 4 0 00-4 4V184a4 4 0 004 4h68.98a4 4 0 004-4v-70.388a4 4 0 00-4-4z"
        fill="#E4E8FF"
      />
      <path
        d="M399.829 162.482c2.27 0 4.048.668 5.334 2.005 1.286 1.337 1.929 3.039 1.929 5.107s-.643 3.77-1.929 5.107c-1.286 1.337-3.064 2.005-5.334 2.005-2.27 0-4.048-.668-5.334-2.005-1.286-1.337-1.929-3.039-1.929-5.107s.643-3.77 1.929-5.107c1.286-1.337 3.064-2.005 5.334-2.005zm3.21-41.576c6.81 0 11.96 1.514 15.451 4.544 3.492 3.03 5.237 6.997 5.237 11.902 0 9.077-6.302 14.947-18.907 17.61.045 3.094-1.953 4.957-5.995 5.59-5.849.915-8.338-2.823-7.47-11.215l-.001-.126c0-1.789.476-3.131 1.428-4.025.952-.895 2.351-1.573 4.198-2.034l6.752-1.645c2.885-.75 4.328-2.135 4.328-4.155 0-1.096-.534-2.049-1.602-2.857-1.067-.807-2.899-1.211-5.496-1.211-2.366 0-4.588.303-6.665.909-2.078.605-3.792 1.332-5.035 1.332-1.243 0-1.804-.871-2.496-1.938-.693-1.068-1.039-2.352-1.039-3.852 0-1.501.433-2.814 1.299-3.939.865-1.125 2.063-2.048 3.592-2.77 1.529-.721 3.347-1.255 5.453-1.601 2.106-.346 4.429-.519 6.968-.519z"
        fill="#C7CFFF"
      />
      <path
        d="M518.797 109.612h-68.98a4 4 0 00-4 4V184a4 4 0 004 4h68.98a4 4 0 004-4v-70.388a4 4 0 00-4-4z"
        fill="#E4E8FF"
      />
      <path
        d="M482.757 162.482c2.27 0 4.048.668 5.334 2.005 1.287 1.337 1.93 3.039 1.93 5.107s-.643 3.77-1.93 5.107c-1.286 1.337-3.064 2.005-5.334 2.005-2.269 0-4.047-.668-5.334-2.005-1.286-1.337-1.929-3.039-1.929-5.107s.643-3.77 1.929-5.107c1.287-1.337 3.065-2.005 5.334-2.005zm3.211-41.576c6.809 0 11.96 1.514 15.451 4.544 3.491 3.03 5.237 6.997 5.237 11.902 0 9.077-6.303 14.947-18.907 17.61.045 3.094-1.954 4.957-5.996 5.59-5.848.915-8.338-2.823-7.469-11.215-.002-.042-.002-.084-.002-.126 0-1.789.476-3.131 1.428-4.025.953-.895 2.352-1.573 4.199-2.034l6.751-1.645c2.886-.75 4.328-2.135 4.328-4.155 0-1.096-.534-2.049-1.601-2.857-1.068-.807-2.9-1.211-5.497-1.211-2.366 0-4.587.303-6.665.909-2.077.605-3.792 1.332-5.035 1.332-1.243 0-1.803-.871-2.496-1.938-.692-1.068-1.038-2.352-1.038-3.852 0-1.501.433-2.814 1.298-3.939.866-1.125 2.063-2.048 3.592-2.77 1.53-.721 3.347-1.255 5.454-1.601 2.106-.346 4.429-.519 6.968-.519z"
        fill="#C7CFFF"
      />
      <path
        d="M368.284 322.011c48.482 0 87.784-3.358 87.784-7.5 0-4.142-39.302-7.5-87.784-7.5-48.482 0-87.784 3.358-87.784 7.5 0 4.142 39.302 7.5 87.784 7.5z"
        fill="#DEE3FF"
      />
      <path
        d="M366.593 317.505c33.966 0 61.5-1.903 61.5-4.25s-27.534-4.25-61.5-4.25c-33.965 0-61.5 1.903-61.5 4.25s27.535 4.25 61.5 4.25z"
        fill="#3F5CFF"
        fillOpacity={0.3}
      />
      <path
        d="M352.095 285.061a2.606 2.606 0 01.92 3.186l-2.427 5.6-5.219 12.72a10.904 10.904 0 01-1.649 4.65c-3.674 5.738-9.223 2.636-15.823-1.581l-.27-.173c-6.468-4.145-11.411-7.85-7.797-13.524 3.663-5.752 12-6.981 18.599-2.765.246.159.487.324.721.493l9.554-8.4a2.77 2.77 0 013.391-.206z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M319.191 297.025l24.552 14.175a5.787 5.787 0 01-8.292 2.925l-13.816-7.976a6.68 6.68 0 01-2.444-9.124z"
        fill="#633DFF"
      />
      <path
        d="M381.74 282.238a2.603 2.603 0 00-2.39 2.299l-.698 6.063-1.841 13.625a10.909 10.909 0 00-.897 4.852c.313 6.806 6.67 6.894 14.493 6.542l.321-.015c7.674-.356 13.807-1.093 13.515-7.813-.297-6.814-6.902-12.046-14.725-11.694-.293.014-.583.037-.871.066l-4.075-12.052a2.768 2.768 0 00-2.832-1.873z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.884 309.051h28.35a5.787 5.787 0 01-5.718 6.679h-15.953a6.68 6.68 0 01-6.679-6.679z"
        fill="#633DFF"
      />
      <path
        d="M310.717 186.645l-11.511 2.935a9.807 9.807 0 00-7.36 8.818l-1.962 28.06a9.805 9.805 0 005.437 9.474l11.55 5.71 3.846-54.997z"
        fill="url(#IgAiCoHostFaqGame_svg__paint0_linear_9492_199820)"
      />
      <mask
        id="IgAiCoHostFaqGame_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={289}
        y={186}
        width={22}
        height={56}
      >
        <path
          d="M310.717 186.645l-11.511 2.935a9.807 9.807 0 00-7.36 8.818l-1.962 28.06a9.805 9.805 0 005.437 9.474l11.55 5.71 3.846-54.997z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqGame_svg__a)">
        <path
          d="M301.736 186.017l-8.98-.628-3.846 54.997 8.981.628 3.845-54.997z"
          fill="#4E47FF"
        />
      </g>
      <path
        d="M426.806 194.763l10.991 4.508a9.807 9.807 0 016.06 9.757l-1.962 28.059a9.807 9.807 0 01-6.703 8.626l-12.232 4.046 3.846-54.996z"
        fill="url(#IgAiCoHostFaqGame_svg__paint1_linear_9492_199820)"
      />
      <mask
        id="IgAiCoHostFaqGame_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={422}
        y={194}
        width={22}
        height={56}
      >
        <path
          d="M426.806 194.763l10.991 4.508a9.807 9.807 0 016.06 9.757l-1.962 28.059a9.807 9.807 0 01-6.703 8.626l-12.232 4.046 3.846-54.996z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqGame_svg__b)">
        <path
          d="M435.786 195.391l8.981.628-3.846 54.996-8.98-.628 3.845-54.996z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.732 159.968c-7.664-7.021-14.471-12.282-20.423-15.784-8.926-5.253-29.509-8.978-36.497-4.986-6.988 3.991-6.207 23.448-4.44 35.696 1.178 8.166 3.232 15.9 6.162 23.201.808-9.709 5.039-18.413 12.693-26.11 7.655-7.697 21.823-11.703 42.505-12.017z"
        fill="#fff"
      />
      <path
        d="M334.339 161.79c3.766-2.003-18.039-21.97-26.062-16.599-8.023 5.371-.921 44.126.823 40.771 1.744-3.355 2.281-6.594 8.147-13.122 5.865-6.528 13.325-9.047 17.092-11.05z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaqGame_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={304}
        y={144}
        width={31}
        height={43}
      >
        <path
          d="M334.339 161.79c3.766-2.003-18.039-21.97-26.062-16.599-8.023 5.371-.921 44.126.823 40.771 1.744-3.355 2.281-6.594 8.147-13.122 5.865-6.528 13.325-9.047 17.092-11.05z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqGame_svg__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M340.092 164.97c3.767-2.002-18.039-21.97-26.062-16.599-8.023 5.371-.921 44.126.823 40.771 1.744-3.355 2.281-6.594 8.147-13.122 5.865-6.528 13.325-9.047 17.092-11.05z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.687 161.168c8.566-5.886 16.039-10.148 22.42-12.788 9.571-3.959 30.471-4.784 36.836.142 6.364 4.925 2.883 24.084-.571 35.967-2.303 7.922-5.413 15.295-9.331 22.117.551-9.727-2.427-18.935-8.936-27.622-6.509-8.688-19.982-14.626-40.418-17.816z"
        fill="#fff"
      />
      <path
        d="M407.55 166.924c-3.452-2.508 20.921-19.246 28.118-12.811 7.197 6.436-5.229 43.825-6.489 40.26-1.261-3.565-1.342-6.847-6.241-14.128-4.9-7.281-11.937-10.814-15.388-13.321z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaqGame_svg__d"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={407}
        y={152}
        width={31}
        height={43}
      >
        <path
          d="M407.55 166.924c-3.452-2.508 20.921-19.246 28.118-12.811 7.197 6.436-5.229 43.825-6.489 40.26-1.261-3.565-1.342-6.847-6.241-14.128-4.9-7.281-11.937-10.814-15.388-13.321z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqGame_svg__d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M401.41 169.272c-3.451-2.507 20.921-19.246 28.118-12.81 7.198 6.435-5.229 43.824-6.489 40.259-1.26-3.565-1.341-6.847-6.241-14.128-4.9-7.281-11.937-10.814-15.388-13.321z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.751 176.623l2.527 3.795a5.11 5.11 0 003.899 2.267l15.639 1.094a5.114 5.114 0 004.123-1.642l3.178-3.461-29.366-2.053z"
        fill="#211F8A"
      />
      <path
        d="M385.612 288.865a665.289 665.289 0 01-47.199-3.301c-18.808-2.13-34.983-18.44-34.752-35.041.325-13.834 2.677-47.469 4.28-61.218 2.082-16.46 20.37-30.371 39.291-29.863a669.479 669.479 0 0147.2 3.3c18.807 2.131 34.981 18.452 34.752 35.041-.326 13.838-2.678 47.473-4.281 61.218-2.082 16.472-20.37 30.371-39.291 29.864z"
        fill="url(#IgAiCoHostFaqGame_svg__paint2_linear_9492_199820)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.358 262.272l2.998-6.567a2.75 2.75 0 015.168.472l1.676 6.679c.223.89.876 1.61 1.741 1.919l5.837 2.087a2.347 2.347 0 01-.208 4.483l-6.324 1.618a2.751 2.751 0 00-1.85 1.591l-2.599 6.135a2.748 2.748 0 01-5.153-.246l-2.108-6.684a2.747 2.747 0 00-1.552-1.706l-5.899-2.492a2.352 2.352 0 01.42-4.466l5.931-1.278a2.744 2.744 0 001.922-1.545z"
        fill="#4E47FF"
      />
      <path
        d="M399.296 175.194l-61.039-4.268c-14.086-.985-26.303 9.635-27.287 23.72l-1.867 26.694c-.985 14.086 9.635 26.303 23.72 27.288l61.039 4.268c14.086.985 26.303-9.635 27.288-23.721l1.866-26.694c.985-14.085-9.635-26.302-23.72-27.287z"
        fill="#221CBF"
      />
      <path
        d="M398.426 176.842l-59.537-4.163c-13.146-.919-24.548 8.993-25.468 22.139l-1.866 26.694c-.92 13.147 8.992 24.549 22.139 25.468l59.536 4.163c13.146.92 24.549-8.992 25.468-22.139l1.867-26.694c.919-13.146-8.993-24.548-22.139-25.468z"
        fill="#645EFF"
      />
      <path
        d="M397.456 179.679l-58.001-4.056c-11.268-.788-21.042 7.708-21.83 18.976l-1.938 27.714c-.788 11.269 7.708 21.042 18.976 21.83l58.001 4.056c11.269.788 21.042-7.708 21.83-18.976l1.938-27.714c.788-11.269-7.708-21.042-18.976-21.83z"
        fill="url(#IgAiCoHostFaqGame_svg__paint3_radial_9492_199820)"
      />
      <path
        d="M365.266 225.522c8.877.621 16.575-6.047 17.194-14.893.618-8.846-6.077-16.521-14.954-17.142-8.878-.62-16.576 6.048-17.194 14.894-.619 8.846 6.076 16.521 14.954 17.141z"
        fill="url(#IgAiCoHostFaqGame_svg__paint4_linear_9492_199820)"
      />
      <path
        d="M362.252 202.829a2.91 2.91 0 003.109-2.693 2.908 2.908 0 00-2.704-3.099 2.906 2.906 0 00-3.108 2.692 2.908 2.908 0 002.703 3.1z"
        fill="#00FFFA"
        fillOpacity={0.3}
      />
      <path
        d="M346.06 209.564c-.518 8.044-3.782 14.351-10.578 13.876-7.716-.54-9.226-7.219-8.702-15.263.524-8.043 3.042-14.402 10.584-13.875 6.951.486 9.218 7.238 8.696 15.262z"
        fill="url(#IgAiCoHostFaqGame_svg__paint5_linear_9492_199820)"
      />
      <path
        d="M335.84 202.424c1.456.102 2.722-1.038 2.827-2.545.105-1.508-.989-2.812-2.445-2.914-1.456-.102-2.722 1.038-2.827 2.545-.106 1.508.989 2.812 2.445 2.914z"
        fill="#fff"
      />
      <path
        d="M336.077 220.841c2.663.187 4.922-1.085 5.044-2.84.123-1.755-1.936-3.329-4.6-3.515-2.663-.187-4.921 1.085-5.044 2.84-.123 1.755 1.937 3.329 4.6 3.515z"
        fill="#00CBCD"
      />
      <path
        d="M406.199 213.77c-.523 8.043-3.788 14.35-10.584 13.875-7.715-.54-9.187-7.216-8.702-15.263.485-8.046 3.043-14.402 10.585-13.874 6.956.486 9.217 7.237 8.701 15.262z"
        fill="url(#IgAiCoHostFaqGame_svg__paint6_linear_9492_199820)"
      />
      <path
        d="M395.973 206.629c1.456.102 2.722-1.038 2.827-2.545.106-1.507-.989-2.812-2.445-2.914-1.456-.102-2.721 1.038-2.827 2.545-.105 1.508.99 2.812 2.445 2.914z"
        fill="#fff"
      />
      <path
        d="M396.21 225.046c2.664.187 4.922-1.085 5.045-2.84.123-1.755-1.937-3.329-4.6-3.515-2.663-.187-4.922 1.085-5.045 2.84-.122 1.756 1.937 3.329 4.6 3.515z"
        fill="#00CBCD"
      />
      <path
        d="M379.541 226.963c-2.595 3.671-7.574 5.448-13.439 5.675-5.669.198-10.283-1.376-13.844-4.722M379.487 225.428a3.306 3.306 0 001.591 2.515"
        stroke="#000"
        strokeWidth={2.578}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.993 242.281c-8.127-1.316-14.62-3.721-19.478-7.214-4.858-3.492-8.455-10.076-10.791-19.751l14.643 1.086c1.385 6.99 3.893 11.832 7.522 14.526 3.628 2.694 7.122 4.005 10.482 3.932l-2.378 7.421z"
        fill="url(#IgAiCoHostFaqGame_svg__paint7_linear_9492_199820)"
      />
      <path
        d="M277.932 212.568c1.41-6.608 6.469-11.13 11.3-10.1 4.831 1.031 7.605 7.223 6.196 13.831-1.41 6.609-6.469 11.13-11.3 10.1-4.831-1.031-7.605-7.223-6.196-13.831z"
        fill="#000"
      />
      <path
        d="M424.045 256.281c8.127-1.316 14.619-3.721 19.478-7.214 4.858-3.492 8.455-10.076 10.791-19.751l-14.643 1.086c-1.386 6.99-3.893 11.832-7.522 14.526-3.628 2.694-7.123 4.005-10.482 3.932l2.378 7.421z"
        fill="url(#IgAiCoHostFaqGame_svg__paint8_linear_9492_199820)"
      />
      <path
        d="M456.105 226.568c-1.409-6.608-6.468-11.13-11.299-10.1-4.832 1.031-7.605 7.223-6.196 13.831 1.409 6.609 6.469 11.13 11.3 10.1 4.831-1.031 7.605-7.223 6.195-13.831z"
        fill="#000"
      />
      <path
        d="M493.514 180.152l-43.978-16.006a3.6 3.6 0 00-4.614 2.151l-19.085 52.435a3.6 3.6 0 002.152 4.614l43.978 16.007a3.6 3.6 0 004.614-2.152l19.085-52.434a3.601 3.601 0 00-2.152-4.615z"
        fill="#4E47FF"
      />
      <path
        d="M454.315 209.501c1.479.538 2.48 1.395 3.001 2.572.521 1.176.536 2.438.046 3.786-.491 1.347-1.314 2.304-2.469 2.87-1.155.566-2.473.58-3.952.042-1.479-.539-2.479-1.396-3.001-2.572-.521-1.177-.536-2.439-.046-3.786.491-1.348 1.314-2.305 2.469-2.871 1.156-.566 2.473-.58 3.952-.041zm11.955-26.335c4.437 1.615 7.435 3.824 8.991 6.627 1.557 2.802 1.754 5.802.59 8.999-2.153 5.916-7.653 8.246-16.5 6.992-.704 2.027-2.449 2.767-5.233 2.22-4.03-.791-4.765-3.819-2.204-9.087a.758.758 0 01.026-.076c.424-1.166 1.053-1.927 1.885-2.284.833-.357 1.906-.467 3.219-.33l4.79.53c2.058.195 3.327-.365 3.806-1.682.26-.714.138-1.461-.366-2.241s-1.602-1.478-3.295-2.094c-1.541-.561-3.061-.89-4.559-.988-1.498-.098-2.787-.032-3.597-.326-.81-.295-.969-.996-1.167-1.856-.198-.86-.119-1.779.237-2.756.356-.978.949-1.731 1.78-2.259.831-.528 1.831-.846 2.998-.953 1.168-.108 2.479-.024 3.934.25s3.01.712 4.665 1.314z"
        fill="#FF7171"
      />
      <path
        d="M298.968 150.433l-43.785 7.721a3.42 3.42 0 00-2.774 3.962l9.205 52.204a3.42 3.42 0 003.962 2.774l43.785-7.72a3.42 3.42 0 002.774-3.962l-9.205-52.205a3.42 3.42 0 00-3.962-2.774z"
        fill="#4E47FF"
      />
      <path
        d="M279.942 190.418l-5.281-17.359a5.569 5.569 0 014.467-7.125 5.513 5.513 0 016.365 5.419l.091 18.088a2.965 2.965 0 01-2.451 2.936 2.823 2.823 0 01-3.191-1.959zM284.339 199.443c1.514-.267 2.528-1.688 2.266-3.174s-1.701-2.474-3.215-2.207c-1.514.267-2.528 1.687-2.266 3.173s1.701 2.474 3.215 2.208z"
        fill="#FF7171"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M500.061 256.536l-4.321 7.419a.545.545 0 01-.993-.116l-2.405-7.95a.546.546 0 00-.425-.378l-8.312-1.503a.545.545 0 01-.216-.982l7.05-4.957a.543.543 0 00.228-.502l-.865-8.347a.545.545 0 01.862-.498l6.796 4.924c.16.116.37.136.549.053l7.817-3.628a.546.546 0 01.742.679l-2.854 7.95a.546.546 0 00.115.557l5.682 6.058a.544.544 0 01-.395.917l-8.586.034a.548.548 0 00-.469.27z"
        fill="#FF7171"
      />
      <path
        d="M268.689 277.968c2.645-2.219 2.424-6.835-.492-10.31-2.916-3.475-7.423-4.493-10.067-2.275-2.645 2.219-2.424 6.835.492 10.31 2.916 3.475 7.423 4.494 10.067 2.275z"
        fill="#FF7171"
      />
      <defs>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint0_linear_9492_199820"
          x1={961.619}
          y1={3525.46}
          x2={806.213}
          y2={5747.87}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint1_linear_9492_199820"
          x1={-682.435}
          y1={3410.49}
          x2={-837.841}
          y2={5632.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint2_linear_9492_199820"
          x1={369.592}
          y1={245.528}
          x2={366.422}
          y2={285.617}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C1FFFF" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint4_linear_9492_199820"
          x1={1846.82}
          y1={1906.52}
          x2={1734.81}
          y2={3508.28}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint5_linear_9492_199820"
          x1={1190.13}
          y1={1717.37}
          x2={1088.29}
          y2={3173.69}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint6_linear_9492_199820"
          x1={1250.65}
          y1={1721.61}
          x2={1148.81}
          y2={3177.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint7_linear_9492_199820"
          x1={1438.38}
          y1={-168.419}
          x2={217.668}
          y2={-3522.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqGame_svg__paint8_linear_9492_199820"
          x1={-704.343}
          y1={-154.419}
          x2={516.37}
          y2={-3508.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <radialGradient
          id="IgAiCoHostFaqGame_svg__paint3_radial_9492_199820"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(4642.16732 6371.41798 -4434.91892 3231.24864 1230.74 3283.2)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIgAiCoHostFaqGame);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
