/*
 *
 * Asynchronously loads the reducers and epics for Modal
 *
 */
import { errorLoading, getAsyncInjectors } from '@/utils/asyncInjectors';

export default (store) => {
  const { injectReducer, injectEpics } = getAsyncInjectors(store);
  const importModules = Promise.all([
    import(/* webpackMode: "eager" */ './reducer'),
    import(/* webpackMode: "eager" */ './epics'),
  ]);

  return importModules
    .then(([reducer, epics]) => {
      injectReducer('modal', reducer.default);
      injectEpics(epics.default);
    })
    .catch(errorLoading);
};
