export const BRAZE_API_KEY =
  process.env.DEPLOY_ENV === 'production'
    ? '450b25ae-2983-4d0a-aec8-b7b524cd5ba2'
    : '664bb03e-824e-4a4d-954c-61e6eb9675a2';
export const BRAZE_BASE_URL = 'sdk.fra-01.braze.eu';

export const BRAZE_MAIN_REGION = ['JP', 'TW', 'US', 'HK'];

export const BRAZE_CUSTOM_EVENTS = {
  watchedStream: 'Watched stream',
  stoppedWatchingStream: 'Stopped watching stream',
  commented: 'Commented',
};

export const BRAZE_EVENT_PROPERTY = {
  timestamp: 'Time',
  roomID: 'Livestream ID',
  streamerID: 'Streamer ID',
  platform: 'Platform',
  duration: 'View Duration',
};

export const BRAZE_DEFAULT_EVENT_PROPERTY = {
  [BRAZE_EVENT_PROPERTY.platform]: 'WEB',
};

export const BRAZE_DEFAULT_CUSTOM_ATTRIBUTES = {
  WEB_REGION: 'webRegion',
};
