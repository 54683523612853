import { stringify } from 'query-string';
import { forkJoin, from } from 'rxjs';
import { AjaxObservable } from 'rxjs/internal/observable/dom/AjaxObservable';
import { map } from 'rxjs/operators';

import apis from '@/services/17App/shared/apis';

import { GIFT_SLOT, SLOTS_TYPES } from '@17live/app/containers/Gift/constants';
import { getLuckyBagInfoResp } from '@17live/app/containers/Gift/types';
import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { getWhitelistGiftsResp } from '@17live/app/types/Gift';
import {
  getBackendLanguageCode,
  getLang,
  getRegion,
  queryClient,
} from '@17live/app/utils';
import { fetchAPI } from '@17live/app/utils/api';

const API_PATH = 'gift';

export const getLivesGiftTabs = (language = getLang(), roomID, filter = 0) =>
  apis.getJSON(`lives/${roomID}/giftTabs?${stringify({ filter })}`, null, {
    language: getBackendLanguageCode(language),
  });

export const getAllGiftsInfo = (language = getLang()) => {
  const path = 'gifts';

  return from(
    // Use "React Query" to call gifts API instead of "RxJS ajax",
    // so that we can cache the different language gifts by "React Query"
    queryClient.fetchQuery(
      [path, language],
      () => {
        return fetchAPI(path, {
          // Not allow cache response by browser
          cache: 'no-cache',
          headers: {
            language: getBackendLanguageCode(language),
          },
        });
      },
      {
        // we already cache API in service worker cache
        cacheTime: 0,
        staleTime: 0,
      }
    )
  );
};

export const getWhitelistGifts = (streamerUserID: string) => {
  const queryKey = ['gifts', 'whitelist', streamerUserID];

  return from(
    queryClient.fetchQuery(
      queryKey,
      () => {
        return fetchAPI<getWhitelistGiftsResp>(
          `gifts/whitelist?streamerID=${streamerUserID}`
        )
          .catch(() => {
            // 如果 whitelist API 掛掉，應該要不影響送禮功能，畢竟 whitelist 只是多標記 label 而已
            return { gifts: [] } as getWhitelistGiftsResp;
          })
          .then(data => ({
            ...data,
            // [Workaround] 原本預期一定會有 gifts 欄位，但發現有可能會連欄位都沒有，所以這裡補上 gifts
            gifts: data.gifts ?? [],
          }));
      },
      {
        cacheTime: 0,
        staleTime: 0,
      }
    )
  );
};

/**
 * Get all gift info.
 * @param {string} region - region will inject header
 * @param {string} srcID - roomID in the given source type
 * @param {number} filter - 1: 主播開播前要選擇自訂活動的禮物時使用
 *
 * 舊的禮物 API /api/v1/gift?srcID={roomID}&srcType=1
 * 在設計上未符合 RESTful 規範，也較難實作 cache 機制，
 * 因此 BE 開發了新的禮物 API ，拆成 /api/v1/lives/{roomID}/giftTabs + /api/v1/gifts，
 * giftTabs API 只會有當前可用的禮物，而所有禮物都改從另一支 gifts API 取得
 * ref: https://docs.google.com/document/d/1wMVxFrWn27ABAiqYjBZxRZiPNKLWXoC4vg_zRZES3cs/edit#heading=h.c2lbteld9uqq
 */
export const getGiftInfo = (language = getLang(), srcID, filter) =>
  forkJoin([
    getLivesGiftTabs(language, srcID, filter),
    getAllGiftsInfo(language),
  ]).pipe(
    map(([liveGiftTabs, allGiftsInfo]) => {
      const levelUps = liveGiftTabs.meta?.levelUps ?? [];

      (liveGiftTabs?.tabs ?? []).forEach(tab => {
        tab.tabID = tab.id; // [rename issue] old API use 'tabID', but new API use 'id'
      });

      return { ...liveGiftTabs, allGifts: allGiftsInfo.gifts, levelUps };
    })
  );

export const getLuckyBagInfo = (
  language = getLang(),
  giftIDs: string[] = [],
  streamerUserID?: string
): AjaxObservable<getLuckyBagInfoResp> =>
  apis.getJSON(
    `${API_PATH}/luckybag?${stringify(
      {
        'giftID[]': giftIDs,
        streamerID: streamerUserID,
      },
      { arrayFormat: 'comma' }
    )}`,
    null,
    {
      language: getBackendLanguageCode(language),
    }
  );

export const getSlotsInfo = (
  slotTab = SLOTS_TYPES[GIFT_SLOT],
  language = 'TW',
  streamerUserID?: string
) =>
  apis.getJSON(
    `${API_PATH}/slots?${stringify({ slotTab, streamerID: streamerUserID })}`,
    null,
    {
      language: getBackendLanguageCode(language),
    }
  );

export const sendGift = (roomID, giftID, traceID, tabID) => {
  return apis.postJSON(
    `lives/${roomID}/gift`,
    {
      srcType: 1,
      giftID,
      tabID,
    },
    setTrackAPIHeaders({
      'trace-id': traceID,
    }),
    {
      retries: 0, // do not retry
    }
  );
};

/**
 * For user
 * srcID
 * room ID in the given source type
 * required: false
 *
 * srcType
 * source type of the gift list
 * required: false
 * should be given with default 1
 */
export const getRecentSentGift = (language = getLang(), srcID, srcType = 1) =>
  apis.getJSON(`${API_PATH}/recent?${stringify({ srcType, srcID })}`, null, {
    language: getBackendLanguageCode(language),
  });

export const getGoodsInfo = (region = getRegion(), language = getLang()) =>
  apis.getJSON(
    `${API_PATH}/goods?${stringify({ eventRegion: region })}`,
    null,
    {
      language: getBackendLanguageCode(language),
    }
  );

export const sendGoods = (liveStreamID, itemID) =>
  apis.postJSON(
    `${API_PATH}/goods`,
    {
      liveStreamID,
      itemID,
    },
    {},
    {
      retries: 0, // do not retry
    }
  );

export const sendGiftToAllInGroupCall = (giftID, groupCallID, traceID, tabID) =>
  apis.postJSON(
    `${API_PATH}/giftbox/groupCall`,
    {
      giftID,
      groupCallID,
      tabID,
    },
    setTrackAPIHeaders({ 'trace-id': traceID }),
    {
      retries: 0, // do not retry
    }
  );
