import { createContext } from 'react';

export enum ModalActionType {
  OPEN_MODAL,
  CLOSE_MODAL,
}

export type ModalAction = {
  type: ModalActionType;
  payload?: any;
};

export const ModalContext = createContext<React.Dispatch<ModalAction>>(
  () => null
);

export default ModalContext;
