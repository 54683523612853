import { ofType } from 'redux-observable';

import { from as fromPromise, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { errorAction } from '@/utils/errorAction';

import { LANG } from '@17live/app/constants';

import { addLang, changeLang, setLang, setRegion } from './actions';
import { CHANGE_LANG, CHANGE_REGION } from './constants';
import { makeSelectLang, makeSelectMessages } from './selectors';

const messagesSelector = makeSelectMessages();
const languageSelector = makeSelectLang();

export const handleChangeLocaleEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_LANG),
    map(({ payload: toLocale }) => ({
      from: languageSelector(state$.value),
      to: toLocale,
    })),
    map(({ from, to }) => setLang({ from, to }))
  );

export const addLocaleDataEpic = (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_LANG),

    filter(({ payload: lang }) => {
      return !messagesSelector(state$.value)[lang];
    }),
    switchMap(({ payload: lang }) => {
      return fromPromise(
        import(
          /* webpackChunkName: "translation.[request]" */ `../../../../app/translations/${lang}.json`
        )
      ).pipe(
        map(translation => {
          return addLang(lang, translation.default);
        }),
        catchError(err =>
          of(
            errorAction(CHANGE_LANG, err),
            // set locale to fallback message to prevent app crash
            changeLang(LANG.EN)
          )
        )
      );
    })
  );

export const handleChangeRegionEpic = action$ =>
  action$.pipe(
    ofType(CHANGE_REGION),
    map(({ payload: region }) => setRegion({ to: region }))
  );

export default [
  handleChangeLocaleEpic,
  addLocaleDataEpic,
  handleChangeRegionEpic,
];
