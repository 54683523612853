import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import { VLIVE_ENABLED_MODEL_TABS_ORDER } from '@17live/app/components/VLiveCharacter/VLiveCharacter.constants';
import VLiveCharacterIntroductionFromAppCreate from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppCreate';
import VLiveCharacterIntroductionFromAppEasyCreationByImage from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppEasyCreationByImage';
import VLiveCharacterIntroductionFromAppLive2D from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppLive2D';
import VLiveCharacterIntroductionFromAppVRM from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppVRM';
import VLiveCharacterTabList from '@17live/app/components/VLiveCharacter/VLiveCharacterTabList';
import { VliverModel, VliverTab } from '@17live/app/enums/Vliver.enums';
import { COLOR_NEUTRAL_200 } from '@17live/design-system/color';

const VLiveCharacterIntroductionFromAppWrapper = styled.div<{
  hasTab: boolean;
}>`
  ${props =>
    props.hasTab &&
    css`
      border: 1px solid ${COLOR_NEUTRAL_200};
      border-radius: 4px;
    `};
`;

type VLiveCharacterIntroductionFromAppProps = {
  characterSizeLimitMB?: number;
  easyCreationImageSizeLimitMB?: number;
  enabledModels: VliverModel[];
};

const VLiveCharacterIntroductionFromApp = ({
  characterSizeLimitMB,
  easyCreationImageSizeLimitMB,
  enabledModels,
}: VLiveCharacterIntroductionFromAppProps) => {
  const [activeTab, setActiveTab] = useState<VliverTab | null>(null);

  const { id: activeTabID } = activeTab || { id: VliverModel.NONE };

  const tabs: VliverTab[] = useMemo(() => {
    const result: VliverTab[] = [];

    VLIVE_ENABLED_MODEL_TABS_ORDER.forEach(model => {
      if (enabledModels.find(enabledModel => enabledModel === model.id)) {
        result.push(model);
      }
    });

    return result;
  }, [enabledModels]);

  // we need to transfer the enum of v-liver mode to the index of TabList
  const hasTab = tabs.length >= 2;

  const handleTabChange = useCallback(
    (index: number) => {
      setActiveTab(tabs.find(tab => tab.id === index) || tabs[0]);
    },
    [tabs]
  );

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, [tabs]);

  return (
    <VLiveCharacterIntroductionFromAppWrapper hasTab={hasTab}>
      {hasTab && (
        <VLiveCharacterTabList
          tabs={tabs}
          activeTabID={activeTabID}
          onTabChange={handleTabChange}
        />
      )}
      {activeTabID === VliverModel.LIVE2D && (
        <VLiveCharacterIntroductionFromAppLive2D
          characterSizeLimitMB={characterSizeLimitMB}
          hasTab={hasTab}
        />
      )}
      {activeTabID === VliverModel.VRM && (
        <VLiveCharacterIntroductionFromAppVRM hasTab={hasTab} />
      )}
      {activeTabID === VliverModel.EASY_CREATION_BY_IMAGE && (
        <VLiveCharacterIntroductionFromAppEasyCreationByImage
          easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
          hasTab={hasTab}
        />
      )}
      {activeTabID === VliverModel.CREATE && (
        <VLiveCharacterIntroductionFromAppCreate hasTab={hasTab} />
      )}
    </VLiveCharacterIntroductionFromAppWrapper>
  );
};

export default VLiveCharacterIntroductionFromApp;
