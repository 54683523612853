import React, { useEffect, useMemo, useState } from 'react';

import { createGlobalStyle } from 'styled-components';

import DriverJS from 'driver.js';

import Portal from '@17live/ui/Portal';

import { useDriver } from './hooks';

export type Props = {
  isTurnOn: boolean;
  highlightElement?: Element | string;
  noMask?: boolean;
  noCloseOnOutside?: boolean;
  popover?: Omit<DriverJS.PopoverOptions, 'title' | 'description'>;
};

const NoMaskStyle = createGlobalStyle`
  #driver-page-overlay {
    display: none!important;
  }
`;

const Driver: React.FC<Props> = ({
  children,
  highlightElement,
  isTurnOn,
  noMask,
  noCloseOnOutside,
  popover = {},
}) => {
  const { off } = useDriver();
  const driver = useMemo(
    () =>
      new DriverJS({
        keyboardControl: false,
        onReset: off,
      }),
    [off]
  );
  const [
    highlightedStage,
    setHighlightedStage,
  ] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isTurnOn) {
      if (highlightElement) {
        driver.highlight({
          element:
            typeof highlightElement === 'string'
              ? highlightElement
              : `#${highlightElement.id}`,
        });

        if (noCloseOnOutside) {
          document.addEventListener('click', ev => {
            ev.stopPropagation();
          });
        }

        const stage = document.querySelector<HTMLDivElement>(
          '#driver-highlighted-element-stage'
        );

        if (stage) {
          setHighlightedStage(stage);
        } else {
          driver.reset();
        }
      }
    } else {
      driver.reset();
    }
  }, [driver, highlightElement, isTurnOn, noCloseOnOutside]);

  useEffect(() => {
    return () => {
      driver.reset();
    };
  }, [driver]);

  return isTurnOn && highlightedStage ? (
    <Portal element={highlightedStage}>
      {noMask && <NoMaskStyle />}
      {children}
    </Portal>
  ) : null;
};

export default Driver;
