import apis from '@/services/17App/shared/apis';

const API_PATH = 'lives';

export const publishStream = liveStreamID =>
  apis.patchJSON(`${API_PATH}/${liveStreamID}`, {});

export const endStream = liveStreamID =>
  apis.deleteJSON(`${API_PATH}/${liveStreamID}`, { reason: 'normalEnd' });

export const keepStream = liveStreamID =>
  apis.postJSON(`${API_PATH}/${liveStreamID}/alive`, {});

export const getConcertInfo = roomID =>
  apis.getJSON(`${API_PATH}/${roomID}/concertInfo`, {}, {}, { retries: 0 });
