import styled from 'styled-components';

import Loadable from '@/components/Loadable';

const Stretch = styled.div`
  min-height: 100%;
`;

export default Loadable({
  loading: Stretch,
  loader: () =>
    import(
      /* webpackChunkName: "MainContent" */
      /* webpackPreload: true */
      '../'
    ),
});
