// @flow
export const ANDROID: { [string]: string } = {
  SCHEME: process.env.DEPLOY_ENV === 'production' ? 'https' : 'http',
  PACKAGE_NAME: 'com.machipopo.media17',
};

export const IOS: { [string]: string } = {
  SCHEME: 'media17://',
};

export const DEEP_LINK_VERSION = 'v2';

export const DYNAMIC_LINK = {
  live: {
    pageName: 'live',
    campaignID: 'livestream',
  },
  clip: {
    pageName: 'clip',
    campaignID: 'clip',
  },
  recap: {
    pageName: 'recap',
    campaignID: 'recap',
  },
  vod: {
    pageName: 'vod',
    campaignID: 'vod',
  },
  post: {
    pageName: 'post',
    campaignID: 'post',
  },
  profile: {
    pageName: 'streamer_profile',
    campaignID: 'profilepage',
  },
};

export const DOWNLOAD_LINK_IOS = 'https://itunes.apple.com/us/app/id988259048?mt=8';
export const DOWNLOAD_LINK_ANDROID = `https://play.google.com/store/apps/details?id=${ANDROID.PACKAGE_NAME}`;
export const DOWNLOAD_LINK = 'https://17app.onelink.me/3980265200?pid=17.live';
export const ONE_LINK = 'https://17appv2.onelink.me/D7OH';
