import * as React from 'react';
function SvgIgAiCoHostFaqTrending(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 720 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <g filter="url(#IgAiCoHostFaqTrending_svg__filter0_f_9864_414199)">
        <path
          d="M357.276 331.5c78.837 0 142.747-63.911 142.747-142.748 0-78.837-63.91-142.747-142.747-142.747-78.837 0-142.747 63.91-142.747 142.747 0 78.837 63.91 142.748 142.747 142.748z"
          fill="#7388FF"
          fillOpacity={0.1}
        />
      </g>
      <g filter="url(#IgAiCoHostFaqTrending_svg__filter1_f_9864_414199)">
        <path
          d="M357.276 298.911c60.839 0 110.159-49.319 110.159-110.158s-49.32-110.16-110.159-110.16c-60.839 0-110.159 49.321-110.159 110.16s49.32 110.158 110.159 110.158z"
          fill="#7388FF"
          fillOpacity={0.2}
        />
      </g>
      <path
        d="M285.949 213.587c-1.725 10.647-4.875 19.152-9.45 25.516-4.576 6.365-13.201 11.077-25.875 14.138l1.422-19.183c9.157-1.815 15.5-5.1 19.03-9.854 3.529-4.754 5.246-9.331 5.15-13.732l9.723 3.115z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint0_linear_9864_414199)"
      />
      <path
        d="M238.607 239.731c3.321-8.205 10.877-12.888 16.875-10.459 5.999 2.428 8.169 11.048 4.847 19.253s-10.877 12.887-16.875 10.459c-5.999-2.429-8.169-11.048-4.847-19.253z"
        fill="#000"
      />
      <path
        d="M431.937 209.614c-.15 10.784 1.475 19.707 4.876 26.77 3.4 7.062 11.076 13.2 23.026 18.415l1.931-19.138c-8.703-3.378-14.379-7.714-17.03-13.009-2.65-5.294-3.546-10.1-2.687-14.417l-10.116 1.379z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint1_linear_9864_414199)"
      />
      <path
        d="M474.02 243.581c-1.847-8.657-8.474-14.58-14.803-13.231-6.329 1.35-9.963 9.462-8.117 18.119 1.847 8.657 8.474 14.581 14.803 13.231 6.329-1.35 9.963-9.462 8.117-18.119z"
        fill="#000"
      />
      <path
        d="M281.407 143.312l-14.774 4.888a12.846 12.846 0 00-8.812 12.196v36.848c0 5.212 3.149 9.907 7.971 11.885l15.615 6.406v-72.223z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint2_linear_9864_414199)"
      />
      <mask
        id="IgAiCoHostFaqTrending_svg__a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={257}
        y={143}
        width={25}
        height={73}
      >
        <path
          d="M281.407 143.312l-14.774 4.888a12.846 12.846 0 00-8.812 12.196v36.848c0 5.212 3.149 9.907 7.971 11.885l15.615 6.406v-72.223z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqTrending_svg__a)">
        <path
          d="M269.614 143.312h-11.793v72.223h11.793v-72.223z"
          fill="#4E47FF"
        />
      </g>
      <path
        d="M433.858 143.312l14.775 4.888a12.847 12.847 0 018.811 12.196v36.848c0 5.212-3.149 9.907-7.971 11.885l-15.615 6.406v-72.223z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint3_linear_9864_414199)"
      />
      <mask
        id="IgAiCoHostFaqTrending_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={433}
        y={143}
        width={25}
        height={73}
      >
        <path
          d="M433.858 143.312l14.775 4.888a12.847 12.847 0 018.811 12.196v36.848c0 5.212-3.149 9.907-7.971 11.885l-15.615 6.406v-72.223z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqTrending_svg__b)">
        <path
          d="M445.651 143.312h11.793v72.223h-11.793v-72.223z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M346.944 103.697c-10.657-8.475-20.033-14.728-28.13-18.76-12.146-6.05-39.384-9.037-48.152-3.182-8.767 5.855-5.969 31.21-2.54 47.056 2.285 10.563 5.676 20.482 10.173 29.756.168-12.763 4.902-24.523 14.201-35.281 9.3-10.759 27.449-17.288 54.448-19.589z"
        fill="#fff"
      />
      <path
        d="M310.006 108.673c4.739-2.962-25.582-27.063-35.575-19.31-9.994 7.751 2.829 57.748 4.802 53.204 1.972-4.543 2.378-8.825 9.446-17.892 7.069-9.067 16.588-13.041 21.327-16.002z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaqTrending_svg__c"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={270}
        y={87}
        width={41}
        height={56}
      >
        <path
          d="M310.006 108.673c4.739-2.962-25.582-27.063-35.575-19.31-9.994 7.751 2.829 57.748 4.802 53.204 1.972-4.543 2.378-8.825 9.446-17.892 7.069-9.067 16.588-13.041 21.327-16.002z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqTrending_svg__c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M317.815 112.303c4.739-2.962-25.582-27.063-35.575-19.31-9.994 7.751 2.829 57.748 4.801 53.204 1.973-4.543 2.379-8.825 9.447-17.892s16.588-13.041 21.327-16.002z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M369.211 103.716c10.657-8.474 20.034-14.728 28.131-18.76 12.145-6.049 39.384-9.037 48.151-3.182 8.767 5.855 5.969 31.21 2.541 47.056-2.286 10.563-5.677 20.482-10.173 29.756-.169-12.763-4.903-24.523-14.202-35.281-9.3-10.759-27.449-17.288-54.448-19.589z"
        fill="#fff"
      />
      <path
        d="M406.149 108.692c-4.739-2.962 25.582-27.063 35.575-19.31 9.994 7.751-2.829 57.748-4.801 53.204-1.973-4.543-2.379-8.825-9.447-17.892s-16.588-13.041-21.327-16.002z"
        fill="#908CFF"
      />
      <mask
        id="IgAiCoHostFaqTrending_svg__d"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={405}
        y={87}
        width={41}
        height={56}
      >
        <path
          d="M406.149 108.692c-4.739-2.962 25.582-27.063 35.575-19.31 9.994 7.751-2.829 57.748-4.801 53.204-1.973-4.543-2.379-8.825-9.447-17.892s-16.588-13.041-21.327-16.002z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#IgAiCoHostFaqTrending_svg__d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M398.34 112.322c-4.739-2.962 25.582-27.063 35.576-19.31 9.993 7.751-2.829 57.748-4.802 53.204-1.973-4.543-2.379-8.825-9.447-17.892s-16.588-13.041-21.327-16.002z"
          fill="#4E47FF"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.729 126.283l3.65 4.729a6.697 6.697 0 005.303 2.606h20.537a6.697 6.697 0 005.237-2.522l3.838-4.813h-38.565z"
        fill="#211F8A"
      />
      <path
        d="M388.624 270.052a871.453 871.453 0 01-61.983 0c-24.773-1.066-47.401-20.901-48.617-42.617-.839-18.109-.839-62.279 0-80.392 1.216-21.701 23.844-41.552 48.617-42.617a876.219 876.219 0 0161.983 0c24.773 1.065 47.401 20.916 48.617 42.617.839 18.113.839 62.283 0 80.392-1.216 21.716-23.844 41.551-48.617 42.617z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint4_linear_9864_414199)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.271 237.881l3.318-8.856a3.602 3.602 0 016.796.146l2.801 8.574a3.6 3.6 0 002.45 2.349l7.819 2.195a3.073 3.073 0 01.138 5.877l-8.117 2.693a3.6 3.6 0 00-2.272 2.248l-2.835 8.254a3.602 3.602 0 01-6.757.15l-3.366-8.543a3.601 3.601 0 00-2.184-2.087l-7.936-2.717a3.08 3.08 0 01.14-5.875l7.634-2.212a3.599 3.599 0 002.371-2.196z"
        fill="#4E47FF"
      />
      <path
        d="M396.119 120.254h-80.158c-18.497 0-33.492 14.995-33.492 33.492v35.055c0 18.497 14.995 33.492 33.492 33.492h80.158c18.497 0 33.492-14.995 33.492-33.492v-35.055c0-18.497-14.995-33.492-33.492-33.492z"
        fill="#221CBF"
      />
      <path
        d="M395.132 122.487h-78.184c-17.264 0-31.259 13.995-31.259 31.259v35.055c0 17.264 13.995 31.259 31.259 31.259h78.184c17.264 0 31.259-13.995 31.259-31.259v-35.055c0-17.264-13.995-31.259-31.259-31.259z"
        fill="#645EFF"
      />
      <path
        d="M394.124 126.283h-76.168c-14.798 0-26.794 11.996-26.794 26.793v36.395c0 14.798 11.996 26.794 26.794 26.794h76.168c14.798 0 26.794-11.996 26.794-26.794v-36.395c0-14.797-11.996-26.793-26.794-26.793z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint5_radial_9864_414199)"
      />
      <path
        d="M356.246 189.134c11.658 0 21.109-9.418 21.109-21.035s-9.451-21.035-21.109-21.035c-11.658 0-21.109 9.418-21.109 21.035s9.451 21.035 21.109 21.035z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint6_linear_9864_414199)"
      />
      <path
        d="M350.234 159.753a3.81 3.81 0 003.817-3.803 3.81 3.81 0 00-3.817-3.804 3.81 3.81 0 00-3.816 3.804 3.81 3.81 0 003.816 3.803z"
        fill="#00FFFA"
        fillOpacity={0.3}
      />
      <path
        d="M372.595 192.087a1.69 1.69 0 112.689 2.044c-3.772 4.96-10.602 7.45-19.198 7.482-7.863 0-14.267-2.438-19.107-7.315a1.688 1.688 0 112.397-2.379c4.177 4.21 9.712 6.316 16.704 6.316 7.637-.028 13.481-2.158 16.515-6.148z"
        fill="#000"
      />
      <path
        d="M338.053 189.412a1.689 1.689 0 011.582 1.789 6.023 6.023 0 01-2.734 4.679 1.69 1.69 0 01-1.838-2.834 2.643 2.643 0 001.201-2.052 1.689 1.689 0 011.789-1.582zM373.836 189.412a1.689 1.689 0 00-1.582 1.789 6.02 6.02 0 002.734 4.679 1.688 1.688 0 101.838-2.834 2.638 2.638 0 01-1.201-2.052 1.689 1.689 0 00-1.789-1.582z"
        fill="#000"
      />
      <path
        d="M335.853 271.78c-4.279-.2-7.76-2.256-10.441-6.169-4.74-4.051-7.458-4.051-8.153 0-1.043 6.077 9.913 14.954 13.728 17.041 2.543 1.391 6.788 2.152 12.735 2.282l6.688-15.407c-5.425 1.701-10.277 2.452-14.557 2.253z"
        fill="#C3FFFE"
      />
      <path
        d="M338.206 283.179c-2.233 10.014 3.014 19.725 11.737 21.658 8.723 1.933 11.014-6.055 13.247-16.069 2.233-10.013 3.566-18.234-5.143-20.185-8.71-1.952-17.556 4.592-19.841 14.596z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M351.566 305.137l6.449-36.575a7.581 7.581 0 017.316 8.896l-3.629 20.582a8.75 8.75 0 01-10.136 7.097z"
        fill="#633DFF"
      />
      <path
        d="M381.961 271.78c4.28-.2 7.761-2.256 10.442-6.169 4.74-4.051 7.458-4.051 8.153 0 1.043 6.077-9.913 14.954-13.728 17.041-2.543 1.391-6.788 2.152-12.735 2.282l-6.688-15.407c5.425 1.701 10.277 2.452 14.556 2.253z"
        fill="#C3FFFE"
      />
      <path
        d="M379.609 283.179c2.233 10.014-3.014 19.725-11.737 21.658-8.723 1.933-11.014-6.055-13.247-16.069-2.233-10.013-3.566-18.234 5.143-20.185 8.709-1.952 17.556 4.592 19.841 14.596z"
        fill="#C3FFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.249 305.137l-6.449-36.575a7.581 7.581 0 00-7.316 8.896l3.629 20.582a8.75 8.75 0 0010.136 7.097z"
        fill="#633DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M461.51 107.251c1.464-5.604 6.414-14.58 14.848-26.93 12.652-18.524 25.751-21.709 25.751-20.117 0 1.593-22.326 32.73-30.154 41.714-5.219 5.988-8.7 7.766-10.445 5.333zM252.131 67.698c-1.026-3.822-4.496-9.944-10.408-18.365-8.868-12.633-18.05-14.805-18.05-13.719 0 1.086 15.649 22.32 21.137 28.447 3.658 4.084 6.098 5.296 7.321 3.637zM503.723 115.76c3.293-4.766 11.014-11.508 23.163-20.228 18.225-13.08 31.623-11.592 31.078-10.096-.544 1.496-32.173 23.121-42.602 28.884-6.952 3.843-10.832 4.323-11.639 1.44zM208.678 93.429c-3.292-4.765-11.013-11.508-23.163-20.228-18.224-13.08-31.622-11.592-31.078-10.096.545 1.497 32.174 23.121 42.602 28.885 6.953 3.842 10.832 4.322 11.639 1.439zM244.317 279.885c-5.773.469-15.473 3.784-29.099 9.946-20.44 9.243-41.062 26.157-39.494 26.433 1.568.277 51.321-20.869 61.527-27.019 6.804-4.099 9.159-7.219 7.066-9.36zM266.251 286.787c-1.445-2.326-8.291 5.706-15.064 13.034s-17.303 19.506-15.969 20.966c1.334 1.461 14.703-12.221 19.489-18.568 4.787-6.347 12.988-13.106 11.544-15.432z"
        fill="#E2E2E2"
      />
      <path
        d="M397.259 171.273c7.736-.814 12.561-6.5 10.173-8.166-2.388-1.667-4.19 4.554-10.173 4.554-5.982 0-10.999-5.155-13.903-3.588-2.904 1.567 6.168 8.015 13.903 7.2z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint7_linear_9864_414199)"
      />
      <path
        d="M315.559 171.273c-7.736-.814-12.561-6.5-10.173-8.166 2.388-1.667 4.19 4.554 10.173 4.554s10.999-5.155 13.903-3.588c2.904 1.567-6.167 8.015-13.903 7.2z"
        fill="url(#IgAiCoHostFaqTrending_svg__paint8_linear_9864_414199)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M461.634 274.296c-3.221 2.74 21.655 14.656 33.742 17.946 12.088 3.29 24.84 8.691 24.84 6.669 0-2.022-2.851-3.379-24.84-13.781-21.989-10.401-30.52-13.574-33.742-10.834zM460.091 298.157c7.248 10.33 13.36 17.263 18.337 20.8 7.465 5.306 9.482 8.455 11.699 6.856 2.218-1.598 2.747.991-9.901-11.797-8.432-8.525-15.143-13.811-20.135-15.859z"
        fill="#E2E2E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M579.4 269.919l10.365-17.953-39.663-10.097-15.688 27.174c-1.741 3.014-4.636 4.977-7.904 5.708-3.203.718-6.763.25-9.928-1.577-3.164-1.827-5.349-4.676-6.329-7.809-1.001-3.196-.748-6.68.994-9.697 1.741-3.015 4.634-4.975 7.901-5.71 3.204-.718 6.765-.246 9.926 1.58 2.932 1.692 5.025 4.263 6.096 7.121l20.46-35.438.006.003a2.268 2.268 0 012.521-1.062l44.08 11.223a2.267 2.267 0 011.553 3.365l-25.147 43.556c-1.74 3.014-4.634 4.974-7.901 5.709-3.203.718-6.762.247-9.926-1.579-3.164-1.827-5.352-4.678-6.331-7.807-1.001-3.195-.746-6.682.994-9.697 1.741-3.014 4.634-4.974 7.901-5.71 3.204-.718 6.765-.245 9.926 1.58 2.929 1.691 5.025 4.263 6.096 7.121l-.002-.004z"
        fill="#FF7171"
      />
      <path
        d="M590.258 293.229l-12.104 20.965c-1.343 2.325-3.576 3.84-6.097 4.404-2.472.554-5.218.192-7.66-1.217-2.441-1.409-4.127-3.607-4.883-6.025-.772-2.465-.577-5.154.767-7.481 1.343-2.326 3.575-3.838 6.096-4.405 2.471-.554 5.219-.19 7.658 1.218 2.262 1.306 3.876 3.289 4.703 5.495l15.785-27.341.004.002a1.75 1.75 0 011.945-.819c.213 5.941.666 9.845 1.358 11.713 2.214 5.973 10.25 7.928 9.513 9.732-.249.609-5.05.741-12.816-2.563-1.027-.437-2.45-1.663-4.269-3.678z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M544.358 79.204l-7.28 12.497a.918.918 0 01-1.672-.196l-4.052-13.391a.918.918 0 00-.716-.638l-14.001-2.531a.918.918 0 01-.364-1.655l11.875-8.348a.917.917 0 00.385-.846l-1.458-14.061a.918.918 0 011.452-.838l11.448 8.293c.27.195.623.23.925.09l13.168-6.11a.918.918 0 011.25 1.142l-4.808 13.391a.919.919 0 00.195.939l9.57 10.204a.918.918 0 01-.666 1.546l-14.462.057a.916.916 0 00-.789.455z"
        fill="#FF7171"
      />
      <path
        d="M150.003 226.792c20.01 0 37.105 12.651 43.925 30.494l16.562 2.348-13.401 14.397.004.496c0 26.1-20.663 47.308-46.311 47.73l-.779.006c-26.007 0-47.09-21.372-47.09-47.736 0-26.363 21.083-47.735 47.09-47.735z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.619 294.392l16.706-34.728a.984.984 0 011.353-.439l18.361 9.916a.983.983 0 001.382-.506l8.726-22.255a.983.983 0 00-.778-1.332l-9.516-1.342a.984.984 0 01-.409-1.791l22.152-14.817a.983.983 0 011.498.571l6.65 25.665a.984.984 0 01-1.562 1.018l-7.261-5.74a.984.984 0 00-1.533.435l-13.186 36.199a.983.983 0 01-1.404.522l-19.078-10.681a.983.983 0 00-1.379.46l-11.415 25.796a.983.983 0 01-1.52.365l-7.522-6.128a.984.984 0 01-.265-1.188zM173.657 159.051c-4.251 4.104-2.357 9.765-2.357 9.765a10.64 10.64 0 00-3.32-.537 9.095 9.095 0 00-6.521 2.553c-4.252 4.107-4.425 10.973 1.358 15.414 3.608 2.771 11.64 3.376 24.097 1.817a3.67 3.67 0 003.148-2.941c1.161-5.958 1.761-10.257 1.802-12.895.059-4.513-.731-8.311-2.849-11.285-4.238-5.93-11.106-5.998-15.358-1.891z"
        fill="#FF7171"
      />
      <path
        d="M156.331 108.853c10.153-.177 18.236-8.745 18.054-19.136-.181-10.392-8.558-18.673-18.711-18.495-10.152.177-18.235 8.745-18.053 19.136.181 10.392 8.558 18.673 18.71 18.495z"
        fill="#4E47FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.363 95.448c-5.979 4.528-8.424 8.519-7.335 11.973 1.633 5.181 23.135-1.928 32.408-7.575 9.273-5.646 23.862-16.436 23.754-22.604-.072-4.111-5.884-3.984-17.437.382l1.217 1.501c9.257-3.637 13.339-3.8 12.245-.487-1.641 4.967-14.778 12.933-21.91 17.17-7.133 4.236-24.814 11.346-27.195 9.624-1.587-1.148.021-3.904 4.824-8.27l-.571-1.714z"
        fill="#FF7171"
      />
      <defs>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint0_linear_9864_414199"
          x1={-252.076}
          y1={-1264.62}
          x2={-4645.72}
          y2={334.534}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint1_linear_9864_414199"
          x1={1218.48}
          y1={-1152.71}
          x2={5267.68}
          y2={1185.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBFFFF" />
          <stop offset={1} stopColor="#E3FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint2_linear_9864_414199"
          x1={1437.13}
          y1={4447.08}
          x2={1437.13}
          y2={7365.59}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint3_linear_9864_414199"
          x1={-721.868}
          y1={4447.08}
          x2={-721.868}
          y2={7365.59}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#F9FFFE" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint4_linear_9864_414199"
          x1={8301.17}
          y1={10039.1}
          x2={8301.17}
          y2={16776.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C1FFFF" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint6_linear_9864_414199"
          x1={2445.99}
          y1={2250.52}
          x2={2445.99}
          y2={4353.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint7_linear_9864_414199"
          x1={1645.04}
          y1={588.889}
          x2={1645.04}
          y2={1014.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <linearGradient
          id="IgAiCoHostFaqTrending_svg__paint8_linear_9864_414199"
          x1={-932.218}
          y1={588.889}
          x2={-932.218}
          y2={1014.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#1493AB" />
        </linearGradient>
        <filter
          id="IgAiCoHostFaqTrending_svg__filter0_f_9864_414199"
          x={212.693}
          y={44.169}
          width={289.167}
          height={289.167}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={0.918}
            result="effect1_foregroundBlur_9864_414199"
          />
        </filter>
        <filter
          id="IgAiCoHostFaqTrending_svg__filter1_f_9864_414199"
          x={245.281}
          y={76.758}
          width={223.99}
          height={223.989}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={0.918}
            result="effect1_foregroundBlur_9864_414199"
          />
        </filter>
        <radialGradient
          id="IgAiCoHostFaqTrending_svg__paint5_radial_9864_414199"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(49.923 -3527.024 3950.559) scale(10327.1 7188.34)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = React.forwardRef(SvgIgAiCoHostFaqTrending);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
