import React from 'react';
import styled, { css } from 'styled-components';
import { ModalConsumer } from '@/components/ReactRouteModal';
import { mobileCSS } from '@/utils/media';
import { gradient } from '@/utils/styles';

const BodyForm = styled.form`
  ${props => props.styles};
`;

export const responsiveStyle = mobileCSS`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100%;
  justify-content: flex-start;
  border-radius: 0;
  border: 0;
`;

export const gradientStyle = css`
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-image: ${gradient.left()};
  }
`;

/**
 * @deprecated
 * Please use GlobalModal/ModalBody instead.
 */
const ModalBody = styled(({ hasGradient, onOpenStateChanged, ...props }) => (
  <ModalConsumer>
    {({ getBodyProps, getBodyStyles }) => (
      <BodyForm
        {...getBodyProps({
          ...props,
          styles: getBodyStyles(),
        })}
      />
    )}
  </ModalConsumer>
))`
  ${props => props.hasGradient && gradientStyle};
`;

export default ModalBody;
