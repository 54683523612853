import { isHandheld } from '@/services/Device';
import { isFromIframe } from '@/containers/ThirdPartyAction/utils';
import {
  init,
  getLoginStatus,
  getUserName,
  share,
  getFacebookUserID,
  login as loginBySDK,
} from './api';
import { login as loginByManual } from './manual';

/**
 * Swap login method to manual flow for webview in native app
 *
 * https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow
 */
const login = (isHandheld() && !isFromIframe()) ? loginByManual : loginBySDK;

export {
  init,
  getLoginStatus,
  getUserName,
  login,
  share,
  getFacebookUserID,
};
