import { Observable } from 'rxjs';
import { mergeMap, share as opShare } from 'rxjs/operators';
import $script from 'scriptjs';

import { FACEBOOK_LOGIN_CANCELLED_ERROR } from '../../containers/ThirdPartyAction/constants';
import configs from './configs';

export const FACEBOOK_STATUS_CONNECTED = 'connected';
export const FACEBOOK_STATUS_NOT_AUTHORIZED = 'not_authorized';

const { appId, version, sdkUrl, status } = configs;

export const init = Observable.create(observer => {
  if (window.FB) {
    observer.next();
    observer.complete();
    return;
  }

  const locale = navigator.language.replace('-', '_');

  $script(sdkUrl(locale), () => {
    /**
     * ref: https://developers.facebook.com/docs/facebook-login/web?locale=zh_TW
     */
    window.fbAsyncInit = () => {
      window.FB.init({ appId, version, status });
      observer.next();
      observer.complete();
    };
  });
}).pipe(opShare());

const createAPIMethod = api => init.pipe(mergeMap(() => api));

// getLoginStatus would be called before login, so it cannot be async either
export const getLoginStatus = () =>
  Observable.create(subscriber => {
    window.FB.getLoginStatus(({ status: loginStatus, authResponse }) => {
      if (loginStatus === FACEBOOK_STATUS_CONNECTED) {
        const { userID, accessToken } = authResponse;
        subscriber.next({ userID, accessToken });
      } else {
        subscriber.next({});
      }
      subscriber.complete();
    });
  });

export const getUserName = () =>
  createAPIMethod(
    Observable.create(subscriber => {
      window.FB.api('/me', { locale: 'en_US', fields: 'name' }, ({ name }) => {
        subscriber.next(name);
        subscriber.complete();
      });
    })
  );

// login function don't need to and cannot be async or the popup won't open
export const login = () =>
  Observable.create(subscriber => {
    window.FB.login(({ authResponse }) => {
      if (!authResponse) {
        // user cancelled Facebook auth
        subscriber.error({ message: FACEBOOK_LOGIN_CANCELLED_ERROR });
      } else {
        const { userID, accessToken } = authResponse;
        subscriber.next({ userID, accessToken });
      }
      subscriber.complete();
    });
  });

/**
 * @name share
 * @description Facebook share
 * @see https://developers.facebook.com/docs/sharing/reference/share-dialog
 */
export const share = ({ href, labels } = {}) =>
  Observable.create(subscriber => {
    /**
     * Facebook hashtag rules
     * 1. need prefix #
     * 2. only show one tag, so use [0]
     */
    const hashtag =
      Array.isArray(labels) && labels.length > 0
        ? encodeURIComponent(labels.map(label => `#${label}`)[0])
        : '';

    if (!window.FB) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${href}&hashtag=${hashtag}`,
        'pop',
        'width=600, height=400, scrollbars=no'
      );
      subscriber.next();
      subscriber.complete();
      return;
    }

    window.FB.ui(
      {
        method: 'share',
        href,
        display: 'popup',
        hashtag,
      },
      result => {
        subscriber.next(result);
        subscriber.complete();
      }
    );
  });

export const getFacebookUserID = token =>
  createAPIMethod(
    Observable.create(subscriber => {
      window.FB.api(
        '/me',
        { locale: 'en_US', fields: 'id', access_token: token },
        ({ id }) => {
          subscriber.next({
            facebookID: id,
            facebookAccessToken: token,
          });
          subscriber.complete();
        }
      );
    })
  );

export default {
  init,
  getLoginStatus,
  getUserName,
  login,
  share,
  getFacebookUserID,
};
