import {
  INIT_SNACK_RANK_SYSTEM_INFO,
  SET_SNACK_RANK_SYSTEM_INFO,
  TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT,
} from '@17live/app/containers/SnackRankSystem/SnackRankSystem.constants';
import { SnackRankSystemInfo } from '@17live/app/containers/SnackRankSystem/SnackRankSystem.types';

export const setSnackRankSystemInfo = ({
  roomID,
  info: snackRankSystemInfo,
}: {
  roomID: string;
  info: Partial<SnackRankSystemInfo>;
}) => ({
  type: SET_SNACK_RANK_SYSTEM_INFO,
  payload: {
    roomID,
    snackRankSystemInfo,
  },
});

export const initSnackRankSystemInfo = ({ roomID }: { roomID: string }) => ({
  type: INIT_SNACK_RANK_SYSTEM_INFO,
  payload: {
    roomID,
  },
});

export const toggleSnackRankSystemFeatureOffAlert = (
  isOpen: boolean = false
) => ({
  type: TOGGLE_SNACK_RANK_SYSTEM_FEATURE_OFF_ALERT,
  payload: {
    isOpen,
  },
});
