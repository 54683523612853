export const GET_RED_ENVELOPE = 'app/RedEnvelope/GET_RED_ENVELOPE';
export const SET_RED_ENVELOPE = 'app/RedEnvelope/SET_RED_ENVELOPE';
export const SEND_RED_ENVELOPE = 'app/RedEnvelope/SEND_RED_ENVELOPE';
export const SET_RED_ENVELOPE_RESPONSE =
  'app/RedEnvelope/SET_RED_ENVELOPE_RESPONSE';

export const SET_SENT_RED_ENVELOPE_ERROR =
  'app/RedEnvelope/SET_SENT_RED_ENVELOPE_ERROR';

export const SEARCH_LIVE_STREAMERS = 'app/RedEnvelope/SEARCH_LIVE_STREAMERS';
export const SET_LIVE_STREAMERS = 'app/RedEnvelope/SET_LIVE_STREAMERS';

export const SET_RED_ENVELOPE_EVENT_INFO =
  'app/RedEnvelope/SET_RED_ENVELOPE_EVENT_INFO';
export const SET_RED_ENVELOPE_EVENT_END_MSG =
  'app/RedEnvelope/SET_RED_ENVELOPE_EVENT_END_MSG';

export const ON_RECEIVE_RED_ENVELOPE =
  'app/RedEnvelope/ON_RECEIVE_RED_ENVELOPE';
export const ON_RECEIVE_RED_ENVELOPE_EVENT_END_MSG =
  'app/RedEnvelope/ON_RECEIVE_RED_ENVELOPE_EVENT_END_MSG';

export const CLEAR_RED_ENVELOPE_EVENT =
  'app/RedEnvelope/CLEAR_RED_ENVELOPE_EVENT';

/**
 *  backend api document for red envelope types
 * https://docs.google.com/document/d/10YQPitKEGyiS19Sl-WngrnE7MaqS2wpBABndfHXn5wo/edit#
 */
export const NORMAL = 1;
export const CUSTOM = 2;
export const SYSTEM = 3;
export const RECOMMEND = 4;

export const RED_ENVELOPE_TYPES = {
  NORMAL,
  CUSTOM,
  SYSTEM,
  RECOMMEND,
};

export const RED_ENVELOPE_INFO_TYPES = {
  DUMMY: 0,
  NEW_CREATED: 1,
  PROCESSING: 2,
};
