/*
 *
 * Asynchronously loads the reducers and epics for GiftBox
 *
 */
import { errorLoading, getAsyncInjectors } from '@/utils/asyncInjectors';

export default (store) => {
  const { injectEpics } = getAsyncInjectors(store);
  const importModules = Promise.all([
    import(/* webpackMode: "eager" */ './epics'),
  ]);

  return importModules
    .then(([epics]) => {
      injectEpics(epics.default);
    })
    .catch(errorLoading);
};
