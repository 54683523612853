/*
 *
 * PubNubChatRoom actions
 *
 */
/* eslint-disable no-unused-vars */
import { PokeInfo } from '@/containers/Poke/types';

import { SubmitChatMode } from '@17live/app/containers/ChatRoom/components/SubmitChat.constants';
import {
  ArmyGift,
  ArmySubscription,
  Chat,
  EngagementReward,
  Gift,
  LaborReward,
  Reaction,
} from '@17live/app/containers/ChatRoom/types';
import { SUBSCRIBED_CHAT_ROOM } from '@17live/app/containers/LiveStream/constants';

import {
  APPEND_AI_COHOST_MESSAGE,
  APPEND_ARMY_GIFT,
  APPEND_ARMY_SUBSCRIPTION,
  APPEND_CHAT,
  APPEND_ENGAGEMENT_REWARD,
  APPEND_GIFT,
  APPEND_LABOR_REWARD,
  APPEND_LUCKYBAG,
  APPEND_POKE,
  APPEND_REACTION,
  APPEND_SYSTEM_COMMENT,
  ASSIGN_ASSISTANT,
  GET_BARRAGE_INFO,
  POST_BARRAGE,
  POST_CHAT,
  PUSH_BANNER_MESSAGE,
  RECEIVE_MESSAGE,
  RECEIVE_POKE,
  SET_BARRAGE_MODE,
  SHIFT_BANNER_MESSAGE,
  SHOW_CHECKING_MESSAGE,
  UPDATE_CHAT,
  UPDATE_SUBMIT_CHAT_FOCUS,
  UPDATE_SUBMIT_CHAT_MODE,
} from './constants';

export const subscribedChatroom = (roomID: string) => ({
  type: SUBSCRIBED_CHAT_ROOM,
  roomID,
});

export const receiveMessage = (
  roomID: string,
  message: { [key: string]: any }
) => ({
  type: RECEIVE_MESSAGE,
  payload: {
    roomID,
    message,
  },
});

export const receivePoke = (roomID: string, pokeInfo: PokeInfo) => ({
  type: RECEIVE_POKE,
  payload: {
    roomID,
    pokeInfo,
  },
});

export const appendChat = (payload: Chat) => ({
  type: APPEND_CHAT,
  payload,
});

export const appendAICoHostMessage = (payload: Chat) => ({
  type: APPEND_AI_COHOST_MESSAGE,
  payload,
});

export const assignAssistant = (payload: Chat) => ({
  type: ASSIGN_ASSISTANT,
  payload,
});

export const appendReaction = (payload: Reaction) => ({
  type: APPEND_REACTION,
  payload,
});

export const appendGift = (payload: Gift) => ({
  type: APPEND_GIFT,
  payload,
});

export const appendLaborReward = (payload: LaborReward) => ({
  type: APPEND_LABOR_REWARD,
  payload,
});

export const appendLuckyBag = (payload: Gift) => ({
  type: APPEND_LUCKYBAG,
  payload,
});

export const appendPoke = (payload: PokeInfo) => ({
  type: APPEND_POKE,
  payload,
});

export const appendArmyGift = (payload: ArmyGift) => ({
  type: APPEND_ARMY_GIFT,
  payload,
});

export const appendArmySubscription = (payload: ArmySubscription) => ({
  type: APPEND_ARMY_SUBSCRIPTION,
  payload,
});

/**
 * @description This is pure action to show chatroom system comment
 * @param {*} roomID
 * @param {*} content
 * @returns
 */
export const appendSystemComment = (
  roomID: string,
  content: { [key: string]: any }
) => ({
  type: APPEND_SYSTEM_COMMENT,
  payload: {
    roomID,
    content,
  },
});

export const appendEngagementReward = (payload: EngagementReward) => ({
  type: APPEND_ENGAGEMENT_REWARD,
  payload,
});

export const postChat = (roomID: string, content: string, traceID: string) => ({
  type: POST_CHAT,
  payload: {
    roomID,
    content,
    traceID,
  },
});

export const setBarrageMode = (roomID: string, isBarrage: boolean) => ({
  type: SET_BARRAGE_MODE,
  payload: { isBarrage, roomID },
});

export const postBarrage = (
  roomID: string,
  { content, type, marqueeType, traceID }
) => ({
  type: POST_BARRAGE,
  payload: {
    roomID,
    content,
    type,
    marqueeType,
    traceID,
  },
});

export const getBarrageInfo = (roomID: string) => ({
  type: GET_BARRAGE_INFO,
  payload: {
    roomID,
  },
});

export const updateChat = (roomID: string, content: string) => ({
  type: UPDATE_CHAT,
  payload: {
    roomID,
    content,
  },
});

export const pushBannerMessage = (
  roomID: string,
  message: { [key: string]: string }
) => ({
  type: PUSH_BANNER_MESSAGE,
  payload: {
    roomID,
    message,
  },
});

export const shiftBannerMessage = (roomID: string) => ({
  type: SHIFT_BANNER_MESSAGE,
  payload: roomID,
});

export const showCheckingMessage = (roomID: string, checkingLevel: number) => ({
  type: SHOW_CHECKING_MESSAGE,
  payload: {
    roomID,
    checkingLevel,
  },
});

export const updateSubmitChatFocusState = (
  roomID: string,
  isFocus: boolean
) => ({
  type: UPDATE_SUBMIT_CHAT_FOCUS,
  payload: {
    roomID,
    isFocus,
  },
});

export const updateSubmitChatMode = (
  roomID: string,
  submitChatMode: SubmitChatMode
) => ({
  type: UPDATE_SUBMIT_CHAT_MODE,
  payload: {
    roomID,
    submitChatMode,
  },
});
