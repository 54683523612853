import { APIError } from '@17live/app/types/Error';
import { PollConfig, PollFields, PollInfo } from '@17live/app/types/Poll';

import {
  CREATE_POLL,
  DELETE_POLL,
  END_POLL,
  GET_POLL_CONFIG,
  INIT_POLL,
  SET_IS_POLL_LOADING,
  SET_POLL_CONFIG,
  SET_POLL_ERROR,
  SET_POLL_INFO,
  SET_POLL_OPTION_COUNT,
  UPDATE_PAID_POLL,
  UPDATE_POLL,
  UPDATE_POLL_INFO,
} from './constants';

export const initPoll = () => ({
  type: INIT_POLL,
});

export const getPollConfig = () => ({
  type: GET_POLL_CONFIG,
});

export const setPollConfig = (pollConfig: PollConfig) => ({
  type: SET_POLL_CONFIG,
  payload: pollConfig,
});

export const createPoll = (fields: PollFields) => ({
  type: CREATE_POLL,
  payload: fields,
});

export const setPollInfo = (pollInfo: PollInfo) => ({
  type: SET_POLL_INFO,
  payload: pollInfo,
});

export const endPoll = (pollID: string) => ({
  type: END_POLL,
  payload: pollID,
});

export const deletePoll = (pollID: string) => ({
  type: DELETE_POLL,
  payload: { pollID },
});

export const setPollError = (
  roomID: string,
  giftID: string,
  error: APIError
) => ({
  type: SET_POLL_ERROR,
  payload: {
    roomID,
    giftID,
    error,
  },
});

export const setPollOptionCount = pollOption => ({
  type: SET_POLL_OPTION_COUNT,
  payload: { pollOption },
});

export const updatePoll = (pollID, fields) => ({
  type: UPDATE_POLL,
  payload: { pollID, fields },
});

export const updatePaidPoll = (roomID, giftID, traceID) => ({
  type: UPDATE_PAID_POLL,
  payload: { roomID, giftID, traceID },
});

export const updatePollInfo = pollInfo => ({
  type: UPDATE_POLL_INFO,
  payload: pollInfo,
});

export const setIsPollLoading = (isLoading: boolean) => ({
  type: SET_IS_POLL_LOADING,
  payload: isLoading,
});
