// @flow
import React from 'react';
import type { IntlShape, MessageDescriptor } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';

import styled from 'styled-components';

import { termsRegionMapping } from '@/components/Terms/constants';

import { REGION } from '@17live/app/constants/langs';
import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';

import messages from './messages';

type Props = {
  message?: MessageDescriptor,
};

type PropsWithIntl = Props & {
  intl: IntlShape,
};

type FAQAnchorProps = Props & {
  link: string,
};

const Anchor = styled.a.attrs(() => ({ target: '_blank' }))`
  color: ${props => props.theme.BD_BLACK};
  font-size: 14px;
  line-height: 1;
  margin-bottom: 20px;
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const TermsAnchor = injectIntl(
  ({ intl, message, ...props }: PropsWithIntl) => {
    const { region } = useLocale();

    const termsLink = termsRegionMapping[props.region || region];

    return (
      <Anchor href={termsLink} {...props}>
        <FormattedMessage {...(message || messages.termsLabel)} />
      </Anchor>
    );
  }
);

export const AgreementsAnchor = injectIntl(
  ({ intl, message, ...props }: PropsWithIntl) => {
    const { region } = useLocale();
    const agreementsLink =
      region === REGION.JP
        ? messages.agreementsLinkJapan
        : messages.agreementsLink;
    return (
      <Anchor href={intl.formatMessage(agreementsLink)} {...props}>
        <FormattedMessage {...(message || messages.agreementsLabel)} />
      </Anchor>
    );
  }
);

export const InvoiceAnchor = ({ message, ...props }: Props) => (
  <Anchor
    href="https://17.media/policies/terms?lang=TW#invoice-policies"
    {...props}
  >
    <FormattedMessage {...(message || messages.invoiceLinkMessage)} />
  </Anchor>
);

export const BBFuAnchor = ({ message, ...props }: Props) => (
  <Anchor href="https://bbrich.com/policy" {...props}>
    <FormattedMessage {...(message || messages.termsLabel)} />
  </Anchor>
);

export const AboutAnchor = injectIntl(
  ({ intl, message, ...props }: PropsWithIntl) => (
    <Anchor href={intl.formatMessage(messages.aboutLink)} {...props}>
      <FormattedMessage {...(message || messages.aboutLabel)} />
    </Anchor>
  )
);

export const FAQAnchor = ({ link, message, ...props }: FAQAnchorProps) => (
  <Anchor href={link} {...props}>
    <FormattedMessage {...(message || messages.FAQLabel)} />
  </Anchor>
);

export default Anchor;
