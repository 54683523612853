import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';
import {
  getSupportedVariantLanguagesFromAppClientCaseInsensitive,
  variantLanguagesFromAppClientMap,
} from '@17live/app/utils';

const QS_LANG = 'lang';
export const useRedirectLangURL = (url = location.href) => {
  const {
    lang: defaultLang,
    getSupportedLangCaseInsensitive,
    changeLang,
  } = useLocale();

  let redirectURL;

  const fromURL = new URL(url);

  const [, firstPath] = fromURL.pathname.split('/');

  const webSupportedLang = getSupportedLangCaseInsensitive(firstPath);
  const variantLang = getSupportedVariantLanguagesFromAppClientCaseInsensitive(
    firstPath
  );

  const qsLang = fromURL.searchParams.get(QS_LANG);
  fromURL.searchParams.delete(QS_LANG);
  const fullPath = `${fromURL.pathname}${fromURL.search}${fromURL.hash}`;

  if (variantLang) {
    // 如果是 app 來的變種語系要轉成 web supported 的
    const variantLangLowerCase = variantLang.toLowerCase() as 'zh-tw';

    const splitLangs = fullPath.split('/');

    splitLangs[1] = variantLanguagesFromAppClientMap[variantLangLowerCase];
    redirectURL = splitLangs.join('/');
  } else if (!webSupportedLang) {
    // 如果不是 app 來的變動語系，也不是 web 支援的，就是舊路徑，就加上 web 語系，並參考 qs lang
    const matchedQueryStringLang = getSupportedLangCaseInsensitive(
      String(qsLang)
    );

    if (matchedQueryStringLang) {
      redirectURL = `/${matchedQueryStringLang}${fullPath}`;
    } else {
      redirectURL = `/${defaultLang}${fullPath}`;
    }
  }

  if (redirectURL === fullPath) {
    // don't redirect
    redirectURL = null;
  } else if (redirectURL) {
    const [, urlLang] = redirectURL.split('/');

    // save back to redux store
    changeLang(urlLang);
  }

  if (redirectURL?.endsWith('/')) redirectURL = redirectURL.slice(0, -1);

  return redirectURL;
};
