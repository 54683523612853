import { createSelector } from 'reselect';

import {
  selectLiveStream,
  selectLiveStreamList,
} from '@17live/app/containers/LiveStream/selectors';

import { MAX_HISTORY_LIVE_STREAMS } from './constants';

/**
 * Direct selector to the preference state domain
 */
export const selectPreferenceDomain = state => state.get('preference');

export const selectVolume = domain => domain.get('volume');

export const selectHistoryLiveStreams = domain =>
  domain.get('historyLiveStreams');

export const makeHistoryLiveStreams = () =>
  createSelector(selectPreferenceDomain, selectHistoryLiveStreams);

const historyLiveStreamsSelector = makeHistoryLiveStreams();

export const selectHistoryLiveStreamsList = (
  liveStreamDomain,
  historyLiveStreams
) => {
  const historyList = historyLiveStreams
    .toList()
    .sortBy(room => -room.get('timestamp'))
    .take(MAX_HISTORY_LIVE_STREAMS);

  const liveStreamList = selectLiveStreamList(
    liveStreamDomain,
    historyList.map(stream => stream.get('roomID'))
  );

  return historyList.map((stream, key) =>
    stream.set('isOnline', liveStreamList.getIn([key, 'isOnline']))
  );
};

export const selectHasReadAchievementHint = domain =>
  !!domain.get('hasReadAchievementHint');

export const selectHasEnhanceDownload = domain =>
  !!domain.get('hasEnhanceDownload');

/**
 * Other specific selectors
 */

/**
 * Default selector used by Preference
 */

export const makeSelectPreference = () =>
  createSelector(selectPreferenceDomain, preference => preference);

export const makeSelectVolume = () =>
  createSelector(selectPreferenceDomain, selectVolume);

export const makeSelectCarouselVolume = () =>
  createSelector(selectPreferenceDomain, domain =>
    domain.get('carouselVolume')
  );

export const makeSelectClipVolume = () =>
  createSelector(selectPreferenceDomain, domain => domain.get('clipVolume'));

export const makeSelectClipFeedVolume = () =>
  createSelector(selectPreferenceDomain, domain =>
    domain.get('clipFeedVolume')
  );

export const makeSelectHistoryLiveStreamsList = () =>
  createSelector(
    selectLiveStream,
    historyLiveStreamsSelector,
    selectHistoryLiveStreamsList
  );

export const makeSelectHasReadAchievementHint = () =>
  createSelector(selectPreferenceDomain, selectHasReadAchievementHint);

export const makeSelectHasEnhanceDownload = () =>
  createSelector(selectPreferenceDomain, selectHasEnhanceDownload);
