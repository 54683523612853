export enum RegionMode {
  DUMMY = 0,
  ALL = 1,
  INCLUDE = 2,
  EXCLUDE = 3,
}

export enum RegionCode {
  DUMMY = 'dummy',
  ALL = 'all',
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

export const regionCodeMap: { [key: string]: number } = {
  [RegionCode.DUMMY]: RegionMode.DUMMY,
  [RegionCode.ALL]: RegionMode.ALL,
  [RegionCode.INCLUDE]: RegionMode.INCLUDE,
  [RegionCode.EXCLUDE]: RegionMode.EXCLUDE,
};

export const isRegionAllowedWithRegionMode = (
  region: string | null | undefined,
  regionCode: number | string,
  regions: (string | null | undefined)[]
): boolean => {
  let regionMode = -1;

  if (typeof regionCode === 'string') {
    regionMode = regionCodeMap[regionCode];
  }

  if (typeof regionCode === 'number') {
    regionMode = regionCode;
  }

  switch (regionMode) {
    case RegionMode.INCLUDE:
      return regions.includes(region);
    case RegionMode.EXCLUDE:
      return !regions.includes(region);
    case RegionMode.DUMMY:
      return false;
    case RegionMode.ALL:
    default:
      return true;
  }
};
