import historyLiveStreamEpics from '@/containers/HistoryLiveStreams/epics';
import thirdPartyLoginEpics from '@/containers/ThirdPartyAction/epics';
import userProviderEpics from '@/containers/UserProvider/epics';

import appEpics from '@17live/app/containers/App/epics';
import EventPageEpics from '@17live/app/containers/EventPage/epics';
import LanguageProviderEpics from '@17live/app/containers/LanguageProvider/epics';
import liveStreamEpics from '@17live/app/containers/LiveStream/epics';
import loginProviderEpics from '@17live/app/containers/LoginProvider/epics';
import PreferenceProviderEpics from '@17live/app/containers/PreferenceProvider/epics';
import SnackEpics from '@17live/app/containers/Snack/Snack.epics';
import SnackRankSystemEpics from '@17live/app/containers/SnackRankSystem/SnackRankSystem.epics';
import StatusAnnouncementEpics from '@17live/app/containers/StatusAnnouncement/StatusAnnouncement.epics';
import ViolationEpics from '@17live/app/containers/Violation/Violation.epics';

const initialEpics = [
  ...appEpics,
  ...loginProviderEpics,
  ...thirdPartyLoginEpics,
  ...userProviderEpics,
  ...historyLiveStreamEpics,
  ...liveStreamEpics,
  ...LanguageProviderEpics,
  ...StatusAnnouncementEpics,
  ...PreferenceProviderEpics,
  ...EventPageEpics,
  ...SnackEpics,
  ...SnackRankSystemEpics,
  ...ViolationEpics,
];

export default initialEpics;
