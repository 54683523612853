import { Map } from 'immutable-v4';
import { createSelector } from 'reselect';

import { makeSelectRoomID } from '@17live/app/containers/LiveStream/selectors';

export const selectSnackRankSystem = (state: any) => {
  return state.get('snackRankSystem');
};

export const makeSelectSnackRankSystemInfoByRoomID = () =>
  createSelector(
    selectSnackRankSystem,
    makeSelectRoomID(),
    (snackRankSystem, roomID) =>
      snackRankSystem
        .getIn(
          ['rooms', roomID],
          Map({
            featureEnabled: false,
            snackRankSettleRegion: '',
          })
        )
        .toJS()
  );

/**
 * 在某些情境會需要拿到 API Response 後才顯示，避免出現舊新功能短時間內變換的狀況
 * snackRankSystemInfo 會在 LEAVE_LIVE_STREAM 時清除，避免拿到舊的資料，詳細見 initSnackRankSystemInfoEpic
 */
export const makeSelectSnackRankSystemInfoIsFetched = () =>
  createSelector(selectSnackRankSystem, makeSelectRoomID(), (state, roomID) =>
    state.hasIn(['rooms', roomID])
  );

export const makeSelectSnackRankSystemFeatureOffAlert = () =>
  createSelector(selectSnackRankSystem, state =>
    state?.get('isFeatureAlertOpen')
  );
