/*
 *
 * ForgotPasswordModal constants
 *
 */
export const SET_STATUS = 'app/ForgotPasswordModal/SET_STATUS';
export const SEND_VERIFICATION_CODE =
  'app/ForgotPasswordModal/SEND_VERIFICATION_CODE';
export const GET_RESET_PASSWORD_TOKEN =
  'app/ForgotPasswordModal/GET_RESET_PASSWORD_TOKEN';
