/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import RobotSVG from '@/assets/ig_Alert_captcha.svg';
import SVG from '@/components/SVG';

import { PerimeterXCaptchaID } from '@17live/app/services/Perimeterx/constants';
import { PerimeterXCaptchaCallback } from '@17live/app/services/Perimeterx/types';
import { $t } from '@17live/app/utils';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  opacity: 1;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background: #ffffff;
  width: 320px;
  height: 300px;
  text-align: center;
  border-radius: 4px;
  padding: 10px 20px 0 20px;
`;

const Robot = styled(SVG).attrs({
  src: RobotSVG,
})`
  svg {
    width: 160px;
    height: 160px;
  }
`;

const Content = styled.div`
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding: 20px 0;
`;

type Props = {
  callback: PerimeterXCaptchaCallback | null;
  onClose: () => void;
};

const PerimeterXCaptchaModal: React.FC<Props> = ({ callback, onClose }) => {
  const [isFailed, setIsFailed] = useState(false);
  useEffect(() => {
    window._pxOnCaptchaSuccess = (isValid: boolean) => {
      if (callback) {
        callback(isValid);
        setIsFailed(true);
      }

      if (isValid) {
        onClose();
      }
    };

    return () => {
      window._pxOnCaptchaSuccess = null;
    };
  }, [callback, onClose]);

  return (
    <Overlay>
      <Modal>
        <Robot />
        <Content>
          {isFailed
            ? $t('perimeterx_modal_failed')
            : $t('perimeterx_modal_title')}
        </Content>
        <div id={PerimeterXCaptchaID} />
      </Modal>
    </Overlay>
  );
};

export default PerimeterXCaptchaModal;
