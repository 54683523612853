/*
 *
 * Preference actions
 *
 */
import {
  INIT_PREFERENCE,
  GET_PREFERENCE,
  SET_PREFERENCE,
  UPDATE_PREFERENCE,
} from './constants';

export const initPreference = () => ({
  type: INIT_PREFERENCE,
});

export const getPreference = () => ({
  type: GET_PREFERENCE,
});

export const setPreference = (key, value) => ({
  type: SET_PREFERENCE,
  payload: {
    key,
    value,
  },
});

export const updatePreference = preference => ({
  type: UPDATE_PREFERENCE,
  payload: preference,
});
