import styled from 'styled-components';
import { mobileCSS } from '@/utils/media';
import breakpoints from '@/constants/breakpoints';

const AppWrapper = styled.div`
  min-width: ${breakpoints.xs};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mobileCSS`
    min-width: 0;
  `}
`;

export default AppWrapper;
