// @flow
import React from 'react';

import ErrorBlock from '@/components/ErrorBlock/Loadable';
import LoadingSVG from '@/components/LoadingSVG';

export const LoadingPlaceholder = LoadingSVG;

type Props = {
  isLoading: boolean,
  error: Error,
  pastDelay: boolean,
};

const LoadingComponent = ({ isLoading, error, pastDelay }: Props) => {
  if (isLoading && pastDelay) {
    return <LoadingPlaceholder />;
  } else if (error) {
    return <ErrorBlock />;
  }

  // Don't flash "Loading..." when we don't need to.
  return null;
};

export default LoadingComponent;
