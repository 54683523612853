import { connect } from 'react-redux';
import { compose } from 'redux';

import globalModalLoader from '@/containers/GlobalModal/loader';
import modalLoader from '@/containers/Modal/loader';
import { authWith17 } from '@/containers/ThirdPartyAction/actions';
import withLoader from '@/utils/withLoader';

import RedirectFromState from '../components/RedirectFromState';

export const mapDispatchToProps = dispatch => ({
  authWith17: (oauthType, action, data) =>
    dispatch(authWith17(oauthType, action, data)),
});

export default compose(
  withLoader(modalLoader, globalModalLoader),
  connect(null, mapDispatchToProps)
)(RedirectFromState);
