import { KEY_PREFERENCES, SessionStorage } from '@/services/Storage';

export const DEFAULTS = {
  locale: '',
  returnPath: '/',
};

const preferences = {
  ...DEFAULTS,
  ...(SessionStorage.getItem(KEY_PREFERENCES) || {}),
};

const updateLocalStorage = () => {
  SessionStorage.setItem(KEY_PREFERENCES, preferences);
};

const Preference = {
  get(key) {
    return preferences[key];
  },

  set(key, value) {
    preferences[key] = value;
    updateLocalStorage();
  },
};

export default Preference;
