// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import getPicture from '@/utils/getPicture';

type Props = {
  picture?: string,
  twitterPicture?: string,
};

const Image = ({ picture, twitterPicture }: Props) => {
  if (!picture) {
    return null;
  }

  const image = getPicture(picture);
  const twitterImage = getPicture(twitterPicture);

  return (
    <ReactHelmet>
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:image" content={twitterImage || image} />
    </ReactHelmet>
  );
};

export default Image;
