import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getConfig, getPersonalConfig } from '../actions';

export const useConfig = () => {
  const dispatch = useDispatch();

  return {
    getConfig: useCallback(() => dispatch(getConfig()), [dispatch]),
    getPersonalConfig: useCallback(() => dispatch(getPersonalConfig()), [
      dispatch,
    ]),
  };
};
