import { SendRedEnvelopeParams } from '@17live/app/types/RedEnvelope';

import {
  CLEAR_RED_ENVELOPE_EVENT,
  GET_RED_ENVELOPE,
  ON_RECEIVE_RED_ENVELOPE,
  ON_RECEIVE_RED_ENVELOPE_EVENT_END_MSG,
  SEARCH_LIVE_STREAMERS,
  SEND_RED_ENVELOPE,
  SET_LIVE_STREAMERS,
  SET_RED_ENVELOPE,
  SET_RED_ENVELOPE_EVENT_END_MSG,
  SET_RED_ENVELOPE_EVENT_INFO,
  SET_RED_ENVELOPE_RESPONSE,
  SET_SENT_RED_ENVELOPE_ERROR,
} from './constants';
import { RedEnvelopeInfo, SentEnvelopeResponse } from './types';

export const getRedEnvelope = (roomID: string) => ({
  type: GET_RED_ENVELOPE,
  payload: { roomID },
});

export const setRedEnvelope = (redEnvelopeInfo: RedEnvelopeInfo) => ({
  type: SET_RED_ENVELOPE,
  payload: redEnvelopeInfo,
});

export const sendRedEnvelope = (
  sendRedEnvelopeParams: SendRedEnvelopeParams
) => ({
  type: SEND_RED_ENVELOPE,
  payload: sendRedEnvelopeParams,
});

export const setSentResponse = (res: SentEnvelopeResponse) => ({
  type: SET_RED_ENVELOPE_RESPONSE,
  payload: res,
});

export const setSentRedEnvelopeError = res => ({
  type: SET_SENT_RED_ENVELOPE_ERROR,
  payload: res,
});

export const searchLiveStreams = query => ({
  type: SEARCH_LIVE_STREAMERS,
  payload: { query },
});

export const setLiveStreams = list => ({
  type: SET_LIVE_STREAMERS,
  payload: { list },
});

export const onReceiveRedEnvelope = (roomID: string, redEnvelopeEventInfo) => ({
  type: ON_RECEIVE_RED_ENVELOPE,
  payload: {
    roomID,
    redEnvelopeEventInfo,
  },
});

export const onReceiveRedEnvelopeEventEndMsg = (
  roomID: string,
  redEnvelopeEventEndMsg: any
) => ({
  type: ON_RECEIVE_RED_ENVELOPE_EVENT_END_MSG,
  payload: {
    roomID,
    redEnvelopeEventEndMsg,
  },
});

export const setRedEnvelopeEventInfo = (roomID, redEnvelopeEventInfo) => ({
  type: SET_RED_ENVELOPE_EVENT_INFO,
  payload: {
    roomID,
    redEnvelopeEventInfo,
  },
});

export const setRedEnvelopeEventEndMsg = (roomID, redEnvelopeEventEndMsg) => ({
  type: SET_RED_ENVELOPE_EVENT_END_MSG,
  payload: {
    roomID,
    redEnvelopeEventEndMsg,
  },
});

export const clearRedEnvelopeEvent = (roomID: string) => ({
  type: CLEAR_RED_ENVELOPE_EVENT,
  payload: {
    roomID,
  },
});
