import { filter, skip, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SessionStorage, KEY_EVENTS } from '@/services/Storage';
import { OPEN_MODAL, MODAL_LOGIN, MODAL_REGISTER } from './constants';

export const setOpenLoginOrRegisterModalEventEpic = action$ =>
  action$.pipe(
    ofType(OPEN_MODAL),
    filter(
      ({ payload: { name } }) => name === MODAL_LOGIN || name === MODAL_REGISTER
    ),
    tap(() =>
      SessionStorage.updateItem(KEY_EVENTS, {
        hasOpenLoginOrRegisterModal: true,
      })
    ),
    // we intentionally don't hide the DriveRegisterBox here by request by both PM and Design,
    // ref: https://17media.slack.com/archives/C66Q87MT4/p1536827033000100?thread_ts=1536750615.000100&cid=C66Q87MT4
    skip()
  );

export default [setOpenLoginOrRegisterModalEventEpic];
