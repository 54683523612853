import { I18nKeys } from '@17live/app/utils/intl';

/**
 * @see https://github.com/17media/api/blob/master/models/vliver/vliver.go
 */
export enum VliverModel {
  NONE = 0,
  LIVE2D = 1, // vliver who use Live2D (for App)
  VRM = 2, // vliver who use VRM from VRoid Hub platform (for App)
  UNKNOWN = 3, // vliver who use unknown model (for web)
  EASY_CREATION_BY_IMAGE = 4, // vliver who use picture to transfer the model (for App)
  CREATE = 5,
}

export interface VliverTab {
  id: VliverModel;
  label: I18nKeys;
}

/**
 * @see https://17media.atlassian.net/wiki/spaces/TEAMZ/pages/997425686/126+vliver#Vliver-live2D-Upload
 */
export enum VliverValidationErrorBit {
  MODEL3_NOT_FOUND = 1,
  MODEL3_PARSE_FAILED = 2,
  FILE_REFERENCES_KEY_NOT_FOUND = 4,
  MOC_KEY_NOT_FOUND = 8,
  TEXTURE_KEY_NOT_FOUND = 16,
  MOC_URL_NOT_FOUND = 32,
  TEXTURE_URL_NOT_FOUND = 64,
  MOTION_URL_NOT_FOUND = 128,
  PHYSICS_URL_NOT_FOUND = 256,
  POSE_URL_NOT_FOUND = 512,
  USER_DATA_URL_NOT_FOUND = 1024,
  EXPRESSION_URL_NOT_FOUND = 2048,
  MULTIPLE_MODEL_FOUND = 4096,
}
