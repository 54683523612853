import React from 'react';

import styled from 'styled-components';

import { TermsAnchor } from '@/components/Terms';

import VLiveCharacterIntroductionFileInfoLive2D from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFileInfoLive2D';
import VLiveCharacterIntroductionFromApp from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromApp';
import VLiveCharacterSectionTitle from '@17live/app/components/VLiveCharacter/VLiveCharacterSectionTitle';
import { RegionType } from '@17live/app/constants';
import { VliverModel } from '@17live/app/enums/Vliver.enums';
import { $t, getGCSImage, getWebp2xURL } from '@17live/app/utils';
import {
  COLOR_INFO_DARK,
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
} from '@17live/design-system/color';
import Box from '@17live/ui/Box';
import IgModalVliverFileTutorial from '@17live/ui/Gui/IgModalVliverFileTutorial';

const Subtitle = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
  color: ${COLOR_PRIMARY_BLACK};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  white-space: pre-wrap;
`;

const List = styled.ul`
  padding-left: 16px;
  list-style: decimal;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  line-height: 140%;
`;

const QRCode = styled.img.attrs(() => ({
  src: getWebp2xURL(getGCSImage('ig-qr-code-2021.png')),
}))<{}>`
  display: block;
  width: 160px;
  height: 160px;
`;

const Terms = styled(TermsAnchor)`
  color: ${COLOR_INFO_DARK};
`;

const VLiveCharacterIntroduction = ({
  isMobile,
  isFromApp,
  region,
  characterSizeLimitMB,
  easyCreationImageSizeLimitMB,
  enabledModels,
}: {
  isMobile: boolean;
  isFromApp: boolean;
  region?: RegionType;
  characterSizeLimitMB?: number;
  easyCreationImageSizeLimitMB?: number;
  enabledModels: VliverModel[];
}) => {
  return (
    <>
      <Box mb={isMobile ? '24px' : '16px'}>
        <VLiveCharacterSectionTitle>
          {$t('vlive_intro_content_1')}
        </VLiveCharacterSectionTitle>
      </Box>

      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_content_2')}</Subtitle>
        <Text>{$t('vlive_intro_content_2_1')}</Text>
        {isMobile && <br />}
        <Text>{$t('vlive_intro_content_2_2')}</Text>
      </Box>

      <Box mb={'24px'}>
        {/* Mobile  */}
        {isFromApp && (
          <VLiveCharacterIntroductionFromApp
            characterSizeLimitMB={characterSizeLimitMB}
            easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
            enabledModels={enabledModels}
          />
        )}

        {/* Desktop */}
        {!isFromApp && (
          <>
            <Box mb={'24px'}>
              <Subtitle>{$t('vlive_intro_content_3')}</Subtitle>
              <Box mb={'8px'}>
                <QRCode />
              </Box>
              <Subtitle>{$t('vlive_web_intro_content_1')}</Subtitle>
              <Text>{$t('vlive_web_intro_content_1_1')}</Text>
            </Box>
            <Box mb={'24px'}>
              <Box mb={'8px'}>
                <IgModalVliverFileTutorial width={150} height={150} />
              </Box>
              <Subtitle>{$t('vlive_web_intro_content_2')}</Subtitle>
              <Text>{$t('vlive_web_intro_content_2_1')}</Text>
              <Text>{$t('vlive_web_intro_content_2_2')}</Text>
            </Box>
            <Box mb={'24px'}>
              <Subtitle>{$t('vlive_web_intro_content_3')}</Subtitle>
              <Text>{$t('vlive_web_intro_content_3_1')}</Text>
            </Box>
            <Box mb={'24px'}>
              <Subtitle>{$t('vlive_web_intro_content_4')}</Subtitle>
              <Text>{$t('vlive_web_intro_content_4_1')}</Text>
            </Box>
            <VLiveCharacterIntroductionFileInfoLive2D
              isMobile={isMobile}
              characterSizeLimitMB={characterSizeLimitMB}
            />
          </>
        )}
      </Box>

      <div>
        <Subtitle>{$t('vlive_intro_content_5')}</Subtitle>
        <List>
          <li>{$t('vlive_intro_content_5_1')}</li>
          <li>{$t('vlive_intro_content_5_2')}</li>
          <li>
            {$t('vlive_intro_content_5_3', {
              param_1: <Terms region={region} />,
            })}
          </li>
        </List>
      </div>
    </>
  );
};

export default VLiveCharacterIntroduction;
