/**
 * UUID v4 tester
 *
 * Since the API sometimes response back invalid UUID,
 * we loosen the rules and implement our own tester here.
 *
 * The difference between this and the general tester is that we ignore the variant checking.
 *
 * @flow
 */

const UUIDRegex: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const isUUID = (uuid: string): boolean => UUIDRegex.test(uuid);

export default isUUID;
