import React from 'react';

import VLiveCharacterImage from '@17live/app/components/VLiveCharacter/VLiveCharacterImage';
import VLiveCharacterSubtitle from '@17live/app/components/VLiveCharacter/VLiveCharacterSubtitle';
import VLiveCharacterText from '@17live/app/components/VLiveCharacter/VLiveCharacterText';
import { $t, getGCSImage, getWebp2xURL } from '@17live/app/utils';
import Box from '@17live/ui/Box';

const VLiveCharacterIntroductionFromAppCreate = ({
  hasTab,
}: {
  hasTab: boolean;
}) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_web_intro_des_1')}
        </VLiveCharacterSubtitle>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={160}
            height={160}
            src={getWebp2xURL(getGCSImage('Vliver_FAQ_concept.png'))}
          />
        </Box>
        <VLiveCharacterText>{$t('vlive_web_intro_des_1_1')}</VLiveCharacterText>
      </Box>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_web_intro_des_2')}
        </VLiveCharacterSubtitle>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={311}
            src={getWebp2xURL(getGCSImage('Vliver_FAQ_detection.png'))}
          />
        </Box>
        <VLiveCharacterText>{$t('vlive_web_intro_des_2_1')}</VLiveCharacterText>
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppCreate;
