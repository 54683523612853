import React, { useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AuthRoute from '@/containers/AuthRoute';

import ErrorBoundary from '@17live/app/components/ErrorBoundary';
import {
  ContractApplication,
  ErrorRoutePath,
} from '@17live/app/constants/routePath';
import ContractApplicationContainerLoadable from '@17live/app/containers/ContractApplication/ContractApplicationContainerLoadable';
import ContractApplicationStatusContainerLoadable from '@17live/app/containers/ContractApplication/ContractApplicationStatusContainerLoadable';
import ContractApplicationStep1ContainerLoadable from '@17live/app/containers/ContractApplication/ContractApplicationStep1ContainerLoadable';
import ContractApplicationStep2ContainerLoadable from '@17live/app/containers/ContractApplication/ContractApplicationStep2ContainerLoadable';
import { useContractApplicationStore } from '@17live/app/containers/ContractApplication/stores/useContractApplicationStore';

const ContractApplicationRoute = ({ match }: RouteComponentProps) => {
  const search = new URLSearchParams(location.search);
  // APP don't need AuthRoute, use query string accessToken
  const RenderRoute = search.get('accessToken') ? Route : AuthRoute;

  const { setStepStatusInfo, setApplicantInfo } = useContractApplicationStore(
    state => state
  );

  useEffect(() => {
    return () => {
      setStepStatusInfo(null);
      setApplicantInfo(null);
    };
  }, [setApplicantInfo, setStepStatusInfo]);

  return (
    <Switch>
      <RenderRoute
        exact
        path={`${match.url}`}
        render={props => (
          <ErrorBoundary>
            <ContractApplicationContainerLoadable
              {...props}
              parentPath={match.url}
            />
          </ErrorBoundary>
        )}
      />
      <RenderRoute
        exact
        path={`${match.url}${ContractApplication.STEP1}`}
        render={props => (
          <ErrorBoundary>
            <ContractApplicationStep1ContainerLoadable
              {...props}
              parentPath={match.url}
            />
          </ErrorBoundary>
        )}
      />
      <RenderRoute
        exact
        path={`${match.url}${ContractApplication.STEP2}`}
        render={props => (
          <ErrorBoundary>
            <ContractApplicationStep2ContainerLoadable
              {...props}
              parentPath={match.url}
            />
          </ErrorBoundary>
        )}
      />
      <RenderRoute
        exact
        path={`${match.url}${ContractApplication.STATUS}`}
        render={props => (
          <ErrorBoundary>
            <ContractApplicationStatusContainerLoadable
              {...props}
              parentPath={match.url}
            />
          </ErrorBoundary>
        )}
      />
      <Redirect to={ErrorRoutePath.NOT_FOUND} />
    </Switch>
  );
};

export default ContractApplicationRoute;
