import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import shortid from 'shortid';

import { ActionType, DriverContext } from './Context';
import { Props as DriverProps } from './Driver';

export const useElementWithRandomID = <T extends Element>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.id = shortid.generate();
    }
  }, []);

  return ref;
};

export type DriverHookOptions = Partial<
  Pick<DriverProps, 'popover' | 'noMask' | 'noCloseOnOutside'>
>;

export const useDriver = <HighlightedElement extends Element>({
  noMask: noMaskDefault,
  popover: popoverDefault,
  noCloseOnOutside: noCloseOnOutsideDefault,
}: DriverHookOptions = {}) => {
  const dispatch = useContext(DriverContext);
  const ref = useElementWithRandomID<HighlightedElement>();

  const turnOn = useCallback(
    (
      children: React.ReactNode,
      {
        noMask,
        popover,
        noCloseOnOutside,
        highlightElement,
      }: DriverHookOptions & Partial<{ highlightElement: string }> = {}
    ) => {
      dispatch({
        type: ActionType.TURN_ON,
        payload: {
          isTurnOn: true,
          highlightElement: highlightElement ?? ref.current,
          children,
          popover: popover ?? popoverDefault,
          noMask: noMask ?? noMaskDefault,
          noCloseOnOutside: noCloseOnOutside ?? noCloseOnOutsideDefault,
        },
      });
    },
    [dispatch, ref, noMaskDefault, popoverDefault, noCloseOnOutsideDefault]
  );

  const off = useCallback(() => {
    dispatch({
      type: ActionType.TURN_OFF,
      payload: {
        isTurnOn: false,
      },
    });
  }, [dispatch]);

  const driver = useMemo(
    () => ({
      ref,
      turnOn,
      off,
    }),
    [ref, turnOn, off]
  );

  return driver;
};

export type UseDriverReturnType = ReturnType<typeof useDriver>;
