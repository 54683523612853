import { Observable } from 'rxjs';

import apis from '@/services/17App/shared/apis';

import { SentEnvelopeResponse } from '@17live/app/containers/RedEnvelope/types';
import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { SendRedEnvelopeParams } from '@17live/app/types/RedEnvelope';

const API_PATH = 'redenvelopes';

export const getRedEnvelopes = (roomID: string, srcType = 1) =>
  apis.getJSON(`${API_PATH}?srcID=${roomID}&srcType=${srcType}`);

export const sendRedEnvelope = ({
  roomID,
  srcType = 1,
  redEnvelopeID,
  announce,
  point,
  name,
  recommendStreamerUserID,
  traceID,
}: SendRedEnvelopeParams): Observable<SentEnvelopeResponse> => {
  return apis.postJSON(
    `${API_PATH}`,
    {
      srcType,
      srcID: `${roomID}`,
      redEnvelopeID,
      announce,
      point,
      name,
      recommendStreamerUserID,
    },
    setTrackAPIHeaders({ 'trace-id': traceID }),
    {
      retries: 0, // do not retry
    }
  );
};
