import { stringify } from 'query-string';

import apis from '@/services/17App/shared/apis';

import { CreateProgramsParams } from '@17live/app/types/ProgramInfo';
import { getRegion } from '@17live/app/utils';

const API_PATH = 'programs';

export const getProgramList = (region: string = getRegion()) =>
  apis.getJSON(`${API_PATH}?${stringify({ region })}`);

export const getPrograms = (programID: string) =>
  apis.getJSON(`${API_PATH}/${programID}`);

export const getProgramsInfo = (count = 1) =>
  apis.getJSON(`${API_PATH}?${stringify({ count })}`);

export const createPrograms = (fields: CreateProgramsParams) =>
  apis.postJSON(`${API_PATH}`, fields);

export const editPrograms = (fields: CreateProgramsParams, programID: string) =>
  apis.putJSON(`${API_PATH}/${programID}`, fields);

export const deletePrograms = (programID: string) =>
  apis.deleteJSON(`${API_PATH}/${programID}`);
