import { Epic, ofType } from 'redux-observable';

import { stringify } from 'query-string';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { trackPageView } from '@/services/Analytics';

import { api } from '@17live/app/utils/api';

import { FETCH_EVENTS, fetchEventSuccess } from './actions';
import { API_PATH } from './const';
import { EventList } from './types';

type Event = {
  events: {
    inProgress: EventList[];
  };
};

export default [
  action$ =>
    action$.pipe(
      ofType(FETCH_EVENTS),
      tap(({ payload: region }) => trackPageView(`/event/${region}`)),
      switchMap(({ payload: region }) => {
        return (
          api
            /**
             * Status_ALL         Status = 0
             * Status_IN_PROGRESS Status = 1
             * Status_HAS_ENDED   Status = 2
             */
            .getJSON<Event>(
              `${API_PATH}?${stringify({
                region,
                status: 1,
              })}`
            )
            .pipe(
              catchError(() => []),
              map(res => fetchEventSuccess(res.events.inProgress))
            )
        );
      })
    ),
] as Epic[];
