import React from 'react';

import VLiveCharacterImage from '@17live/app/components/VLiveCharacter/VLiveCharacterImage';
import VLiveCharacterIntroductionFileInfoEasyCreationByImage from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFileInfoEasyCreationByImage';
import VLiveCharacterSubtitle from '@17live/app/components/VLiveCharacter/VLiveCharacterSubtitle';
import VLiveCharacterText from '@17live/app/components/VLiveCharacter/VLiveCharacterText';
import { $t, getGCSImage, getWebp2xURL } from '@17live/app/utils';
import Box from '@17live/ui/Box';
import IgVliverEasyCreationByImageTutorial from '@17live/ui/Gui/IgVliverEasyCreationByImageTutorial';

type VLiveCharacterIntroductionFromAppEasyCreationByImageProps = {
  easyCreationImageSizeLimitMB?: number;
  hasTab: boolean;
};

const VLiveCharacterIntroductionFromAppEasyCreationByImage = ({
  easyCreationImageSizeLimitMB,
  hasTab,
}: VLiveCharacterIntroductionFromAppEasyCreationByImageProps) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <VLiveCharacterSubtitle>
        {$t('vlive_intro_pic_title')}
      </VLiveCharacterSubtitle>
      <Box mb={'8px'}>
        <IgVliverEasyCreationByImageTutorial width={160} height={160} />
      </Box>
      <Box mb={'24px'}>
        <Box mb={'24px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_1')}
          </VLiveCharacterText>
        </Box>
        <VLiveCharacterIntroductionFileInfoEasyCreationByImage
          isMobile
          easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
        />
      </Box>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_pic_content_3')}
        </VLiveCharacterSubtitle>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={311}
            src={getWebp2xURL(getGCSImage('Vliver_FAQ_picV_format.png'))}
          />
        </Box>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_1')}
        </VLiveCharacterText>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_2')}
        </VLiveCharacterText>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_3')}
        </VLiveCharacterText>
      </Box>
      <VLiveCharacterSubtitle>
        {$t('vlive_intro_pic_content_3_4')}
      </VLiveCharacterSubtitle>
      <Box mb={'24px'}>
        <VLiveCharacterImage
          width={311}
          height={311}
          src={getWebp2xURL(getGCSImage('ig_Vliver_FAQ_picV_OK.png'))}
        />
      </Box>
      <VLiveCharacterSubtitle>
        {$t('vlive_intro_pic_content_3_5')}
      </VLiveCharacterSubtitle>
      <Box mb={'24px'}>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={311}
            src={getWebp2xURL(getGCSImage('ig_Vliver_FAQ_picV_NG.png'))}
          />
        </Box>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_5_1')}
        </VLiveCharacterText>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_5_2')}
        </VLiveCharacterText>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_5_3')}
        </VLiveCharacterText>
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_5_4')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_pic_content_3_5_5')}
        </VLiveCharacterText>
      </Box>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_pic_content_4')}
        </VLiveCharacterSubtitle>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips1_Deco.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_4_1')}
          </VLiveCharacterText>
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips2_hair.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_4_2')}
          </VLiveCharacterText>
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips3_eye.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_4_3')}
          </VLiveCharacterText>
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips4_face.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_4_4')}
          </VLiveCharacterText>
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterImage
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips5_mouth.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_pic_content_4_5')}
          </VLiveCharacterText>
        </Box>
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppEasyCreationByImage;
