import Loadable from '@/components/Loadable';
import Loading from '@/components/Loading';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExternalLive" */ '@17live/app/containers/ExternalLive'
    ),
  loading: Loading,
});
