import { stringify } from 'query-string';
import apis from '@/services/17App/shared/apis';

const API_PATH = 'post';

type Options = {
  cursor?: string;
  count: number;
  sharedPost?: number;
};

export const getPosts = (
  userID: string,
  { cursor = '', count = 12, sharedPost = 1 }: Options = {}
) =>
  apis.getJSON(
    `${API_PATH}/${userID}?${stringify({ cursor, count, sharedPost })}`
  );

export const getPostInfo = (postID: string) =>
  apis.legacy('getPostInfo', {
    postID,
  });
