import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import { $t } from '@17live/app/utils';
import {
  COLOR_NEUTRAL_100,
  COLOR_NEUTRAL_600,
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from '@17live/design-system/color';
import { Flex } from '@17live/ui/Box';

const FileInfo = styled.div<{ isMobile: boolean }>`
  border-radius: ${({ isMobile }) => (isMobile ? '12px' : '4px')};
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  margin-bottom: 12px;
`;

const FileInfoTitle = styled.div`
  background: ${COLOR_PRIMARY_BLACK};
  color: ${COLOR_PRIMARY_WHITE};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  padding: 8px;
`;

const FileInfoHead = styled.div`
  background: ${COLOR_NEUTRAL_600};
  color: ${COLOR_PRIMARY_WHITE};
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  padding: 4px;
`;

const FileInfoBody = styled.div`
  background: ${COLOR_NEUTRAL_100};
  color: ${COLOR_NEUTRAL_700};
  font-size: 12px;
  line-height: 133%;
  padding: 12px;
  text-align: center;
  align-items: center;
`;

export default function VLiveCharacterIntroductionFileInfoEasyCreationByImage({
  isMobile,
  easyCreationImageSizeLimitMB,
}: {
  isMobile: boolean;
  easyCreationImageSizeLimitMB?: number;
}) {
  return (
    <FileInfo isMobile={isMobile}>
      <FileInfoTitle>{$t('vlive_intro_pic_content_2')}</FileInfoTitle>
      <Flex flexDirection={isMobile ? 'column' : 'row'}>
        <Flex
          flexDirection={'column'}
          flexGrow={1}
          mr={isMobile ? '0' : '1px'}
          flexBasis={'33.33%'}
        >
          <FileInfoHead>{$t('vlive_intro_pic_content_2_1')}</FileInfoHead>
          <FileInfoBody>
            <FormattedMessage id="vlive_intro_pic_content_2_2">
              {text => (
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}
            </FormattedMessage>
          </FileInfoBody>
        </Flex>
        <Flex
          flexDirection={'column'}
          flexGrow={1}
          mr={isMobile ? '0' : '1px'}
          flexBasis={'33.33%'}
        >
          <FileInfoHead>{$t('vlive_intro_pic_content_2_3')}</FileInfoHead>
          <FileInfoBody>
            {$t('vlive_intro_pic_content_2_4', {
              param_1: easyCreationImageSizeLimitMB,
            })}
          </FileInfoBody>
        </Flex>
        <Flex
          flexDirection={'column'}
          flexGrow={1}
          mr={isMobile ? '0' : '1px'}
          flexBasis={'33.33%'}
        >
          <FileInfoHead>{$t('vlive_intro_pic_content_2_5')}</FileInfoHead>
          <FileInfoBody>
            <FormattedMessage id="vlive_intro_pic_content_2_6">
              {text => (
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}
            </FormattedMessage>
          </FileInfoBody>
        </Flex>
        <Flex flexDirection={'column'} flexGrow={1} flexBasis={'33.33%'}>
          <FileInfoHead>{$t('vlive_intro_pic_content_2_7')}</FileInfoHead>
          <FileInfoBody>
            <FormattedMessage id="vlive_intro_pic_content_2_8">
              {text => (
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}
            </FormattedMessage>
          </FileInfoBody>
        </Flex>
      </Flex>
    </FileInfo>
  );
}
