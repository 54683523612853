import { createGlobalStyle, css } from 'styled-components';

import { isHandheld } from '@/services/Device';
import bowser from '@/utils/bowser';

import { LANG } from '@17live/app/constants';

import { BG_WHITE } from './constants/colors';

const customScrollbar = css`
  /* Turn on custom 8x wide scrollbar */
  ::-webkit-scrollbar {
    position: fixed;
    width: 8px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100px;
  }
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  ::-webkit-scrollbar-thumb:vertical {
    background: rgba(0, 0, 0, 0.45);
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:vertical:hover {
    width: 8px;
    background: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61);
  }
`;

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  html {
    font-family: 'Helvetica Neue', Helvetica, 'Apple Color Emoji', Arial, 'PingFang TC', 'Heiti TC', 'Microsoft Jhenghei', sans-serif;
    font-display: swap;

    &[lang|=${LANG.JA}] {
      font-family: 'Hiragino Kaku Gothic Pro', Osaka, Meiryo, 'MS PGothic', Arial, sans-serif;
    }
  }

  body {
    font-size: 14px;
    /* smooth scrolling in ios */
    -webkit-overflow-scrolling: touch;
  }

  #app {
    background-color: ${BG_WHITE};
    height: 100%;
    display: flex;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }

  p,
  label {
    font-size: 14px;
    line-height: 1.5em;
  }

  a {
    text-decoration: none;
    color: inherit;
    touch-action: manipulation;
  }

  video::-webkit-media-controls-timeline {
    visibility: hidden;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
    touch-action: manipulation;
  }

  ${!bowser.mac && !isHandheld() ? customScrollbar : ''}
`;

export default GlobalStyle;
