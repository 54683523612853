import styled from 'styled-components';

const AIAssistantWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  white-space: pre-wrap;
`;

export default AIAssistantWrapper;
