import { LocalStorage } from '@/services/Storage';
import { KEY_LOGIN_INFO } from '@/services/Storage/constants';

import { BRAZE_MAIN_REGION } from '@17live/app/services/Braze/constants';
import { getPreference } from '@17live/app/utils/preferences';

export const isUserInMainRegionForBraze = () => {
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO);
  const registerRegion = loginInfo?.registerRegion || '';

  // In Braze situation, it's not suitable to use `getRegion` in
  // packages/app/utils/getLang.ts, because there is some prioritized logic of
  // region in `getRegion`, including `app region`, `web region`, and `IP region`.
  const webRegion = getPreference('region') || '';

  for (let i = 0; i < BRAZE_MAIN_REGION.length; i += 1) {
    const mainRegion = BRAZE_MAIN_REGION[i];
    if (mainRegion === webRegion || mainRegion === registerRegion) {
      return true;
    }
  }

  return false;
};
