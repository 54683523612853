import React from 'react';
import { createPortal } from 'react-dom';
import { getRootElement } from '@17live/app/utils';
type Props = {
  element?: Element | null;
};

const Portal: React.FC<Props> = ({ children, element = getRootElement() }) =>
  element ? createPortal(children, element) : <>{children}</>;

export default Portal;
