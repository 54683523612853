// @flow
// TODO: move to typescript
import type { ComponentType } from 'react';

import ForgotPasswordModalLoadable from '@/containers/ForgotPasswordModal/components/Loadable';
import LoginModalLoadable from '@/containers/LoginModal/components/Loadable';
import {
  MODAL_FORGOT_PASSWORD,
  MODAL_LOGIN,
  MODAL_PHONE_VERIFY,
  MODAL_REGISTER,
  MODAL_REGISTER_OAUTH,
  MODAL_RESET_PASSWORD,
} from '@/containers/Modal/constants';
import PhoneVerifyModalLoadable from '@/containers/PhoneVerifyModal/components/Loadable';
import RegisterModalLoadable from '@/containers/RegisterModal/components/Loadable';
import RegisterOAuthModalLoadable from '@/containers/RegisterOAuthModal/components/Loadable';
import ResetPasswordModalLoadable from '@/containers/ResetPasswordModal/components/Loadable';

import ConcertModalLoadable from '@17live/app/containers/Concert/ConcertModalLoadable';
import { CONCERT_MODAL_ID } from '@17live/app/containers/Modal/constants';

const modals: {
  [modalID: string]: {|
    +Component: ComponentType<*>,
    +isFullPage: boolean,
    +withoutAuth?: boolean,
  |},
} = Object.freeze({
  [MODAL_LOGIN]: {
    Component: LoginModalLoadable,
    isFullPage: true,
  },
  [MODAL_REGISTER]: {
    Component: RegisterModalLoadable,
    isFullPage: true,
  },
  [MODAL_REGISTER_OAUTH]: {
    Component: RegisterOAuthModalLoadable,
    isFullPage: true,
  },
  [MODAL_FORGOT_PASSWORD]: {
    Component: ForgotPasswordModalLoadable,
    isFullPage: true,
  },
  [MODAL_RESET_PASSWORD]: {
    Component: ResetPasswordModalLoadable,
    isFullPage: true,
  },
  [MODAL_PHONE_VERIFY]: {
    Component: PhoneVerifyModalLoadable,
    isFullPage: true,
  },
  [CONCERT_MODAL_ID]: {
    Component: ConcertModalLoadable,
    isFullPage: true,
    withoutAuth: true,
  },
});

export default modals;
