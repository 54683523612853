// @flow
import {
  identifyUser as identifyGAUser,
  loadGA,
  trackEvent,
} from '@/services/GoogleAnalytics';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  EVENT_LABELS,
} from '@/services/GoogleAnalytics/constants';
import ec from '@/services/GoogleAnalytics/ec';
import * as enhancers from '@/services/GoogleAnalytics/enhancers';
import pageView from '@/services/GoogleAnalytics/pageView';
import {
  identifyUser as identifyMatomoUser,
  loadMatomo,
  trackPageView,
} from '@/services/MatomoAnalytics';
import { identifySentryUser } from '@/services/Sentry';

import { identifyBrazeUser } from '@17live/app/services/Braze';

export const initAnalytics = () => {
  // GA
  loadGA()
    // when user block ga or ga failed to load
    .catch(err => console.error('GA load failed', err)); // eslint-disable-line no-console
  // Matomo
  loadMatomo()
    // when user block ga or ga failed to load
    .catch(err => console.error('Matomo load failed', err)); // eslint-disable-line no-console
};

export const identifyUser = (userID: string | null = null): void => {
  // GA
  identifyGAUser(userID);
  // Matomo
  identifyMatomoUser(userID);
  // Sentry
  identifySentryUser(userID);
  // Braze
  identifyBrazeUser(userID);
};

export {
  ec,
  pageView,
  trackEvent,
  trackPageView,
  enhancers,
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
};
