import React from 'react';

import VLiveCharacterIntroductionFileInfoLive2D from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFileInfoLive2D';
import VLiveCharacterSubtitle from '@17live/app/components/VLiveCharacter/VLiveCharacterSubtitle';
import VLiveCharacterText from '@17live/app/components/VLiveCharacter/VLiveCharacterText';
import { $t } from '@17live/app/utils';
import Box from '@17live/ui/Box';
import IgModalVliverFileTutorial from '@17live/ui/Gui/IgModalVliverFileTutorial';

type VLiveCharacterIntroductionFromAppLive2DProps = {
  characterSizeLimitMB?: number;
  hasTab: boolean;
};

const VLiveCharacterIntroductionFromAppLive2D = ({
  characterSizeLimitMB,
  hasTab,
}: VLiveCharacterIntroductionFromAppLive2DProps) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <VLiveCharacterSubtitle>
        {$t('vlive_intro_content_3')}
      </VLiveCharacterSubtitle>
      <Box mb={'8px'}>
        <IgModalVliverFileTutorial width={150} height={150} />
      </Box>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_content_3_1')}
        </VLiveCharacterSubtitle>
        <VLiveCharacterText>
          {$t('vlive_intro_content_3_1_1')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_content_3_1_2')}
        </VLiveCharacterText>
      </Box>

      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_content_3_2')}
        </VLiveCharacterSubtitle>
        <Box mb={'24px'}>
          <VLiveCharacterText>
            {$t('vlive_intro_content_3_2_1')}
          </VLiveCharacterText>
        </Box>

        <VLiveCharacterIntroductionFileInfoLive2D
          isMobile
          characterSizeLimitMB={characterSizeLimitMB}
        />
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppLive2D;
