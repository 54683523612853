import Loadable from '@/components/Loadable';
import { isMobile } from '@/services/Device';

export default isMobile()
  ? Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "MobileBlock" */ '@17live/app/containers/MobileBlock/MobileBlockContainer'
        ),
    })
  : () => null;
