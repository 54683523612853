import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { fetchAPI } from '@17live/app/utils/api';

export type UseAPIQueryOptions<QueryFnData, Error, Data> = {
  query?: UseQueryOptions<QueryFnData, Error, Data>;
  fetch?: RequestInit;
};

export const createAPIQuery = (url: string, fetchParams: RequestInit = {}) =>
  fetchAPI(url, fetchParams);

export const useAPIQuery = <
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData
>(
  queryKey: QueryKey,
  url: string,
  options?: UseAPIQueryOptions<QueryFnData, Error, Data>
) =>
  useQuery(queryKey, () => createAPIQuery(url, options?.fetch), options?.query);
