import { SnackStatus } from '@17live/app/containers/ChatRoom/components/SubmitChat.constants';
import {
  DEFAULT_COUNTDOWN_SEC,
  RESET_SNACK_ANIMATION_INFO,
  RUN_SNACK_COUNTDOWN,
  SET_SNACK_ANIMATION_INFO,
  SET_SNACK_ANIMATION_STATUS,
  SET_SNACK_COUNTDOWN_STATUS,
  SET_SNACK_INIT_TIMER,
  SET_SNACK_STATUS,
  START_SNACK_COUNTDOWN,
  STOP_SNACK_COUNTDOWN,
  UPDATE_SNACK_TIMER,
} from '@17live/app/containers/Snack/Snack.constants';
import {
  AnimationStatus,
  CountdownStatus,
} from '@17live/app/enums/Snack.enums';
import { SnackAnimationInfo } from '@17live/app/types/Snack';

export const setCountdownStatus = (
  status: CountdownStatus = CountdownStatus.INIT
) => {
  return {
    type: SET_SNACK_COUNTDOWN_STATUS,
    payload: { status },
  };
};

export const startCountdown = (durationSec = DEFAULT_COUNTDOWN_SEC) => {
  return {
    type: START_SNACK_COUNTDOWN,
    payload: { durationSec },
  };
};

export const runCountdown = () => {
  return {
    type: RUN_SNACK_COUNTDOWN,
  };
};

export const stopCountdown = () => {
  return {
    type: STOP_SNACK_COUNTDOWN,
  };
};

export const updateTimer = (durationSec: number) => ({
  type: UPDATE_SNACK_TIMER,
  payload: { durationSec },
});

export const setInitTimer = (durationSec = DEFAULT_COUNTDOWN_SEC) => {
  return {
    type: SET_SNACK_INIT_TIMER,
    payload: { durationSec },
  };
};

export const setAnimationStatus = (
  status: AnimationStatus = AnimationStatus.INIT
) => {
  return {
    type: SET_SNACK_ANIMATION_STATUS,
    payload: { status },
  };
};

// For Incentive animation
export const setSnackAnimationInfo = (animationInfo: SnackAnimationInfo) => {
  return {
    type: SET_SNACK_ANIMATION_INFO,
    payload: { animationInfo },
  };
};

// For Incentive animation
export const resetSnackAnimationInfo = () => {
  return {
    // handle reset behavior in packages/app/containers/Snack/Snack.reducer.ts
    type: RESET_SNACK_ANIMATION_INFO,
  };
};

export const setSnackStatus = (status: SnackStatus) => {
  return {
    type: SET_SNACK_STATUS,
    payload: { status },
  };
};
