import { ofType } from 'redux-observable';

import { Map } from 'immutable';
import omit from 'lodash/omit';
import { concat, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';

import { openModal } from '@/containers/Modal/actions';
import {
  MODAL_LOGIN,
  MODAL_REGISTER,
  MODAL_REGISTER_OAUTH,
} from '@/containers/Modal/constants';
import {
  bindPhoneNumber,
  checkOAuthIDAvailable,
  ERROR_MESSAGE_LOGIN_ACCOUNT_NOT_REGISTERED,
  ERROR_THIRD_PARTY_LOGIN_NOT_AVAILABLE,
  loginWithApple,
  loginWithThirdParty as loginWithThirdPartyService,
  registerWithOAuth,
} from '@/services/17App/auth';
import { ERROR_MESSAGE_REGISTER_ACCOUNT_EXISTS } from '@/services/17App/register';
import {
  disconnect as disconnectGoogle,
  logout as logoutGoogle,
} from '@/services/Google';
import Preference from '@/services/Preference/session';
import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';

import { getRefererForRecordingLandingPage } from '@17live/app/containers/LandingPage/LandingPage.utils';
import {
  loginFailed,
  loginSuccess,
} from '@17live/app/containers/LoginProvider/actions';
import { LOGOUT } from '@17live/app/containers/LoginProvider/constants';
import { processLoginResponse } from '@17live/app/containers/LoginProvider/epics';
import { makeSelectLoginUserInfo } from '@17live/app/containers/LoginProvider/selectors';

import { authWith17, setAuthProcessingStatus } from './actions';
import {
  getRegisterOauthInfo,
  getThirdPartyPayload,
  processAuthWithThirdParty,
} from './auths';
import {
  AUTH_WITH_17,
  AUTH_WITH_THIRD_PARTY,
  DO_THIRD_PARTY_REGISTER,
  LOGIN_WITH_THIRD_PARTY,
  NOT_AVAILABLE_ERROR_SUFFIX,
  REGISTER_WITH_THIRD_PARTY,
  THIRD_PARTY_ACTION_MODAL_MAP,
  THIRD_PARTY_TYPES,
} from './constants';
import { getThirdPartyAction, handleLoginOrRegisterFailed } from './utils';

const loginUserInfoSelector = makeSelectLoginUserInfo();
const checkGoogleLogout = type => {
  if (type === 'google') {
    logoutGoogle();
    disconnectGoogle();
  }
};

export const authWithThirdPartyEpic = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_WITH_THIRD_PARTY),
    tap(() => {
      // record the last location visited to go back to
      Preference.set('returnPath', window.location.pathname);
    }),
    mergeMap(({ payload: { type, action } }) =>
      concat(
        of(setAuthProcessingStatus(true)),
        processAuthWithThirdParty(type, state$.value, action).pipe(
          // only desktop goes here
          map(thirdPartyPayload =>
            authWith17(
              type,
              action,
              // omit action returned from authentication which is only used for mobile redirectURL
              omit(thirdPartyPayload, 'action')
            )
          ),
          catchError(err => of(loginFailed(err, type)))
        ),
        of(setAuthProcessingStatus(false))
      )
    )
  );

export const authWith17Epic = action$ =>
  action$.pipe(
    ofType(AUTH_WITH_17),
    mergeMap(({ payload: { type, action, thirdPartyPayload } }) => {
      return concat(
        of(setAuthProcessingStatus(true)),
        getThirdPartyPayload(type, thirdPartyPayload).pipe(
          mergeMap(thirdPartyInfo => {
            if (type === THIRD_PARTY_TYPES.APPLE) {
              return loginWithApple(thirdPartyInfo);
            }

            return checkOAuthIDAvailable(thirdPartyInfo).pipe(
              catchError(err => {
                if (
                  err.errorMessage === ERROR_THIRD_PARTY_LOGIN_NOT_AVAILABLE
                ) {
                  return throwError(err);
                }
                return of(err);
              }),
              map(oauthInfo =>
                getThirdPartyAction(action)(type, {
                  thirdPartyInfo,
                  oauthInfo,
                  referer: getRefererForRecordingLandingPage(), // MSite Google & Facebook & Twitter & Line
                })
              )
            );
          }),
          catchError(err =>
            handleLoginOrRegisterFailed(
              THIRD_PARTY_ACTION_MODAL_MAP[action],
              err,
              type
            )
          )
        ),
        of(setAuthProcessingStatus(false))
      );
    })
  );

export const loginWithThirdPartyEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_WITH_THIRD_PARTY),
    switchMap(
      ({
        payload: {
          type,
          thirdPartyInfo,
          oauthInfo: { errorMessage },
        },
      }) =>
        (errorMessage === `${type}${NOT_AVAILABLE_ERROR_SUFFIX}`
          ? loginWithThirdPartyService(type, thirdPartyInfo).pipe(
              mergeMap(processLoginResponse),
              catchError(err => of(loginFailed(err, type)))
            )
          : handleLoginOrRegisterFailed(
              MODAL_LOGIN,
              new Error(ERROR_MESSAGE_LOGIN_ACCOUNT_NOT_REGISTERED),
              type
            )
        ).pipe(tap(() => of(checkGoogleLogout(type))))
    )
  );

export const registerWithThirdPartyEpic = action$ =>
  action$.pipe(
    ofType(REGISTER_WITH_THIRD_PARTY),
    switchMap(
      ({
        payload: {
          type,
          thirdPartyInfo, // access token
          oauthInfo: { errorMessage, ...restOAuthInfo }, // oauthInfo e.g. gender, name, picture
          phoneInfo,
          requestID,
          referer,
        },
      }) => {
        return errorMessage === `${type}${NOT_AVAILABLE_ERROR_SUFFIX}`
          ? handleLoginOrRegisterFailed(
              MODAL_REGISTER,
              ERROR_MESSAGE_REGISTER_ACCOUNT_EXISTS,
              type
            ).pipe(tap(() => of(checkGoogleLogout(type))))
          : of(
              openModal({
                name: MODAL_REGISTER_OAUTH,
                params: {
                  type,
                  oauthInfo: getRegisterOauthInfo(type, {
                    ...restOAuthInfo,
                    ...thirdPartyInfo,
                  }),
                  phoneInfo: { ...phoneInfo, requestID },
                  referer,
                },
              })
            );
      }
    )
  );

export const doThirdPartyRegisterEpic = action$ =>
  action$.pipe(
    ofType(DO_THIRD_PARTY_REGISTER),
    switchMap(
      ({ payload: { openID, oauthType, oauthInfo, phoneInfo, referer } }) =>
        registerWithOAuth({ openID, oauthInfo, referer }).pipe(
          tap(loginInfo => {
            LocalStorage.setItem(KEY_LOGIN_INFO, loginInfo);
          }),

          switchMap(loginInfo => {
            if (oauthType === THIRD_PARTY_TYPES.APPLE) {
              return of(loginInfo).pipe(map(loginSuccess));
            }

            return bindPhoneNumber(phoneInfo).pipe(
              concatMap(() =>
                processLoginResponse({
                  ...loginInfo,
                  registerType: oauthType,
                })
              )
            );
          }),
          catchError(err =>
            handleLoginOrRegisterFailed(MODAL_REGISTER_OAUTH, err, oauthType)
          )
        )
    )
  );

export const logoutGoogleEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOGOUT),
    filter(() => {
      const userInfo = loginUserInfoSelector(state$.value) || Map();

      return userInfo.get('extIDGoogle') || userInfo.get('googleID');
    }),
    tap(() => logoutGoogle()),
    skip()
  );

export default [
  authWithThirdPartyEpic,
  loginWithThirdPartyEpic,
  registerWithThirdPartyEpic,
  doThirdPartyRegisterEpic,
  logoutGoogleEpic,
  authWith17Epic,
];
