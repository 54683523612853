import { REGION } from '@17live/app/constants';

// Because of the order, do not use SUPPORT_REGIONS
export const EVENT_PAGE_REGIONS = [
  REGION.TW,
  REGION.JP,
  REGION.HK,
  REGION.MY,
  REGION.SG,
  REGION.US,
  REGION.PH,
  REGION.IN,
  REGION.ID,
  REGION.TH,
  REGION.VN,
  REGION.MM,
];

export const API_PATH = 'event';
