/*
 *
 * Gift actions
 *
 */
import { GiftWhitelist } from '@17live/app/types/Gift';
import { RedEnvelopeEventInfo } from '@17live/app/types/RedEnvelope';

import {
  GET_ANIMATION_SRC_LIST,
  GET_BAGGAGE_INFO,
  GET_CUSTOM_GIFT_INFO,
  GET_GIFT_INFO,
  GET_GIFT_INFO_WITH_CHECK_LOADING,
  GET_GOODS_INFO,
  GET_LUCKY_BAG_CEILING_INFO,
  GET_LUCKY_BAG_INFO,
  GET_SEND_HISTORY,
  GET_SLOTS_INFO,
  HANDLE_ANIMATION_END,
  ON_RECEIVE_GIFT,
  PUSH_COMBO_MESSAGE,
  RECEIVED_GIFT,
  REMOVE_COMBO_MESSAGE,
  RESET_GIFT_INFO,
  SET_ANIMATION_SRC_LIST,
  SET_BAGGAGE_INFO,
  SET_CUSTOM_GIFT_INFO,
  SET_GIFT_EVENT_INFO,
  SET_GIFT_INFO,
  SET_GIFTS_ONLY,
  SET_GOODS_INFO,
  SET_LEVEL_UP_INFO,
  SET_LUCKY_BAG_CEILING_IDS,
  SET_LUCKY_BAG_INFO,
  SET_SEND_HISTORY,
  SET_SLOTS_INFO,
  SET_WHITELIST_GIFTS,
  SHOW_ANIMATION,
  SHOW_BONUS_BOX_ANIMATION,
  SHOW_GIFT_ANIMATION,
  SHOW_POKE_ANIMATION,
  SHOW_RED_ENVELOPE_ANIMATION,
  UPDATE_BAGGAGE_INFO,
  UPDATE_LEVEL_UP_INFO,
  UPDATE_LUCKY_BAG_CEILING_INFO,
} from './constants';

export const getGiftInfoWithCheckLoading = (roomID: string) => ({
  type: GET_GIFT_INFO_WITH_CHECK_LOADING,
  payload: {
    roomID,
  },
});

export const getGiftInfo = (roomID: string, userID?: string) => ({
  type: GET_GIFT_INFO,
  payload: {
    roomID,
    userID,
  },
});

export const setGiftInfo = giftInfo => ({
  type: SET_GIFT_INFO,
  payload: giftInfo,
});

export const setGiftsOnly = gifts => ({
  type: SET_GIFTS_ONLY,
  payload: gifts,
});

export const setGiftEventInfo = () => ({
  type: SET_GIFT_EVENT_INFO,
});

export const resetGiftInfo = roomID => ({
  type: RESET_GIFT_INFO,
  payload: {
    roomID,
  },
});

export const setWhitelistGifts = (whitelistGifts: GiftWhitelist[]) => ({
  type: SET_WHITELIST_GIFTS,
  payload: {
    whitelistGifts,
  },
});

export const onReceiveGift = (roomID, gift, type) => ({
  type: ON_RECEIVE_GIFT,
  payload: {
    roomID,
    gift,
    type,
  },
});

export const receivedGift = (roomID, gift, type) => ({
  type: RECEIVED_GIFT,
  payload: {
    roomID,
    gift,
    type,
  },
});

export const showGiftAnimation = (roomID, gift) => ({
  type: SHOW_GIFT_ANIMATION,
  payload: {
    roomID,
    ...gift,
  },
});

export const showPokeAnimation = (roomID, pokeInfo) => ({
  type: SHOW_POKE_ANIMATION,
  payload: {
    roomID,
    ...pokeInfo,
  },
});

export const showRedEnvelopeAnimation = (
  roomID: string,
  redEnvelopeEventInfo: RedEnvelopeEventInfo
) => ({
  type: SHOW_RED_ENVELOPE_ANIMATION,
  payload: {
    roomID,
    redEnvelopeEventInfo,
  },
});

export const showBonusBoxAnimation = (
  roomID: string,
  redEnvelopeEventInfo: RedEnvelopeEventInfo
) => ({
  type: SHOW_BONUS_BOX_ANIMATION,
  payload: {
    roomID,
    redEnvelopeEventInfo,
  },
});

export const showAnimation = (roomID: string, animationID: string) => ({
  type: SHOW_ANIMATION,
  payload: {
    roomID,
    animationID,
  },
});

export const handleAnimationEnd = (roomID: string, animationID: string) => ({
  type: HANDLE_ANIMATION_END,
  payload: { roomID, animationID },
});

export const pushComboMessage = (roomID, comboInfo) => ({
  type: PUSH_COMBO_MESSAGE,
  payload: {
    roomID,
    ...comboInfo,
  },
});

export const removeComboMessage = (roomID, comboMessageID) => ({
  type: REMOVE_COMBO_MESSAGE,
  payload: {
    roomID,
    comboMessageID,
  },
});

export const setLuckyBagInfo = luckyBagInfo => ({
  type: SET_LUCKY_BAG_INFO,
  payload: luckyBagInfo,
});

export const setLuckyBagCeilingIDs = giftIDs => ({
  type: SET_LUCKY_BAG_CEILING_IDS,
  payload: giftIDs,
});

export const setSlotsInfo = slotsInfo => ({
  type: SET_SLOTS_INFO,
  payload: slotsInfo,
});

export const getSendHistory = roomID => ({
  type: GET_SEND_HISTORY,
  payload: {
    roomID,
  },
});

export const getBaggageInfo = () => ({
  type: GET_BAGGAGE_INFO,
});

export const setBaggageInfo = (baggage, missionDisplayStatus) => ({
  type: SET_BAGGAGE_INFO,
  payload: {
    baggage,
    missionDisplayStatus,
  },
});

export const getGoodsInfo = () => ({
  type: GET_GOODS_INFO,
});

export const setGoodsInfo = baggageList => ({
  type: SET_GOODS_INFO,
  payload: {
    baggageList,
  },
});

export const setSendHistory = history => ({
  type: SET_SEND_HISTORY,
  payload: history,
});

export const getLuckyBagInfo = (giftIDs?: string[]) => ({
  type: GET_LUCKY_BAG_INFO,
  payload: giftIDs,
});

export const getLuckyBagCeilingInfo = () => ({
  type: GET_LUCKY_BAG_CEILING_INFO,
});

export const updateLuckyBagCeilingInfo = ceilingInfo => ({
  type: UPDATE_LUCKY_BAG_CEILING_INFO,
  payload: ceilingInfo,
});

export const getSlotsInfo = tab => ({
  type: GET_SLOTS_INFO,
  payload: tab,
});

export const updateBaggageInfo = (
  giftID,
  expiredTimestamp,
  { shouldUpdate = false }
) => ({
  type: UPDATE_BAGGAGE_INFO,
  payload: {
    giftID,
    expiredTimestamp,
    shouldUpdate,
  },
});

export const getCustomGiftInfo = roomID => ({
  type: GET_CUSTOM_GIFT_INFO,
  payload: {
    roomID,
  },
});

export const setCustomGiftInfo = giftInfo => ({
  type: SET_CUSTOM_GIFT_INFO,
  payload: giftInfo,
});

export const setLevelUpInfo = levelUps => ({
  type: SET_LEVEL_UP_INFO,
  payload: {
    levelUps,
  },
});

export const updateLevelUpInfo = (giftID, response) => ({
  type: UPDATE_LEVEL_UP_INFO,
  payload: {
    giftID,
    response,
  },
});

export const getAnimationSrcList = () => ({
  type: GET_ANIMATION_SRC_LIST,
});

export const setAnimationSrcList = animationSrcList => ({
  type: SET_ANIMATION_SRC_LIST,
  payload: animationSrcList,
});
