import * as React from 'react';
import { forwardRef, memo, Ref, SVGProps } from 'react';
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={160}
    height={160}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#F3F3F3"
      d="M76.875 155c41.421 0 75-33.579 75-75s-33.579-75-75-75-75 33.579-75 75 33.579 75 75 75Z"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M117.416 34.253c1.806.318 4.397.912 6.144 3.481 1.342 1.973 1.846 2.365 1.828 6.137-.003.758-.315 1.985-.936 3.684.062.846-.054 1.504-.348 1.973-.441.703-2.843 1.903-3.108 1.856l-.504-.089-3.45 2.762-.07.397 1.041 2.61 6.445 2.708-4.477 13.138c.557 9.103.181 14.384-1.128 15.842-1.013 1.127-6.717 1.158-12.06.023-5.021-1.067-9.707-3.247-10.393-3.982-.945-1.012.641-6.08 4.758-15.203l1.311-13.938 6.324.094 1.282-1.017c.483-.909.796-1.35.939-1.326.143.026.188-.233.137-.777-.876-.655-1.557-1.326-2.042-2.015-.486-.689-.764-1.179-.836-1.47-.561-.474-.918-.868-1.07-1.184-.228-.473-1.964-3.078-1.154-6.105.809-3.027 2.314-5.06 5.473-6.627 3.159-1.569 4.089-1.29 5.894-.972Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M116.841 35.268c.177-2.19 1.318-3.456 3.424-3.797 2.106-.34 3.658.22 4.658 1.68-2.43-1.196-4.031-1.479-4.805-.847a3.613 3.613 0 0 0-1.344 2.442l-1.933.522ZM122.578 49.933c.831 2.198 1.728 3.195 2.691 2.99.962-.204 1.623-.37 1.982-.5-1.349 1.163-2.292 1.744-2.83 1.74-.537-.004-1.043-.179-1.517-.525a3.39 3.39 0 0 0 1.14 1.083c.452.26 1.159.474 2.121.644-1.633.897-2.847 1.147-3.642.751-.795-.396-1.383-.964-1.766-1.705.118.67.339 1.174.662 1.51.323.337.742.653 1.257.947-1.573.206-2.657.04-3.253-.499-.595-.538-.936-1.08-1.021-1.625l-.697 1.963-1.452-2.617 2.858-3.37 3.467-.787ZM107.609 47.293c-1.533 1.781-2.717 2.411-3.551 1.89-.835-.52-1.399-.904-1.692-1.149.87 1.556 1.558 2.423 2.064 2.604.506.18 1.041.189 1.606.026-.448.329-.929.538-1.442.627-.514.09-1.251.05-2.214-.12 1.229 1.401 2.284 2.052 3.166 1.951.882-.1 1.63-.433 2.243-.997-.34.589-.72.986-1.139 1.192-.419.206-.92.36-1.505.46 1.407.731 2.483.946 3.227.643.744-.302 1.249-.695 1.516-1.178l-.017 2.083 2.26-1.962-1.533-4.145-2.989-1.925ZM124.936 40.38c.884 1.077 1.499 1.714 1.846 1.911.348.198.897.208 1.647.03-.473.634-.889.919-1.248.855l-.796-.14c.8.614 1.29.937 1.469.969.18.032.627.017 1.343-.044-1.257.796-2.135 1.15-2.634 1.062-.499-.088-1.059-.359-1.681-.812l.054-3.832ZM98.234 83.123l-8.376 20.047-11.316 19.588 10.816 4.88 7.361-20.461 9.597-16.506 5.068 17.942 2.477 22.319 12.782-2.054-7.477-22.885-1.533-19.45-19.399-3.42Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="m115.163 127.091.309 7.168 11.597-5.069 5.774-3.005-10.218.266-7.462.64Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M135.813 126.232c3.364 2.376-.753 5.822-2.775 7.132-1.348.873-3.891 1.362-7.63 1.469-1.56.378-3.447.76-5.662 1.146-3.322.58-3.662-.016-4.232-.746-.57-.731-.636-2.977 2.401-4.536 2.025-1.039 3.546-1.22 4.564-.543.821-1.03 2.221-1.876 4.201-2.537 2.97-.991 5.769-3.762 9.133-1.385ZM89.591 121.619l-2.741 6.63-9.164-8.729-4.398-4.799 9.51 3.744 6.793 3.154Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M70.48 113.748c-3.973 1.083-1.283 5.729.17 7.651.967 1.282 3.19 2.612 6.667 3.991 1.336.888 2.979 1.893 4.928 3.013 2.924 1.681 3.447 1.238 4.232.746.786-.491 1.616-2.58-.705-5.083-1.547-1.669-2.915-2.36-4.102-2.071-.42-1.249-1.446-2.523-3.081-3.821-2.452-1.947-4.134-5.508-8.108-4.426ZM120.785 58.74c2.38-1.319 4.32-2.205 5.821-2.658 1.5-.453 3.26-.683 5.279-.691 1.118-.654 3.356-1.532 6.716-2.633 3.359-1.1 5.555-1.677 6.588-1.727l3.228 4.483c-1.085 1.87-2.709 3.48-4.87 4.83-3.241 2.025-11.831 5.132-16.077 5.84-2.831.471-5.234.71-7.211.716l.526-8.16Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="m144.726 53.402 1.215-.752a5.117 5.117 0 0 1 .22-2.508c.279-.792.496-1.6.649-2.424-.282-1.049-.197-1.533.257-1.453.679.12 1.084 1.292 1.016 1.677-.045.257-.004.654.123 1.19l1.545-.637c2.129-2.105 3.391-3.036 3.785-2.794.592.363-2.289 3.566-2.289 3.566s4.194-1.99 4.684-1.452c.326.358-1.055 1.263-4.144 2.716 2.799-.457 4.26-.517 4.384-.177.192.528-1.185 1-4.132 1.415 2.286.865 3.354 1.493 3.201 1.883-.152.39-1.286.143-3.402-.743a4.604 4.604 0 0 1-1.693 1.095c-.651.244-1.258.337-1.82.279l-1.837 1.792-1.762-2.673ZM106.922 56.296c-1.785-2.053-3.305-3.55-4.56-4.489-1.256-.939-2.83-1.757-4.725-2.455-.826-.997-2.63-2.587-5.41-4.77-2.78-2.184-4.647-3.476-5.6-3.877l-4.567 3.108c.38 2.128 1.355 4.196 2.924 6.204 2.354 3.013 9.363 8.87 13.11 10.987 2.499 1.411 4.676 2.458 6.531 3.14l2.297-7.848Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="m86.25 43.092-.883-1.123a5.127 5.127 0 0 0 .65-2.432c.008-.839.081-1.672.22-2.5.623-.888.709-1.373.255-1.453-.68-.12-1.46.843-1.529 1.229-.045.256-.22.615-.522 1.076l-1.234-1.127c-1.28-2.706-2.148-4.013-2.601-3.92-.68.138.93 4.134.93 4.134s-3.26-3.304-3.903-2.967c-.43.225.559 1.548 2.964 3.97-2.473-1.387-3.826-1.943-4.059-1.666-.36.43.772 1.344 3.4 2.743-2.445.03-3.663.255-3.653.674.01.42 1.16.575 3.452.466.282.62.687 1.156 1.215 1.608.529.452 1.067.747 1.615.884L83.68 45l2.57-1.908Z"
      clipRule="evenodd"
    />
    <path
      fill="#FF3200"
      d="M138.125 79.063a1.563 1.563 0 1 0 0-3.126 1.563 1.563 0 0 0 0 3.126Z"
    />
    <path
      fill="#000"
      d="M86.25 29.375a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
    <path
      fill="#FF3200"
      d="M130.156 99.063a2.969 2.969 0 1 0 0-5.938 2.969 2.969 0 0 0 0 5.938ZM78.906 22.188a2.969 2.969 0 1 0 0-5.938 2.969 2.969 0 0 0 0 5.938Z"
    />
    <path
      fill="#000"
      d="M152.188 89.688a2.5 2.5 0 1 0 0-5.001 2.5 2.5 0 0 0 0 5Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M90.02 70.89a.625.625 0 0 1 .275.338l2.22 6.523a.625.625 0 0 1-.475.815l-6.83 1.299a.625.625 0 0 1-.654-.931l1.13-1.919-.075-.047c-1.564-.979-2.775-1.534-4.2-1.768a6.563 6.563 0 0 0-2.898.15c-1.57.445-2.7 1.089-3.538 1.894-.524.503-.854.958-1.273 1.697l-.334.603-.037.065-4.33-2.5.173-.313c.714-1.304 1.309-2.17 2.337-3.158 1.433-1.377 3.287-2.432 5.64-3.098 1.691-.479 3.39-.55 5.071-.274 2.218.365 3.938 1.15 6.003 2.44l.94-1.594a.625.625 0 0 1 .855-.221Z"
      clipRule="evenodd"
    />
    <path
      fill="#E6E6E6"
      d="M59.688 49.063h-45a5.625 5.625 0 0 0-5.626 5.624v45a5.625 5.625 0 0 0 5.626 5.625h45a5.625 5.625 0 0 0 5.624-5.624v-45a5.625 5.625 0 0 0-5.624-5.626Z"
    />
    <path
      fill="#fff"
      d="M58.75 53.75H15.625c-1.036 0-1.875.84-1.875 1.875V98.75c0 1.035.84 1.875 1.875 1.875H58.75c1.035 0 1.875-.84 1.875-1.875V55.625c0-1.035-.84-1.875-1.875-1.875Z"
    />
    <path
      fill="#FF845B"
      fillRule="evenodd"
      d="M17.5 95.938h38.731L44.657 80.041 34.124 91.927l-7.492-8.678L17.5 95.938Z"
      clipRule="evenodd"
    />
    <path
      fill="#F30"
      d="M50.187 73.913a4.925 4.925 0 1 0 0-9.85 4.925 4.925 0 0 0 0 9.85Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
