export const PerimeterXCaptchaID = 'px-captcha';

export const PerimeterXSetting = {
  challenge: {
    view: {
      width: '100%',
      height: '40px',
      padding: '0',
      backgroundColor: '#ffffff',
      fillColor: '#333333',
      borderColor: '#333333',
      borderWidth: 3,
      borderRadius: 26,
      textColor: '#333333',
      texSize: 16,
      fontWeight: 500,
      animation: true,
      checkmarkThickness: '3px',
      checkmarkHeight: '20px',
      checkmarkWidth: '10px',
    },
  },
};
