export const INIT_POLL = 'app/PollProvider/INIT_POLL';
export const GET_POLL_CONFIG = 'app/PollProvider/GET_POLL_CONFIG';
export const SET_POLL_CONFIG = 'app/PollProvider/SET_POLL_CONFIG';
export const SET_POLL_INFO = 'app/PollProvider/SET_POLL_INFO';
export const CREATE_POLL = 'app/PollProvider/CREATE_POLL';
export const END_POLL = 'app/PollProvider/END_POLL';
export const DELETE_POLL = 'app/PollProvider/DELETE_POLL';
export const UPDATE_POLL = 'app/PollProvider/UPDATE_POLL';
export const UPDATE_PAID_POLL = 'app/PollProvider/UPDATE_PAID_POLL';
export const UPDATE_POLL_INFO = 'app/PollProvider/UPDATE_POLL_INFO';
export const SET_POLL_OPTION_COUNT = 'app/PollProvider/SET_POLL_OPTION_COUNT';
export const SET_POLL_ERROR = 'app/PollProvider/SET_POLL_ERROR';
export const SET_IS_POLL_LOADING = 'app/PollProvider/SET_IS_POLL_LOADING';

export const POLL_FIELD = {
  NOTIF_ID: 'notifID',
  TITLE: 'title',
  TTL: 'ttl',
  OPTIONS: 'options',
  PRICE: 'price',
  TOPIC: 'topic',
};

export const MAX_POLL_OPTIONS = 5;
