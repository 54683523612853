import { AvatarFile, AvatarInfo } from '@17live/app/types/Avatar';
import { EventInfo } from '@17live/app/types/Event';
import { api } from '@17live/app/utils/api';

const API_PATH = 'avatar';

export const getAvatarInfo = ({ userID }: { userID: string }) =>
  api.getJSON<AvatarInfo>(`${API_PATH}/user/${userID}`);

export const getAvatarFiles = () =>
  api.getJSON<AvatarFile>(`${API_PATH}/files`);

export const getAvatarWeeklyEventInfo = ({ region }: { region: string }) =>
  api.getJSON<EventInfo>(`${API_PATH}/weeklyevent/info?region=${region}`);
