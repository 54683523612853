import { history } from '@/store/history';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN_URL } from './constants';

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN_URL,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    debug: process.env.DEPLOY_ENV !== 'production',
    environment: process.env.DEPLOY_ENV,
    release: `17live@${process.env.VERSION}`,
    // 為了避免每個月第一天就超過 Sentry 免費版使用額度，造成後續無法修改及驗證，先暫時將整個 disable
    enabled: false,
  });
};

export const identifySentryUser = (userID = null) => {
  Sentry.setUser({ id: userID });
};
