import React from 'react';

import AIAssistantSection from '@17live/app/components/AIAssistant/AIAssistantSection';
import AIAssistantWrapper from '@17live/app/components/AIAssistant/AIAssistantWrapper';
import { $t } from '@17live/app/utils';
import { COLOR_NEUTRAL_700 } from '@17live/design-system/color';
import {
  Heading4,
  Heading6,
  TextBody2,
} from '@17live/design-system/Typography/Typography';
import IgAiCoHostFaqAutoEngagement from '@17live/ui/Gui/IgAiCoHostFaqAutoEngagement';
import IgAiCoHostFaqLearning from '@17live/ui/Gui/IgAiCoHostFaqLearning';
import IgAiCoHostFaqReport from '@17live/ui/Gui/IgAiCoHostFaqReport';
import IgAiCoHostFaqTopic from '@17live/ui/Gui/IgAiCoHostFaqTopic';
import IgAiCoHostFaqTrending from '@17live/ui/Gui/IgAiCoHostFaqTrending';

const AIAssistantIntroductionInteractiveGameOff = () => {
  return (
    <AIAssistantWrapper>
      <Heading4>{$t('ai_assistant_info_subtitle')}</Heading4>
      <AIAssistantSection>
        <Heading6>{$t('ai_assistant_info_heading_1')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_1')}
        </TextBody2>
        <IgAiCoHostFaqAutoEngagement width={'100%'} height={'100%'} />
      </AIAssistantSection>
      <AIAssistantSection>
        <Heading6>{$t('ai_assistant_info_heading_2')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_2')}
        </TextBody2>
        <IgAiCoHostFaqTopic width={'100%'} height={'100%'} />
      </AIAssistantSection>
      <AIAssistantSection>
        <Heading6>{$t('ai_assistant_info_heading_3')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_3')}
        </TextBody2>
        <IgAiCoHostFaqTrending width={'100%'} height={'100%'} />
      </AIAssistantSection>
      <AIAssistantSection>
        <Heading6>{$t('ai_assistant_info_heading_4')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_4')}
        </TextBody2>
        <IgAiCoHostFaqReport width={'100%'} height={'100%'} />
      </AIAssistantSection>
      <AIAssistantSection>
        <Heading6>{$t('ai_assistant_info_heading_5')}</Heading6>
        <TextBody2 color={COLOR_NEUTRAL_700}>
          {$t('ai_assistant_info_desc_5')}
        </TextBody2>
        <IgAiCoHostFaqLearning width={'100%'} height={'100%'} />
      </AIAssistantSection>
    </AIAssistantWrapper>
  );
};

export default AIAssistantIntroductionInteractiveGameOff;
