/*
 *
 * Modal reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOGIN,
  LOGIN_FAILED,
} from '@17live/app/containers/LoginProvider/constants';
import { SEND_VERIFICATION_CODE } from '@/containers/ForgotPasswordModal/constants';
import { OPEN_MODAL, CLOSE_MODAL, SET_PARAMS, SET_ERROR } from './constants';

const initialState = fromJS({});

const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_MODAL:
      return state.merge(payload);

    case SET_PARAMS:
      return state.update('params', params => params.merge(payload));

    case LOGIN:
    case SEND_VERIFICATION_CODE:
      return state.set('error', '');

    case LOGIN_FAILED:
      return state.set('error', payload.error);
    case SET_ERROR:
      return state.set('error', payload);

    case CLOSE_MODAL:
      return fromJS({});

    default:
      return state;
  }
};

export default modalReducer;
