import React, { useReducer } from 'react';
import Modal, { ModalProps } from './Modal';
import ModalContext, { ModalActionType, ModalAction } from './Context';

const initState = {
  isOpened: false,
  children: null as React.ReactNode,
};

type InitState = typeof initState;

const reducer: React.Reducer<InitState, ModalAction> = (
  state,
  { type, payload }
) => {
  let newState = {} as Partial<InitState>;
  switch (type) {
    case ModalActionType.OPEN_MODAL:
      newState = { isOpened: true, ...payload };
      break;

    case ModalActionType.CLOSE_MODAL:
      newState = { isOpened: false };
      break;

    default:
      return state;
  }

  return { ...state, ...newState };
};

export const ModalProvider: React.FC<{
  defaultProps?: ModalProps;
}> = ({ children, defaultProps }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <ModalContext.Provider value={dispatch}>
      <Modal {...defaultProps} {...state} />
      {children}
    </ModalContext.Provider>
  );
};
