import $script from 'scriptjs';
import { bindCallback, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, share } from 'rxjs/operators';
import { isHandheld } from '@/services/Device';
import { ANDROID } from '@/services/Mobile/constants';
import { THIRD_PARTY_TYPES } from '@/containers/ThirdPartyAction/constants';
import { isFromIframe } from '@/containers/ThirdPartyAction/utils';
import { SDK_URL, CLIENT_ID } from './constants';

export const initGoogleSDK = bindCallback($script)(SDK_URL).pipe(
  mergeMap(() => bindCallback(window.gapi.load)('auth2')),
  mergeMap(
    () =>
      new Promise((resolve, reject) => {
        window.gapi.auth2
          .init({
            client_id: CLIENT_ID,
          })
          .then(
            () => resolve(true),
            () => reject()
          );
      })
  ),
  catchError(() => of(false)),
  share()
);

const createAPIMethod = method =>
  initGoogleSDK.pipe(
    filter(Boolean),
    mergeMap(() => method)
  );

// login function don't need to and cannot be async or the popup won't open
export const login = ({ action }) =>
  of({}).pipe(
    filter(() => window.gapi),
    map(() => window.gapi.auth2.getAuthInstance()),
    mergeMap(GoogleAuth =>
      GoogleAuth.isSignedIn.get()
        ? of(GoogleAuth.currentUser.get())
        : GoogleAuth.signIn({
            setAppPackageName: ANDROID.PACKAGE_NAME,
            ...(isHandheld() && !isFromIframe()
              ? {
                  ux_mode: 'redirect',
                  redirect_uri: `${window.location.origin}/redirect/${THIRD_PARTY_TYPES.GOOGLE}/${action}`,
                }
              : {}),
          })
    ),
    map(user => user.getAuthResponse(true)),
    map(userProfile => ({
      googleIDToken: userProfile.id_token,
    })),
    catchError(error => throwError(error))
  );

export const logout = () => {
  initGoogleSDK.subscribe(() => {
    createAPIMethod(window.gapi.auth2.getAuthInstance().signOut());
  });
};

export const disconnect = () => {
  initGoogleSDK.subscribe(() => {
    createAPIMethod(window.gapi.auth2.getAuthInstance().disconnect());
  });
};
