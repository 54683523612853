// @flow
import type { Node } from 'react';
import React from 'react';
import type { ContextRouter, RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';

type Props = RouteProps & {
  isLoggedIn: boolean,
  render: ContextRouter => Node,
  renderUnAuth?: ContextRouter => Node,
  children?: ContextRouter => Node,
};

const AuthRoute = ({ isLoggedIn, render, renderUnAuth, ...rest }: Props) => {
  const { lang } = useLocale();
  const unAuthRender = renderUnAuth || (() => <Redirect to={`/${lang}`} />);

  return <Route {...rest} render={isLoggedIn ? render : unAuthRender} />;
};

export default AuthRoute;
