import {
  END_PIP_ALIVE,
  UPDATE_IN_ROOM_PIP_ROOM_ID,
  UPDATE_PIP_ROOM_ID,
  UPDATE_PIP_STATUS,
} from '@17live/app/containers/PIP/PIP.constants';

export const updateStatus = status => ({
  type: UPDATE_PIP_STATUS,
  payload: status,
});

export const updateRoomID = roomID => ({
  type: UPDATE_PIP_ROOM_ID,
  payload: String(roomID),
});

export const updateInRoomPIPRoomID = roomID => ({
  type: UPDATE_IN_ROOM_PIP_ROOM_ID,
  payload: roomID,
});

export const endPIPAlive = roomID => ({
  type: END_PIP_ALIVE,
  payload: { roomID },
});
