import { LANG } from '@17live/app/constants/langs';
import { ArmyRank } from '@17live/app/enums/Army.enums';

export const GET_LIVE_SETTING_INFO = 'app/LiveSetting/GET_LIVE_SETTING_INFO';
export const GET_STREAMER_CONFIG = 'app/LiveSetting/GET_STREAMER_CONFIG';
export const SET_STREAMER_CONFIG = 'app/LiveSetting/SET_STREAMER_CONFIG';
export const GET_RTMP_INFO = 'app/LiveSetting/GET_RTMP_INFO';
export const SET_RTMP_INFO = 'app/LiveSetting/SET_RTMP_INFO';
export const CREATE_RTMP = 'app/LiveSetting/CREATE_RTMP';
export const CREATE_RTMP_SUCCESS = 'app/LiveSetting/CREATE_RTMP_SUCCESS';
export const CREATE_RTMP_FAIL = 'app/LiveSetting/CREATE_RTMP_FAIL';
export const LEAVE_LIVE_SETTING = 'app/LiveSetting/LEAVE_LIVE_SETTING';
export const SET_IS_LIVE_SETTING_LOADING =
  'app/LiveSetting/SET_LIVE_SETTING_LOADING';

export const LIVE_RTMP_FIELD = {
  CAPTION: 'caption',
  HASHTAGS: 'hashtags',
  EVENT_ID: 'eventID',
  LANDSCAPE: 'landscape',
  SUBTAB_ID: 'subtabID',
  GAME_ID: 'gameID',
  IS_AUTO_ARCHIVE: 'isAutoArchive',
  IS_AUTO_PUBLISH: 'isAutoPublish',
  VLIVER_INFO: 'vliverInfo',
  IS_SHOW_PRODUCTS: 'isShowProducts',
  CLIP_PERMISSION: 'clipPermission',
  GAME_CENTER_SETTING: 'gameCenterSetting',
  ENABLE_OBS_GROUPCALL: 'enableOBSGroupCall',
};

export const LIVE_SETTING_LANDSCAPE_STRAIGHT = 'straight';
export const LIVE_SETTING_LANDSCAPE_HORIZONTAL = 'horizontal';
export const LIVE_SETTING_LANDSCAPE_OPTIONS = [
  {
    value: false,
    label: LIVE_SETTING_LANDSCAPE_STRAIGHT,
  },
  {
    value: true,
    label: LIVE_SETTING_LANDSCAPE_HORIZONTAL,
  },
];

export const LIVE_RTMP_DEFAULT_VALUES = {
  [LIVE_RTMP_FIELD.CAPTION]: '',
  [LIVE_RTMP_FIELD.HASHTAGS]: [],
  [LIVE_RTMP_FIELD.EVENT_ID]: 0,
  [LIVE_RTMP_FIELD.LANDSCAPE]: false,
  [LIVE_RTMP_FIELD.GAME_ID]: '',
  [LIVE_RTMP_FIELD.IS_AUTO_ARCHIVE]: true,
  [LIVE_RTMP_FIELD.IS_AUTO_PUBLISH]: false,
  [LIVE_RTMP_FIELD.IS_SHOW_PRODUCTS]: true,
  [LIVE_RTMP_FIELD.CLIP_PERMISSION]: 0,
};

export const MAX_LIVE_STREAM_HASHTAGS = 2;

export const ARMY_ONLY_FIELD = 'armyOnly';

export const ARMY_ONLY_OPTIONS = {
  ENABLE: 'enable',
  REQUIRED_ARMY_RANK: 'requiredArmyRank',
  ARMY_ONLY_PN: 'armyOnlyPN',
  SHOW_ON_HOT_PAGE: 'showOnHotPage',
};

export const ARMY_ONLY_DEFAULT_VALUES = {
  [ARMY_ONLY_OPTIONS.ENABLE]: false,
  [ARMY_ONLY_OPTIONS.REQUIRED_ARMY_RANK]: ArmyRank.CORPORAL,
  [ARMY_ONLY_OPTIONS.SHOW_ON_HOT_PAGE]: false,
  [ARMY_ONLY_OPTIONS.ARMY_ONLY_PN]: false,
};

export const ARMY_ONLY_STATE = {
  HIDE: 0,
  DISABLE: 1,
  ENABLE: 2,
  DISABLE_NO_FAN: 3,
};

export const PREMIUM_TYPES = {
  NONE: 0,
  PAID: 1,
  ARMY: 2,
};

export const LIVE_SETTING_SUBTAB_CONCERT = 'concert';

const ZH_OBS_GROUPCALL_FAQ_LINK =
  'https://17mediahelp.zendesk.com/hc/en-us/articles/36841125283609-%E6%B4%BE%E5%B0%8D%E7%9B%B4%E6%92%AD';
const JA_OBS_GROUPCALL_FAQ_LINK = 'https://jp.17.live/faq/30363/';
const EN_OBS_GROUPCALL_FAQ_LINK =
  'https://17mediahelp.zendesk.com/hc/en-us/articles/38830290086297-Group-Call';

// @see https://www.figma.com/design/ySWlFuIZj5EO2S8vq67fQY?node-id=2694-35955#1144831368
export const LANG_OBS_GROUPCALL_FAQ_LINK_MAP: { [key: string]: string } = {
  [LANG.EN]: EN_OBS_GROUPCALL_FAQ_LINK,
  [LANG.EN_US]: EN_OBS_GROUPCALL_FAQ_LINK,
  [LANG.ZH_HANT]: ZH_OBS_GROUPCALL_FAQ_LINK,
  [LANG.ZH_HK]: ZH_OBS_GROUPCALL_FAQ_LINK,
  [LANG.JA]: JA_OBS_GROUPCALL_FAQ_LINK,
};
