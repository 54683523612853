import {
  CHANGE_ROUTER,
  GET_CONFIG,
  GET_PERSONAL_CONFIG,
  INIT_APP,
  PUSH_ROUTER,
} from './constants';

export const initApp = () => ({
  type: INIT_APP,
});

export const getConfig = () => ({
  type: GET_CONFIG,
});

export const getPersonalConfig = () => ({
  type: GET_PERSONAL_CONFIG,
});

export const pushRouter = (url: string) => ({
  type: PUSH_ROUTER,
  payload: url,
});

export const changeRouter = () => ({
  type: CHANGE_ROUTER,
});
