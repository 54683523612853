import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

const DEFAULT_ABOUT_URL = 'https://about.17.live';

const AppRedirectToAbout = () => {
  const intl = useIntl();
  const redirectURL = intl.formatMessage({
    id: '17.live_footer_about_link',
  });

  useEffect(() => {
    window.location.href = redirectURL ?? DEFAULT_ABOUT_URL;
  }, [redirectURL]);

  return <></>;
};

export default AppRedirectToAbout;
