const loadScript = (appID: string) => {
  if (typeof window !== 'undefined') {
    /* eslint-disable no-underscore-dangle */
    const doc = document;
    const scriptElement = doc.createElement('script');
    const scripts = doc.getElementsByTagName('script')[0];
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.src = `/${appID}/init.js`;
    if (scripts && scripts.parentNode) {
      scripts.parentNode.insertBefore(scriptElement, scripts);
    }
    // /* eslint-enable */
  }
};

export default loadScript;
