import Loadable from '@/components/Loadable';
import ModalLoading from '@/containers/GlobalModal/components/ModalLoading';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "LoginModal" */
      /* webpackPrefetch: true */
      '../'
    ),
  loading: ModalLoading,
});
