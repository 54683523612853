/*
 *
 * AuthRoute
 *
 */
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsLoggedIn } from '@17live/app/containers/LoginProvider/selectors';
import AuthRoute from './components/AuthRoute';

const mapStateToProps = createStructuredSelector({
  isLoggedIn: makeSelectIsLoggedIn(),
});

export default connect(mapStateToProps)(AuthRoute);
