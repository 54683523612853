// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

export type Props = {
  // not necessary these types, we can add more if there is the need
  type?: 'video.other' | 'video.movie' | 'video.tv_show',
};

const VideoActor = ({ type }: Props) => {
  if (!type) {
    return null;
  }

  return (
    <ReactHelmet>
      <meta property="og:type" content={type} />
    </ReactHelmet>
  );
};

export default VideoActor;
