/**
 *
 * Loadable
 *
 * @flow
 *
 * This is not actually a component but rather a higher-order function which returns a LoadableComponent.
 * - Usage:
 *   const LoadableComponent = Loadable({
 *     loader: () => import('path/to/component'),
 *     {...otherProps}
 *   });
 */

import type { ComponentType } from 'react';
import React from 'react';

import ErrorBoundary from '@17live/app/components/ErrorBoundary';

import ReactLoadable from '@17media/react-loadable';

export type Options = {
  loader: () => Promise<*>,
  loading?: ComponentType,
};

const DELAY: number = 400; // delay to show loading in ms

const DummyLoadingComponent = () => null;

const Loadable = ({
  loader,
  loading = DummyLoadingComponent,
  ...rest
}: Options) =>
  ReactLoadable({
    loader,
    loading,
    delay: DELAY,
    render(loaded, props) {
      const Component = loaded.default;

      return (
        <ErrorBoundary>
          <Component {...rest} {...props} />
        </ErrorBoundary>
      );
    },
    ...rest,
  });

export default Loadable;
