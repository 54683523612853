// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Props = {
  title: string,
  titleTemplate: string,
  defaultTitle: string,
  region: ?string,
  ogTitle: string,
  twitterTitle: string,
};

const Title = ({
  title,
  titleTemplate,
  defaultTitle,
  region,
  ogTitle,
  twitterTitle,
}: Props) => {
  if (!title) {
    return <ReactHelmet defaultTitle={defaultTitle} />;
  }

  return (
    <ReactHelmet titleTemplate={titleTemplate}>
      <title>{[region, title].join('')}</title>
      <meta property="og:title" content={ogTitle || title} />
      <meta name="twitter:title" content={twitterTitle || title} />
    </ReactHelmet>
  );
};

export default Title;
