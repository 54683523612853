/**
 * Create the store with asynchronously loaded reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { routerMiddleware } from 'connected-react-router/immutable';
import Immutable, { fromJS } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { RECEIVE_MESSAGE } from '@17live/app/containers/ChatRoom/constants';
import Logger from '@17live/app/services/Logger';

import initialEpics from '../epics';
import createReducer from '../reducers';
import { setStore } from './';
import { history } from './history';

const LOGGER_LABEL = 'Epics';

function configureStore(initialState) {
  const epicMiddleware = createEpicMiddleware();

  // Create the store with two middlewares
  // 1. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [epicMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  /* istanbul ignore next */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: {
            immutable: Immutable, // to enable immutable.js serialization
          },
          actionsBlacklist: [
            RECEIVE_MESSAGE, // RECEIVE_MESSAGE from chatroom
          ],
          autoPause: true, // pause when the extension window is not opened
        })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer({ history }),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Create root epic that accepts async injection
  const epic$ = new BehaviorSubject(combineEpics(...initialEpics));
  const rootEpic = (action$, state$, deps) =>
    epic$.pipe(
      mergeMap(epic =>
        epic(action$, state$, deps).pipe(
          catchError((err, source$) => {
            setTimeout(() => {
              Logger.info(LOGGER_LABEL, err && err.errorMessage, err);
              throw err;
            }, 0);
            return source$;
          })
        )
      )
    );

  epicMiddleware.run(rootEpic);

  // Extensions
  store.asyncReducers = {}; // Async reducer registry
  store.asyncEpics = []; // Async epics registry
  store.addEpic = epic => {
    if (!(store.asyncEpics.includes(epic) || initialEpics.includes(epic))) {
      epic$.next(epic);
      store.asyncEpics.push(epic);
    }
  };

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      import('../reducers').then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers({
          history,
          asyncReducers: store.asyncReducers,
        });

        store.replaceReducer(nextReducers);
      });
    });
  }

  setStore(store);

  return store;
}

export default configureStore;
