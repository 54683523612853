import { Epic, ofType } from 'redux-observable';

import { of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import {
  ENTER_LIVE_STREAM_SUCCESS,
  LEAVE_LIVE_STREAM,
  RECEIVED_KEEP_VIEW_RESULT,
} from '@17live/app/containers/LiveStream/constants';
import {
  initSnackRankSystemInfo,
  setSnackRankSystemInfo,
  toggleSnackRankSystemFeatureOffAlert,
} from '@17live/app/containers/SnackRankSystem/SnackRankSystem.actions';
import { makeSelectSnackRankSystemInfoByRoomID } from '@17live/app/containers/SnackRankSystem/SnackRankSystem.selectors';

export const initSnackRankSystemInfoEpic: Epic = action$ =>
  action$.pipe(
    ofType(LEAVE_LIVE_STREAM),
    map(({ payload: roomID }) => initSnackRankSystemInfo({ roomID }))
  );

export const receiveSnackRankSystemInfoEpic: Epic = action$ =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    mergeMap(
      ({
        payload: { roomID, newSalaryAndRemunerationInfo: snackRankSystemInfo },
      }) =>
        of(
          setSnackRankSystemInfo({
            roomID,
            info: snackRankSystemInfo,
          })
        )
    )
  );

export const handleKeepAliveResponseEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RECEIVED_KEEP_VIEW_RESULT),
    filter(
      ({
        payload: { roomID, newSalaryAndRemunerationInfo: snackRankSystemInfo },
      }) => {
        const { featureEnabled } = makeSelectSnackRankSystemInfoByRoomID()(
          state$.value,
          {
            roomID,
          }
        );
        const nextFeatureFlag = snackRankSystemInfo?.featureEnabled;

        // 動態關機制只有在一開始是 feature enabled 的狀況下才會執行，另外 snackRankSystemInfo 必須有值，避免關播等行為也觸發到(關播時 snackRankSystemInfo = null)
        return snackRankSystemInfo && featureEnabled && !nextFeatureFlag;
      }
    ),
    mergeMap(
      ({
        payload: { roomID, newSalaryAndRemunerationInfo: snackRankSystemInfo },
      }) =>
        of(
          setSnackRankSystemInfo({
            roomID,
            info: snackRankSystemInfo,
          }),
          toggleSnackRankSystemFeatureOffAlert(true)
        )
    )
  );

export default [
  initSnackRankSystemInfoEpic,
  receiveSnackRankSystemInfoEpic,
  handleKeepAliveResponseEpic,
];
