// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Props = {
  keywords: string,
};

const Keywords = ({ keywords }: Props) => {
  if (!keywords) {
    return null;
  }

  return (
    <ReactHelmet>
      <meta name="keywords" content={keywords} />
    </ReactHelmet>
  );
};

export default Keywords;
