// @flow
import { EVENT_CATEGORIES, EVENT_ACTIONS } from './constants';

const getObjectToDataset = (object: {}, prefix?: string = 'data-ga-'): {} =>
  Object.keys(object).reduce(
    (obj, name) => ({
      ...obj,
      [`${prefix}${name.replace(
        /[A-Z]/g,
        match => `-${match.toLowerCase()}`
      )}`]: object[name],
    }),
    {}
  );

/**
 * tracking data-ga props, powered by `event-tracker` autotrack plugin
 * @deprecated please switch to use `setTrackEventAttrs`
 */
export const getUIButtonClickProps = (
  eventLabel: string = '',
  args?: {} = {}
) =>
  getObjectToDataset({
    on: 'click',
    eventCategory: EVENT_CATEGORIES.UI,
    eventAction: EVENT_ACTIONS.BUTTON_CLICK,
    eventLabel,
    ...args,
  });

/**
 * * @deprecated please switch to use `setTrackEventAttrs`
 */
export const getNonInteractionButtonClickProps = (args?: {} = {}) =>
  getObjectToDataset({
    on: 'click',
    eventAction: EVENT_ACTIONS.BUTTON_CLICK,
    nonInteraction: true,
    ...args,
  });
