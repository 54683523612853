import apis from '@/services/17App/shared/apis';

import { setTrackAPIHeaders } from '@17live/app/services/analytics';
import { PollFields } from '@17live/app/types/Poll';

const API_PATH = 'polls';

export const createPoll = (fields: PollFields) => {
  return apis.postJSON(API_PATH, fields);
};

export const getPoll = (pollID: string) => {
  return apis.getJSON(`${API_PATH}/${pollID}`);
};

export const endPoll = (pollID: string) => {
  return apis.patchJSON(`${API_PATH}/ended/${pollID}`);
};

export const deletePoll = (pollID: string) => {
  return apis.deleteJSON(`${API_PATH}/${pollID}`);
};

export const updatePoll = (
  pollID: string,
  { traceID, ...fields }: PollFields
) => {
  return apis.postJSON(
    `${API_PATH}/${pollID}/vote`,
    fields,
    setTrackAPIHeaders({ 'trace-id': traceID })
  );
};

/**
 * 這支 api body 與送禮 api `lives/${roomID}/gift` 相同
 * 因為付費投票和禮物m原本用同個 api，但會影響到送禮
 * 因此 [APP-48650] 開了一隻新的 api 來分流
 */
export const updatePaidPoll = (roomID, giftID, traceID) => {
  return apis.postJSON(
    `lives/${roomID}/voteGift`,
    {
      srcType: 1,
      giftID,
    },
    setTrackAPIHeaders({
      'trace-id': traceID,
    })
  );
};
