import { stringify } from 'query-string';

import apis from '@/services/17App/shared/apis';

import { CreateRtmpParams, GetRtmpParams } from '@17live/app/types/Rtmp';

const API_PATH = 'rtmp';

export const getRtmpInfo = (
  { rtmpProvider }: GetRtmpParams = { rtmpProvider: '' }
) =>
  apis.getJSON(`${API_PATH}?${stringify({ 'rtmp-provider': rtmpProvider })}`);

export const createRtmp = ({
  armyOnly,
  caption = '',
  device = 'OBS',
  eventID,
  hashtags,
  landscape = false,
  revenueUserID,
  streamerType = 0,
  subtabID,
  topic,
  gameID,
  isAutoArchive,
  isAutoPublish,
  vliverInfo,
  virtualStreamerInfo,
  shopSetting,
  postID,
  enableOnLiveShop,
  clipPermission,
  gameCenterSetting,
  enableOBSGroupCall,
}: CreateRtmpParams) =>
  apis.postJSON(`${API_PATH}`, {
    armyOnly,
    caption,
    device,
    eventID,
    hashtags,
    landscape: !!landscape,
    revenueUserID,
    streamerType,
    subtabID,
    topic,
    gameID,
    archiveConfig: {
      autoRecording: !!isAutoArchive,
      autoPublish: isAutoPublish,
      clipPermission,
    },
    vliverInfo,
    virtualStreamerInfo,
    shopSetting,
    postID,
    enableOnLiveShop,
    gameCenterSetting,
    enableOBSGroupCall,
  });
