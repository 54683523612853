import { stringify } from 'query-string';
import { empty } from 'rxjs';
import { THIRD_PARTY_TYPES } from '@/containers/ThirdPartyAction/constants';
import configs from './configs';
import { FB_BASE_URL } from './constants';

export const login = ({ returnPath, action }) => {
  const { appId, version, authType, responseType } = configs;

  const params = {
    client_id: appId,
    auth_type: authType,
    response_type: responseType,
    redirect_uri: `${window.location.origin}/redirect/${THIRD_PARTY_TYPES.FACEBOOK}`,
    state: JSON.stringify({
      returnPath,
      action,
    }),
  };

  const fbLoginURL = `${FB_BASE_URL}/${version}/dialog/oauth?${stringify(
    params
  )}`;

  window.location.assign(fbLoginURL);

  return empty();
};
