// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Props = {
  url: ?string,
  deepLinkURL: ?string,
};

const DeepLink = ({ url, deepLinkURL }: Props) => (
  <ReactHelmet>
    {deepLinkURL && (
      <meta property="al:ios:url" content={`media17://${deepLinkURL}`} />
    )}
    {url && <meta property="al:android:url" content={url} />}
  </ReactHelmet>
);

export default DeepLink;
