import React, { useCallback, useContext, useMemo } from 'react';

import Modal, { ModalProps } from './Modal';
import ModalContext, { ModalActionType } from './Context';

export default Modal;

type ReplaceModalProps = OmitType<ModalProps, 'isOpened'>;

export const useModal = ({
  onClickMask,
  onESC,
  portalEl,
  top,
  bottom,
}: ReplaceModalProps = {}) => {
  const dispatch = useContext(ModalContext);

  const useModalDefaultProps = useMemo(
    () => ({
      onClickMask,
      onESC,
      portalEl,
      top,
      bottom,
    }),
    [onClickMask, onESC, portalEl, top, bottom]
  );
  const open = useCallback(
    (children: React.ReactNode, replaceProps: ReplaceModalProps = {}) => {
      dispatch({
        type: ModalActionType.OPEN_MODAL,
        payload: {
          children,
          ...useModalDefaultProps,
          ...replaceProps,
        },
      });
    },
    [dispatch, useModalDefaultProps]
  );

  const close = useCallback(() => {
    dispatch({ type: ModalActionType.CLOSE_MODAL });
  }, [dispatch]);

  return { open, close };
};

export * from './Modal';
export * from './Provider';
