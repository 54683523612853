import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import LoadingSVG from '@/components/LoadingSVG';

import VLiveCharacterIntroduction from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroduction';
import {
  DEFAULT_REGION,
  RegionType,
  SUPPORT_REGIONS,
} from '@17live/app/constants';
import { ErrorRoutePath } from '@17live/app/constants/routePath';
import { useFetch, useIsMobile, useLocation } from '@17live/app/hooks';
import { Config } from '@17live/app/types/Config';
import Box from '@17live/ui/Box';

const VLiveCharacterIntroductionContainer = () => {
  const isMobile = useIsMobile();

  const { location } = useLocation();
  const search = new URLSearchParams(location.search);
  const isFromApp = search.has('mobile');
  const searchRegion = search.get('region') as RegionType;
  const region = SUPPORT_REGIONS.includes(searchRegion)
    ? searchRegion
    : DEFAULT_REGION;

  // webview has no loginUserID so config api will use force-region as request header params
  const headers = {
    userSelectedRegion: region,
    'force-region': region,
  };

  // There are too many logic in useFetchConfig already so it's hard to separate force-region logic for webView and the affected scope might be unpredictable.

  // Therefore, use useFetch directly to get config data instead of using useFetchConfig and useVliver hooks.
  const { data: config, isLoading: isConfigLoading } = useFetch<Config>(
    ['config', region],
    { headers }
  );

  const {
    characterSizeLimitMB = 0,
    easyCreationImageSizeLimitMB = 0,
    enabledModels = [],
  } = useMemo(() => config?.addOns?.vliver, [config]) || {};

  const hasVliverConfig = useMemo(() => !!config?.addOns?.vliver, [config]);

  if (isConfigLoading) {
    return <LoadingSVG />;
  }

  if (!hasVliverConfig) {
    return <Redirect to={ErrorRoutePath.NOT_FOUND} />;
  }

  return (
    <Box p={'16px'}>
      <VLiveCharacterIntroduction
        isMobile={isMobile}
        isFromApp={isFromApp}
        region={region}
        characterSizeLimitMB={characterSizeLimitMB}
        easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
        enabledModels={enabledModels}
      />
    </Box>
  );
};

export default VLiveCharacterIntroductionContainer;
