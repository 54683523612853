import React from 'react';

import LoadingSVG from '@/components/LoadingSVG';

import loadable from '@loadable/component';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "MaintenanceSystemOverload" */ './MaintenanceSystemOverload'
    ),
  {
    fallback: <LoadingSVG />,
  }
);
