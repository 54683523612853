import { stringify } from 'query-string';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import apis from '@/services/17App/shared/apis';

import { CreateCustomEventParams } from '@17live/app/types/CustomEvent';

const API_PATH = 'event';

const getCustomEventV2 = (userID: string) =>
  apis.getJSON(
    `${API_PATH}/customEventV2?${stringify({ userID })}`,
    null,
    null,
    { retries: 0 }
  );

const getCustomEventV2Gifts = (userID: string) =>
  apis.getJSON(
    `${API_PATH}/customEventV2/gifts?${stringify({ userID })}`,
    null,
    null,
    { retries: 0 }
  );

/**
 * 原本是一支 API 'event/customEvent' 拿回資料，但因包含個人化資訊，
 * BE 希望將 gifts 資料獨立成一支 API 以利於 cache。
 * 因此改成打 2 支 API 再合併
 */
export const getCustomEventInfo = (userID: string) =>
  forkJoin([getCustomEventV2(userID), getCustomEventV2Gifts(userID)]).pipe(
    map(([customEvent, customEventGifts]) => ({
      ...customEvent,
      ...customEventGifts,
    }))
  );

export const createCustomEvent = (fields: CreateCustomEventParams) =>
  apis.postJSON(`${API_PATH}/customEvent`, fields);

export const editCustomEvent = (eventID: string, status: number) =>
  apis.patchJSON(`${API_PATH}/customEvent/${eventID}`, { status });
