import produce from 'immer';
import create from 'zustand';

import {
  ApplicantInfo,
  StepStatusInfo,
} from '@17live/app/types/ContractApplication';

export type State = {
  stepStatusInfo: StepStatusInfo | null;
  setStepStatusInfo: (stepStatusInfo: StepStatusInfo | null) => void;
  applicantInfo: ApplicantInfo | null;
  setApplicantInfo: (applicantInfo: ApplicantInfo | null) => void;
};

export const useContractApplicationStore = create<State>(set => ({
  applicantInfo: null,
  stepStatusInfo: null,
  setStepStatusInfo: data => {
    set(
      produce(state => {
        state.stepStatusInfo = data;
      })
    );
  },
  setApplicantInfo: data => {
    set(
      produce(state => {
        state.applicantInfo = data;
      })
    );
  },
}));
