export default {
  sdkUrl: locale => `https://connect.facebook.net/${locale}/sdk.js`,
  appId:
    process.env.DEPLOY_ENV !== 'production' ||
    // dirty hack to load development facebook app in e2e
    window.E2E_TEST_MODE
      ? // stage and uat use same key
        // development test fb app id (useful with login)
        '286396242011402'
      : // production fb app id
        '898257960220808',

  version: 'v18.0', // https://developers.facebook.com/docs/graph-api/guides/versioning#jssdkcalls
  status: true,
  authType: 'rerequest',
  responseType: 'token',
};
