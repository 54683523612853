import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import { ConnectedRouter } from 'connected-react-router/immutable';

import defaultTheme from '@/constants/themes';
import GlobalStyle from '@/global-styles';
import { getStore } from '@/store';
import configureStore from '@/store/configureStore';
import { history } from '@/store/history';

import LanguageProvider from '@17live/app/containers/LanguageProvider'; // eslint-disable-line import/no-named-as-default
import { queryClient } from '@17live/app/utils';

export type RootProvidersProps = {
  initState?: Record<string, unknown>;
  theme?: any;
};

export const RootProviders: React.FC<RootProvidersProps> = ({
  children,
  initState,
  theme = defaultTheme,
}) => {
  return (
    <Provider store={initState ? configureStore(initState) : getStore()}>
      <ThemeProvider theme={theme}>
        <>
          <LanguageProvider>
            <ConnectedRouter history={history}>
              <QueryClientProvider client={queryClient}>
                {children}
                {process.env.NODE_ENV === 'test' ? null : (
                  <ReactQueryDevtools
                    position="bottom-right"
                    initialIsOpen={false}
                  />
                )}
              </QueryClientProvider>
            </ConnectedRouter>
          </LanguageProvider>
          <GlobalStyle />
        </>
      </ThemeProvider>
    </Provider>
  );
};
