import { ViolationConfig } from '@17live/app/containers/Violation/Violation.types';
import { useAPIQuery } from '@17live/app/hooks/useAPIQuery';

const API_PATH = 'skyeye';

export const ViolationQueryKeys = {
  root: ['violation'],
  violationReasons: (lang: string) => [...ViolationQueryKeys.root, lang],
};

export const useFetchViolationReasons = (lang: string) => {
  return useAPIQuery<ViolationConfig>(
    ViolationQueryKeys.violationReasons(lang),
    `${API_PATH}/violation_reason?lang=${lang}`,
    {
      query: {
        staleTime: 3 * 1000,
      },
    }
  );
};
