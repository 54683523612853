/*
 * Helmet Messages
 *
 * This contains all the text for the Helmet component.
 */
import defineMessages from 'define-messages';

export default defineMessages({
  titleTemplate: {
    id: 'app.components.Helmet.titleTemplate',
    defaultMessage: '%s - 17LIVE Stream',
  },
  defaultTitle: {
    id: 'app.components.Helmet.defaultTitle',
    defaultMessage: '17LIVE Stream - the best live stream App in Asia',
  },
  description: {
    id: 'app.components.Helmet.description',
    defaultMessage:
      '17LIVE stream is the best live stream App in the world, there are various streamers sharing their life everyday.',
  },
});
