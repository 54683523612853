import { Epic, ofType } from 'redux-observable';

import isEmpty from 'lodash/isEmpty';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  getPreference,
  patchPreference,
  putPreference,
} from '@/services/17App';
import { recordUserVolume } from '@/services/GoogleAnalytics';
import Preference from '@/services/Preference';
import { KEY_LOGIN_INFO, LocalStorage } from '@/services/Storage';
import { errorAction } from '@/utils/errorAction';

import { initPreference, updatePreference } from './actions';
import { GET_PREFERENCE, INIT_PREFERENCE, SET_PREFERENCE } from './constants';

const isAuth = () => {
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO);

  return loginInfo && loginInfo.userID;
};

export const initUserPreferenceEpic: Epic = action$ =>
  action$.pipe(
    ofType(INIT_PREFERENCE),
    filter(() => isAuth()),
    map(() => LocalStorage.getItem(KEY_LOGIN_INFO)),
    switchMap(({ userID }) =>
      putPreference(userID, {}).pipe(
        map(preference => updatePreference(preference)),
        catchError(err => of(errorAction(INIT_PREFERENCE, err)))
      )
    )
  );

export const getUserPreferenceEpic: Epic = action$ =>
  action$.pipe(
    ofType(GET_PREFERENCE),
    filter(() => isAuth()),
    map(() => LocalStorage.getItem(KEY_LOGIN_INFO)),
    switchMap(({ userID }) =>
      getPreference(userID).pipe(
        map(preference => {
          return userID && isEmpty(preference)
            ? initPreference()
            : updatePreference(preference);
        }),
        tap(
          payload =>
            payload && payload.volume && recordUserVolume(payload.volume)
        ),
        catchError(err => of(errorAction(GET_PREFERENCE, err)))
      )
    )
  );

export const setUserPreferenceEpic: Epic = action$ =>
  action$.pipe(
    ofType(SET_PREFERENCE),
    debounceTime(300),
    filter(() => isAuth()),
    switchMap(({ payload: { key, value } }) =>
      patchPreference(LocalStorage.getItem(KEY_LOGIN_INFO).userID, {
        [key]: value,
      }).pipe(catchError(err => of(errorAction(SET_PREFERENCE, err))))
    ),
    // don't do anything
    filter(() => false)
  );

export const getLocalPreferenceEpic: Epic = action$ =>
  action$.pipe(
    ofType(GET_PREFERENCE),
    filter(() => !isAuth()),
    mapTo(updatePreference(Preference.getAll()))
  );

export const setLocalPreferenceEpic: Epic = action$ =>
  action$.pipe(
    ofType(SET_PREFERENCE),
    debounceTime(300),
    filter(() => !isAuth()),
    tap(({ payload: { key, value } }) => {
      Preference.set(key, value);
    }),
    // don't do anything
    filter(() => false)
  );

export default [
  initUserPreferenceEpic,
  getUserPreferenceEpic,
  setUserPreferenceEpic,
  getLocalPreferenceEpic,
  setLocalPreferenceEpic,
];
