/* eslint-disable no-unused-vars */
import { Epic, ofType } from 'redux-observable';

import { push, replace } from 'connected-react-router';
import { isArray } from 'lodash';
import {
  animationFrameScheduler,
  concat,
  EMPTY,
  forkJoin,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  delayWhen,
  filter,
  finalize,
  map,
  mapTo,
  mergeMap,
  retryWhen,
  skip,
  skipUntil,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { getUserInfo } from '@/containers/UserProvider/actions';
import {
  enterLiveStream,
  getLiveStreamInfo as getLiveStreamInfoFromService,
  getPullURLs,
  keepViewLiveStream,
  quitViewLiveStream,
  unlockPremiumRoom,
} from '@/services/17App';
import { ERROR_CODES } from '@/services/17App/shared/constants';
import { trackEvent } from '@/services/GoogleAnalytics';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  EVENT_LABELS,
} from '@/services/GoogleAnalytics/constants';
import { KEY_PAY_FROM, LocalStorage } from '@/services/Storage';
import {
  LIVE_STREAM_STATUS,
  LIVE_STREAM_TYPE,
  VIEWING_STATUS,
} from '@/services/Video/constants';
import { errorAction } from '@/utils/errorAction';

import { FeatureType } from '@17live/app/components/VideoPlayer/PixiCanvas/Marquee/Marquee.enums';
import {
  appendSystemComment,
  receiveMessage,
  subscribedChatroom,
} from '@17live/app/containers/ChatRoom/actions';
import { filterMessageType } from '@17live/app/containers/ChatRoom/utils';
import { ConcertStatus } from '@17live/app/containers/Concert/Concert.constants';
import {
  getConcertStatus,
  getIsConcertFromOnelink,
} from '@17live/app/containers/Concert/Concert.utils';
import {
  getLuckyBagCeilingInfo,
  setGiftsOnly,
} from '@17live/app/containers/Gift/actions';
import { SET_GIFT_INFO } from '@17live/app/containers/Gift/constants';
import {
  getGroupCallInfo,
  receiveGroupCallInfo,
} from '@17live/app/containers/GroupCall/GroupCall.actions';
import {
  GET_GROUP_CALL_INFO,
  RECEIVED_GROUP_CALL_INFO,
} from '@17live/app/containers/GroupCall/GroupCall.constants';
import {
  makeSelectLang,
  makeSelectRegion,
} from '@17live/app/containers/LanguageProvider/selectors';
import {
  clearGroupCallInfo,
  clearLiveStreamInfoByRoomID,
  enterGroupCallSuccess,
  enterLiveStreamSuccess,
  getEnterLiveStreamInfo,
  getLiveStreamInfo,
  keepStream,
  keepStreamPolling,
  keepViewStreamPolling,
  leftLiveStream,
  onReceivedLiveStreamInfo,
  receiveChangeRoom,
  receivedConcertInfo,
  receivedKeepStreamResult,
  receivedKeepViewResult,
  receivedLiveStreamInfo,
  receiveGroupCallAction,
  receiveGroupCallMemberStatus,
  receiveMarquee,
  runKeepViewStreamPolling,
  setIsLiveStreamLoading,
  setRoomIDAliveCalled,
  subscribeGlobalChannel,
  updateLiveStreamStatus,
} from '@17live/app/containers/LiveStream/actions';
import {
  END_STREAM,
  ENTER_GROUP_CALL_SUCCESS,
  ENTER_LIVE_STREAM,
  ENTER_LIVE_STREAM_SUCCESS,
  ERROR_CODE,
  GET_ENTER_LIVE_STREAM_INFO,
  GET_LIVE_STREAM_INFO,
  KEEP_LIVE_POLLING_INTERVAL,
  KEEP_STREAM,
  KEEP_STREAM_POLLING,
  KEEP_VIEW_POLLING_INTERVAL,
  KEEP_VIEW_STREAM_POLLING,
  LEAVE_LIVE_STREAM,
  ON_RECEIVED_LIVE_STREAM_INFO,
  PUBLISH_STREAM,
  RECEIVE_GLOBAL_MESSAGE,
  RECEIVED_KEEP_VIEW_RESULT,
  RECEIVED_LIVE_STREAM_INFO,
  RUN_KEEP_VIEW_STREAM_POLLING,
  SUBSCRIBE_GLOBAL_CHANNEL,
  SUBSCRIBED_CHAT_ROOM,
  UNLOCK_PREMIUM_ROOM,
  UPDATE_LIVE_STREAM_STATUS,
} from '@17live/app/containers/LiveStream/constants';
import {
  LiveStreamInfoConsumer,
  LiveStreamInfoRequestTag,
} from '@17live/app/containers/LiveStream/enums';
import {
  checkStreamIsOnline,
  getConfigWithReactQuery,
} from '@17live/app/containers/LiveStream/utils';
import { LOGOUT_SUCCESS } from '@17live/app/containers/LoginProvider/constants';
import {
  makeSelectIsLoggedIn,
  makeSelectLoginUserID,
  makeSelectLoginUserRegion,
} from '@17live/app/containers/LoginProvider/selectors';
import { openModal } from '@17live/app/containers/Modal/actions';
import {
  CONCERT_MODAL_ID,
  MODAL_LOGIN,
} from '@17live/app/containers/Modal/constants';
import {
  updateInRoomPIPRoomID,
  updateRoomID,
} from '@17live/app/containers/PIP/PIP.actions';
import { END_PIP_ALIVE } from '@17live/app/containers/PIP/PIP.constants';
import { setPollInfo } from '@17live/app/containers/PollProvider/actions';
import { setRedEnvelopeEventInfo } from '@17live/app/containers/RedEnvelope/actions';
import { LEAVE_LIVE_SETTING } from '@17live/app/containers/SettingPage/constants';
import { pushSnackbar } from '@17live/app/containers/Snackbars/actions';
import { subscribeGlobalAnnouncementChannel } from '@17live/app/containers/StatusAnnouncement/StatusAnnouncement.actions';
import {
  endStream,
  getConcertInfo,
  getGroupCallInfoService,
  getMessageProvider,
  keepStream as keepStreamFromService,
  publishStream,
} from '@17live/app/services/17App';
import { setTrackingSession } from '@17live/app/services/analytics';
import { trackBrazeCustomEvents } from '@17live/app/services/Braze';
import {
  BRAZE_CUSTOM_EVENTS,
  BRAZE_EVENT_PROPERTY,
} from '@17live/app/services/Braze/constants';
import Logger from '@17live/app/services/Logger';
import Message from '@17live/app/services/Message/Message';
import {
  MessageProviderFeatureTypes,
  MessageProviders,
  MessageType,
} from '@17live/app/services/Message/Message.enums';
import { getMessengerConfig } from '@17live/app/services/Message/Message.utils';
import { Config } from '@17live/app/types/Config';
import { APIError } from '@17live/app/types/Error';
import {
  GroupCallAction,
  GroupCallActionPayload,
  GroupCallMemberStatusPayload,
} from '@17live/app/types/GroupCall';
import {
  ConcertInfo,
  EnterLiveStreamInfo,
  LiveStream,
} from '@17live/app/types/LiveStreams';
import {
  getLiveStreamRtmpUrls,
  getLiveStreamRtmpUrlsWithCheckCurrentProvider,
} from '@17live/app/utils/rtmpUrls';

import {
  makeSelectCurrentLiveStreamID,
  makeSelectLiveStream,
  makeSelectLiveStreamIsAvailable,
  makeSelectLiveStreamIsGroupCall,
  makeSelectLiveStreamIsRoomIDAliveCalled,
  makeSelectLiveStreamPremium,
  makeSelectLiveStreamUserID,
  makeSelectorGameCenterSetting,
} from './selectors';

const currentLiveStreamIDSelector = makeSelectCurrentLiveStreamID();
const liveStreamSelector = makeSelectLiveStream();
const liveStreamIsAvailableSelector = makeSelectLiveStreamIsAvailable();
const liveStreamIsGroupCallSelector = makeSelectLiveStreamIsGroupCall();
const liveStreamGameCenterSettingSelector = makeSelectorGameCenterSetting();

const filterByPremiumLocked = (state, { roomID }) => {
  const liveStreamPremiumSelector = makeSelectLiveStreamPremium(roomID);
  let isLocked = false;

  if (state) {
    isLocked = liveStreamPremiumSelector(state).isLocked;
  }

  return !isLocked;
};

const mergeLiveStreamFields = (stream: LiveStream) =>
  of(stream).pipe(
    mergeMap(
      () =>
        new Observable(ob => {
          const isOnline = checkStreamIsOnline(stream);
          const isConcert = stream.streamerType === LIVE_STREAM_TYPE.CONCERT;
          // only current live room have property 'roomID' and
          // only current live room need random rtmpUrls (for concert)
          const isNeedDynamicProvider =
            isConcert &&
            !!stream.roomID &&
            isOnline &&
            stream.calledFrom !== LiveStreamInfoConsumer.RTMP;
          const rtmpUrls = isNeedDynamicProvider
            ? getLiveStreamRtmpUrls(stream)
            : stream.rtmpUrls;
          const defaultStream = {
            ...stream,
            isOnline,
            rtmpUrls,
            redEnvelopeEventInfo: stream.redEnvelopeEventInfo || null,
          };

          if (
            !stream.premiumContent?.paymentInfo?.paid &&
            stream.premiumContent?.previewURL
          ) {
            const video = document.createElement('video');

            video.playsInline = true;
            video.src = stream.premiumContent?.previewURL;
            video.style.position = 'absolute';
            video.style.left = '-99em';
            video.volume = 0;

            document.body.appendChild(video);

            /**
             * iOS 設備限制無法在非用戶點擊時播放，導致 video load/error 事件都沒有進去。
             * 解決方法: 不採用 video.onloadeddata 直接 interval check 如果影片寬高有出來就停止偵測，並且限制最多只會偵測`5`秒強制結束。
             */
            const checkVideoSize$ = interval(0, animationFrameScheduler)
              .pipe(
                filter(() => video.videoWidth + video.videoHeight > 0),
                tap(() => {
                  ob.next({
                    ...defaultStream,
                    landscape: video.videoWidth > video.videoHeight,
                  });
                  ob.complete();
                  checkVideoSize$.unsubscribe();
                }),
                takeUntil(timer(5000)),
                finalize(() => {
                  ob.next(defaultStream);
                  ob.complete();

                  document.body.removeChild(video);
                })
              )
              .subscribe();

            video.onerror = () => {
              ob.next(defaultStream);
              ob.complete();
              document.body.removeChild(video);

              checkVideoSize$.unsubscribe();
            };
          } else {
            ob.next(defaultStream);
            ob.complete();
          }
        })
    ),
    catchError(() => of(null))
  );

/**
 * @name catchRtmpError
 * @description 讓 LiveSetting 回到可以重新開播的狀態，並且關閉 PIP
 * @param error
 * @param roomID
 */
const catchRtmpError = (roomID: string) =>
  of(
    updateLiveStreamStatus({ roomID, status: LIVE_STREAM_STATUS.CLOSED }),
    updateRoomID(null)
  );

export const subscribeGlobalChannelEpic: Epic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_GLOBAL_CHANNEL),
    tap(() => {
      Message.unsubscribeGlobalChannel();
    }),
    switchMap(({ payload: { roomID } }) =>
      Message.subscribeGlobalChannel().pipe(
        map(message => ({
          type: RECEIVE_GLOBAL_MESSAGE,
          payload: { message, roomID },
        })),
        takeUntil(
          action$.pipe(
            ofType(LEAVE_LIVE_STREAM),
            filter(
              ({ payload: unsubscribeRoomID }) => unsubscribeRoomID === roomID
            )
          )
        )
      )
    )
  );

export const initGlobalChannelEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    // Because filterByPremiumLocked will select liveStreamInfo to check if user paid
    // Need to wait new liveStreamInfo updated redux then filter correct data.
    // @see https://17media.atlassian.net/browse/APP-46467
    delayWhen(({ payload: { roomID } }) =>
      action$.pipe(
        ofType(ON_RECEIVED_LIVE_STREAM_INFO),
        filter(
          ({ payload: { roomID: liveStreamInfoRoom } }) =>
            liveStreamInfoRoom === roomID
        )
      )
    ),
    delayWhen(() => action$.pipe(ofType(RECEIVED_LIVE_STREAM_INFO))),
    filter(({ payload: { roomID } }) =>
      filterByPremiumLocked(state$?.value, { roomID })
    ),
    switchMap(({ payload: { roomID } }) => {
      return getMessageProvider(
        MessageProviderFeatureTypes.MESSAGE_PROVIDER_DYNAMIC
      ).pipe(
        tap(({ globalMessageProvider }) => {
          Message.setGlobalMessageProvider(globalMessageProvider);
        }),
        map(() => {
          return subscribeGlobalChannel({ roomID });
        })
      );
    })
  );

export const receiveMarqueeEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RECEIVE_GLOBAL_MESSAGE),
    filter(({ payload: { message } }) => {
      return message.type === MessageType.MARQUEE;
    }),
    filter(({ payload }) => {
      const {
        roomID,
        message: { marqueeMsg },
      } = payload;

      if (marqueeMsg.featureType !== FeatureType.GAME) return true;

      const gameCenterSetting = liveStreamGameCenterSettingSelector(
        state$.value,
        {
          roomID,
        }
      );
      /**
       * 遊戲相關的跑馬燈 & 直播間有開啟 game setting
       */
      return gameCenterSetting?.get('enable') === true;
    }),
    map(({ payload }) => receiveMarquee(payload))
  );

export const unlockPremiumRoomEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(UNLOCK_PREMIUM_ROOM),
    switchMap(({ payload: roomID }) =>
      unlockPremiumRoom(roomID).pipe(
        mapTo({
          type: ENTER_LIVE_STREAM,
          payload: { roomID },
        }),
        catchError(error => {
          if (error.errorCode === ERROR_CODE.NOT_ENOUGH_POINT) {
            LocalStorage.setItem(KEY_PAY_FROM, `/live/${roomID}`);
            const lang = makeSelectLang()(state$.value);
            return of(replace(`/${lang}/purchase`));
          } else if (error.errorCode === ERROR_CODE.INVALID_TOKEN) {
            return of(openModal({ name: MODAL_LOGIN }));
          }

          throw error;
        })
      )
    )
  );

export const onReceivedLiveStreamInfoEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ON_RECEIVED_LIVE_STREAM_INFO),
    map(({ payload }) => {
      // enter live room will call liveInfo API and recommend will call cells API.
      // but cells API maybe include current live data, it will override liveInfo.
      // to avoid this issue, exclude current live data from cells.
      // tip: only cells is array type, liveInfo is object type.
      const currentLiveStreamID = currentLiveStreamIDSelector(state$.value);

      if (currentLiveStreamID && isArray(payload)) {
        return {
          payload: payload.filter(
            ({ liveStreamID }) => liveStreamID !== currentLiveStreamID
          ),
        };
      }
      return { payload };
    }),
    map(({ payload }) => [].concat(payload)),
    // filter out empty array or empty payload
    filter(list => list.length > 0),
    mergeMap(list => {
      return forkJoin(list.map(mergeLiveStreamFields).filter(Boolean));
    }),
    map(data => {
      const liveStream = data.length === 1 ? data[0] : data;
      return receivedLiveStreamInfo(liveStream);
    })
  );

export const keepViewLiveStreamEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    // if enter API has any error, don't call keep view.
    filter(({ payload: { error } }) => !error),
    // only call keep view when the live stream is available
    filter(({ payload: { roomID } }) =>
      liveStreamIsAvailableSelector(state$.value, {
        roomID,
      })
    ),
    // premium and unlocked can call keep view
    filter(({ payload: { roomID } }) =>
      filterByPremiumLocked(state$.value, {
        roomID,
      })
    ),
    tap(({ payload: { roomID } }) => {
      const isPremium = !!state$.value.getIn([
        'liveStream',
        roomID,
        'premiumContent',
        'premiumType',
      ]);

      if (isPremium) {
        trackEvent(
          EVENT_CATEGORIES.PREMIUM_CONTENT,
          EVENT_ACTIONS.ENTER_LIVE_STREAM,
          EVENT_LABELS.PAID_STREAM
        );
      }
    }),
    /**
     * Track Braze Custom Events after Enter live stream success.
     * @see https://docs.google.com/spreadsheets/d/1YommD552iL-XvRARGMHl5WVNv_rpZT9iEVT3bevduZk/edit#gid=41936054
     */
    tap(({ payload: { roomID, streamerID } }) => {
      trackBrazeCustomEvents(BRAZE_CUSTOM_EVENTS.watchedStream, {
        [BRAZE_EVENT_PROPERTY.roomID]: roomID,
        [BRAZE_EVENT_PROPERTY.streamerID]: streamerID,
      });
    }),
    map(({ payload: { roomID, isConcert } }) => {
      return keepViewStreamPolling(roomID, isConcert);
    })
  );

export const leveaLiveStreamEpic: Epic = action$ => {
  return action$.pipe(
    ofType(LEAVE_LIVE_STREAM),
    map(({ payload: goingToLeaveRoomID }) => {
      return setRoomIDAliveCalled(goingToLeaveRoomID, false);
    })
  );
};

export const liveStreamNotAvailableEpic: Epic = action$ => {
  return action$.pipe(
    ofType(RECEIVED_KEEP_VIEW_RESULT),
    filter(
      ({ payload: { viewingStatus } }) =>
        viewingStatus !== VIEWING_STATUS.AVAILABLE
    ),
    map(({ payload: { roomID } }) => {
      return setRoomIDAliveCalled(roomID, false);
    })
  );
};

export const startToKeepViewStreamPollingEpic: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(KEEP_VIEW_STREAM_POLLING),
    delay(100),
    filter(({ payload: { roomID } }) => {
      const isAliveCalled = makeSelectLiveStreamIsRoomIDAliveCalled(roomID)(
        state$.value
      );

      return !isAliveCalled;
    }),
    concatMap(({ payload }) => {
      return of(
        setRoomIDAliveCalled(payload?.roomID, true),
        runKeepViewStreamPolling(payload)
      );
    })
  );
};

export const keepViewStreamPollingEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RUN_KEEP_VIEW_STREAM_POLLING),
    delay(100),
    // Use `mergeMap` bacause here might call alive of diffrent stream room at the same time
    mergeMap(
      ({ payload: { roomID, isConcert } }) => {
        return timer(0, KEEP_VIEW_POLLING_INTERVAL).pipe(
          mergeMap(() => {
            return keepViewLiveStream(roomID).pipe(
              concatMap(result => {
                const actions = [];

                if (result?.error) {
                  actions.push(setRoomIDAliveCalled(roomID, false));
                  actions.push(updateRoomID(null));
                  actions.push(updateInRoomPIPRoomID(null));
                }

                const { rtmpUrls } = result;
                let newRtmpUrls = rtmpUrls;
                if (isConcert && rtmpUrls?.length > 1) {
                  // for concert mode
                  // when current provider is throttle then change provider
                  // otherwise, do not change current provider
                  // but finally, all case need to update rtmpUrls
                  const liveStream = liveStreamSelector(state$.value, {
                    roomID,
                  })?.toJS();
                  const currentRtmpUrl = liveStream?.rtmpUrls?.[0];
                  newRtmpUrls = getLiveStreamRtmpUrlsWithCheckCurrentProvider(
                    currentRtmpUrl,
                    newRtmpUrls
                  );
                }

                actions.push(
                  receivedKeepViewResult({
                    ...result,
                    rtmpUrls: newRtmpUrls,
                    roomID,
                  })
                );

                return of(...actions);
              })
            );
          }),
          /**
           * 當 takeUntil 被觸發後，會有對應的 epic 去做存值的動作
           * @see leveaLiveStreamEpic packages/app/containers/LiveStream/epics.ts
           * @see liveStreamNotAvailableEpic packages/app/containers/LiveStream/epics.ts
           * @see endPIPAliveEpic packages/app/containers/PIP/PIP.epics.ts
           */
          takeUntil(
            merge(
              action$.pipe(
                ofType(LEAVE_LIVE_STREAM),
                filter(
                  ({ payload: goingToLeaveRoomID }) =>
                    goingToLeaveRoomID === roomID
                )
              ),
              action$.pipe(
                ofType(RECEIVED_KEEP_VIEW_RESULT),
                filter(
                  ({ payload: { viewingStatus } }) =>
                    viewingStatus !== VIEWING_STATUS.AVAILABLE
                )
              ),
              action$.pipe(
                ofType(END_PIP_ALIVE),
                filter(
                  ({ payload: { roomID: roomIdInPIP } }) =>
                    roomIdInPIP === roomID
                )
              )
            )
          ),
          catchError(() => of(setIsLiveStreamLoading(false)))
        );
      },
      // 現階段我們最多是會呼叫 2 條 "alive" API
      // 在直播間且有開 PIP 的狀況會需要打兩條 alive
      // 直播間本身自己的 alive 和 PIP 的 alive
      2
    )
  );

export const getLiveStreamInfoEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_LIVE_STREAM_INFO),
    switchMap(({ payload: { roomID, additionalInfo } }) => {
      const { calledFrom } = additionalInfo;

      /**
       * TODO: 簡化這邊的流程，確認哪些情境需要打 getPullURLs
       */
      switch (calledFrom) {
        case LiveStreamInfoConsumer.CONCERT:
        case LiveStreamInfoConsumer.FOLLOW: {
          return of({ roomID, additionalInfo });
        }
        default: {
          return getPullURLs(roomID).pipe(
            map(rtmpUrls => ({
              roomID,
              additionalInfo,
              rtmpUrls,
            }))
          );
        }
      }
    }),
    mergeMap(({ roomID, additionalInfo, rtmpUrls }) => {
      const { calledFrom, isAfterPublish } = additionalInfo;
      const loginUserID = makeSelectLoginUserID()(state$.value);

      return getLiveStreamInfoFromService(
        roomID,
        calledFrom,
        isAfterPublish
      ).pipe(
        tap(payload => {
          const isStreamer =
            loginUserID && loginUserID === payload.userInfo.userID;
          const isTV = !!payload.userInfo.programInfo;

          if (isTV) {
            setTrackingSession({
              liveContentID: payload.streamID,
              liveContentType: 'show',
            });
          } else {
            setTrackingSession({
              liveContentID: payload.streamID,
              liveContentType: isStreamer ? 'streamerlivestream' : 'livestream',
            });
          }
        }),
        concatMap((stream: LiveStream) => {
          const isConcert = stream.streamerType === LIVE_STREAM_TYPE.CONCERT;
          const liveStreamActions = [
            /**
             * save streamInfo
             */
            of(
              onReceivedLiveStreamInfo({
                ...stream,
                ...(isConcert || rtmpUrls?.error ? {} : { rtmpUrls }),
                ...additionalInfo,
              })
            ),
          ];

          if (!isConcert) {
            /**
             * request userInfo by userID
             */
            liveStreamActions.push(of(getUserInfo(stream.userID)));
          }

          if (stream.pollInfo) {
            liveStreamActions.push(of(setPollInfo(stream.pollInfo)));
          }

          if (stream.redEnvelopeEventInfo) {
            liveStreamActions.push(
              of(setRedEnvelopeEventInfo(roomID, stream.redEnvelopeEventInfo))
            );
          }

          /**
           * In live setting, clear this roomID liveStreamInfo to receive new info
           */
          if (calledFrom === LiveStreamInfoConsumer.RTMP) {
            return concat(
              of(clearLiveStreamInfoByRoomID(roomID)),
              ...liveStreamActions
            );
          }

          return concat(...liveStreamActions);
        }),
        catchError(err => {
          switch (calledFrom) {
            /**
             * In live room, include concert mode.
             * If request is from LIVE and is initial request
             * then redirect to 404, because can't find this streamer.
             */
            case LiveStreamInfoConsumer.CONCERT:
            case LiveStreamInfoConsumer.LIVE: {
              return of(setIsLiveStreamLoading(false));
            }

            /**
             * 拋出 UNKNOWN, DEFAULT, PARAMETER_ERROR 這幾隻 errorCode 時
             * 必須讓 LiveSetting 回到可以重新開播的狀態
             */
            case LiveStreamInfoConsumer.RTMP: {
              // Handle 如 api cors 而 err 為 null 的情境
              if (!err) {
                return concat(
                  of(pushSnackbar('somethingWrong')),
                  of(setIsLiveStreamLoading(false))
                );
              }

              if (
                !!err &&
                (err.errorCode === ERROR_CODES.UNKNOWN ||
                  err.errorCode === ERROR_CODES.DEFAULT ||
                  err.errorCode === ERROR_CODES.PARAMETER_ERROR)
              ) {
                return catchRtmpError(roomID);
              }
              return of(setIsLiveStreamLoading(false));
            }

            default: {
              return of(setIsLiveStreamLoading(false));
            }
          }
        })
      );
    })
  );

export const getEnterLiveStreamInfoEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_ENTER_LIVE_STREAM_INFO),
    switchMap(
      ({
        payload: {
          roomID,
          additionalInfo: { isConcert, customParameters },
        },
      }) => {
        return enterLiveStream(roomID, customParameters).pipe(
          mergeMap((info: EnterLiveStreamInfo) => {
            const region = makeSelectRegion()(state$.value);
            const appRegion = makeSelectLoginUserRegion()(state$.value);
            const loginUserID = makeSelectLoginUserID()(state$.value);

            return getConfigWithReactQuery(region, loginUserID, appRegion).pipe(
              map((cfg: Config) => {
                const {
                  addOns: { trivia, marquee },
                } = cfg;
                return {
                  triviaExtraInfo: trivia.HostInfos[info.streamerID],
                  marquees: marquee?.marquees,
                };
              }),
              /**
               * 本來 getLiveStreamInfo 在 enterLiveStreamEpic 裡打
               * 為了確保 onRecivedLiveStreamInfo 是在 subscribeChatRoomEpic 進到 delayWhen 後被 trigger
               * 因此將 getLiveStreamInfo 移來這邊
               * @see https://17media.atlassian.net/browse/APP-55269
               */
              concatMap(({ triviaExtraInfo, marquees }) => {
                const actions = [
                  enterLiveStreamSuccess({
                    ...info,
                    roomID,
                    triviaExtraInfo,
                    marquees,
                    isConcert,
                    // Concert 使用 isLive 作為關播依據
                    viewingStatus:
                      isConcert && !info.isLive
                        ? VIEWING_STATUS.ENDED
                        : info.viewingStatus,
                  }),
                  getLiveStreamInfo(roomID, {
                    calledFrom: LiveStreamInfoConsumer.LIVE,
                    requestTag: LiveStreamInfoRequestTag.INITIAL,
                  }),
                ];

                return of(...actions);
              })
            );
          })
        );
      }
    )
  );

export const enterLiveStreamEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM),
    switchMap(({ payload: { roomID, customParameters } }) => {
      const isLoggedin = makeSelectIsLoggedIn()(state$.value);
      const lang = makeSelectLang()(state$.value);

      const liveStreamActions = [
        of(getEnterLiveStreamInfo(roomID, { customParameters })),
        of(getUserInfo(roomID)),
      ];

      const concertLiveStreamActions = [
        of(
          getEnterLiveStreamInfo(roomID, {
            customParameters,
            isConcert: true,
          })
        ),
        of(
          getLiveStreamInfo(roomID, {
            calledFrom: LiveStreamInfoConsumer.CONCERT,
            requestTag: LiveStreamInfoRequestTag.INITIAL,
          })
        ),
      ];

      /**
       * @name getConcertInfo
       * @description 用來判斷該直播間是否為演唱會
       * @param roomID
       * spec: https://docs.google.com/document/d/1KNygDBRoixRHb4pCOqahl1_6wtXjYKMjCOmjvEzoa6w/edit#bookmark=id.nk2b437oz79r
       */
      return getConcertInfo(roomID).pipe(
        /**
         * return status code 200 mean this is concert live stream
         */
        concatMap((result: ConcertInfo) => {
          const { vodStartTimestamp, vodEndTimestamp, vodID } = result;

          const concertDialogURL = `/${lang}?roomID=${roomID}#${CONCERT_MODAL_ID}`;
          const vodURL = `/${lang}/vod/${vodID}`;

          const isConcertFromOnelink = getIsConcertFromOnelink();
          const concertStatus = getConcertStatus(result);

          /**
           * 如果碰到關播畫面需要使用 vodStartTimestamp, vodEndTimestamp 來顯示對應文字
           */
          const concertLiveStreamWithVODTimeAction = concat(
            of(
              receivedConcertInfo({
                roomID,
                vodStartTimestamp,
                vodEndTimestamp,
              })
            ),
            ...concertLiveStreamActions
          );

          // 未登入開啟 LoginModal
          if (!isLoggedin) {
            return of(
              openModal({ name: MODAL_LOGIN, replace: true, isForceOpen: true })
            );
          }

          switch (concertStatus) {
            // 開播或開播前30分鐘內走 CONCERT 流程
            case ConcertStatus.ON_LIVE:
            case ConcertStatus.PREPARE:
              return concertLiveStreamWithVODTimeAction;

            // 如果 VOD 開始期間引導用戶到 /vod/:vodID
            case ConcertStatus.VOD_AVAILABLE: {
              return of(push(vodURL));
            }

            /**
             * 其他的條件
             * 1. ConcertStatus.OFF_LIVE
             * 2. ConcertStatus.VOD_WAITING
             * 3. ConcertStatus.VOD_END
             */
            default: {
              // onelink 引導用戶到首頁
              if (isConcertFromOnelink) {
                return of(push(concertDialogURL));
              }

              // share link 引導用戶到直播間關播畫面
              return concertLiveStreamWithVODTimeAction;
            }
          }
        }),
        /**
         * Error handle
         *
         * refs:
         * https://17media.slack.com/archives/C021KUZNQMC/p1624273486356400
         * models/error.pb.go
         *
         * status code 404, config 沒設定好
         * Not concert room: status code 420, error code: 55001 非演唱會直播間
         * IP not available: status code 420, error code: 55002 不在支援區域內
         * status code 502, 服務異常
         */
        catchError((error: APIError) => {
          if (error) {
            switch (error.errorCode) {
              case ERROR_CODES.NOT_CONCERT_ROOM: {
                return concat(...liveStreamActions);
              }
              default: {
                // 跳轉回首頁並且顯示 Error message toast
                return concat(
                  of(push(`/${lang}`)),
                  of(setIsLiveStreamLoading(false))
                );
              }
            }
          }

          // error undefined 代表 config 沒設定好, 走 LIVE 流程
          return concat(...liveStreamActions);
        })
      );
    })
  );

export const leaveLiveStreamEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(LEAVE_LIVE_STREAM),
    /**
     * Track Braze Custom Events before Leave live stream.
     * @see https://docs.google.com/spreadsheets/d/1YommD552iL-XvRARGMHl5WVNv_rpZT9iEVT3bevduZk/edit#gid=41936054
     */
    tap(({ payload: roomID }) => {
      const streamerID = makeSelectLiveStreamUserID()(state$.value, {
        roomID,
      });

      trackBrazeCustomEvents(BRAZE_CUSTOM_EVENTS.stoppedWatchingStream, {
        [BRAZE_EVENT_PROPERTY.roomID]: roomID,
        [BRAZE_EVENT_PROPERTY.streamerID]: streamerID,
      });
    }),
    switchMap(({ payload: roomID }) =>
      quitViewLiveStream(roomID).pipe(
        mergeMap(() => {
          return concat(
            of(leftLiveStream(roomID)),
            // Because user have left the live room
            // Clear the Room ID of the PIP in the live room so that the PIP can be displayed normally outside the live room.
            of(updateInRoomPIPRoomID(null))
          );
        }),
        catchError(() => of(setIsLiveStreamLoading(false)))
      )
    )
  );

export const endLiveStreamEpic: Epic = action$ =>
  action$.pipe(
    filter(filterMessageType(MessageType.LIVE_STREAM_END)),
    map(({ payload: { roomID } }) =>
      getLiveStreamInfo(roomID, { calledFrom: LiveStreamInfoConsumer.LIVE })
    )
  );

export const subscribeChatRoomEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    // Because filterByPremiumLocked will select liveStreamInfo to check if user paid
    // Need to wait new liveStreamInfo updated redux then filter correct data.
    // @see https://17media.atlassian.net/browse/APP-46467
    delayWhen(({ payload: { roomID } }) =>
      action$.pipe(
        ofType(ON_RECEIVED_LIVE_STREAM_INFO),
        filter(
          ({ payload: { roomID: liveStreamInfoRoom } }) =>
            liveStreamInfoRoom === roomID
        )
      )
    ),
    delayWhen(() => action$.pipe(ofType(RECEIVED_LIVE_STREAM_INFO))),
    filter(({ payload: { roomID } }) =>
      filterByPremiumLocked(state$?.value, { roomID })
    ),
    filter(({ payload: { roomID, messageProvider } }) => {
      return !!roomID && typeof messageProvider !== 'undefined';
    }),
    tap(({ payload: { roomID } }) => {
      return Message.unsubscribeChatRoom(roomID);
    }),
    switchMap(({ payload: { roomID, messageProvider } }) => {
      return Message.setMessageProvider(messageProvider)
        .subscribeChatRoom(roomID)
        .pipe(
          map(message => {
            return receiveMessage(roomID, message);
          }),

          // dispatch action after subscribe chatroom
          startWith(subscribedChatroom(roomID)),

          // end observable for better performance
          takeUntil(
            action$.pipe(
              ofType(LEAVE_LIVE_STREAM),
              filter(
                ({ payload: unsubscribeRoomID }) => unsubscribeRoomID === roomID
              )
            )
          )
        );
    })
  );

export const unsubscribeChatRoomEpic: Epic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === LEAVE_LIVE_STREAM ||
        filterMessageType(MessageType.LIVE_STREAM_END)(action)
    ),
    map(({ payload }) => {
      return Message.unsubscribeChatRoom(payload.roomID || payload);
    }),
    skip(),
    catchError(err => of(errorAction('unsubscribeChatRoomEpic', err)))
  );

export const onSwitchingMessageProviderEpic: Epic = action$ =>
  action$.pipe(
    ofType(RECEIVED_KEEP_VIEW_RESULT),
    // Need to wait subscribeChatRoomEpic subscribed chatroom, if not, the channel will be subscribed twice.
    // @see https://17media.atlassian.net/wiki/spaces/17LI/pages/1065550141/2023+11+23+pubnub+2
    skipUntil(action$.pipe(ofType(SUBSCRIBED_CHAT_ROOM))),

    filter(({ payload: { messageProvider } }) => {
      // only when there is requested from api
      // and when the requested messageProvider is different then the current
      return (
        messageProvider in MessageProviders &&
        messageProvider !== Message.messageProvider
      );
    }),
    tap(({ payload: { roomID } }) => {
      return Message.unsubscribeChatRoom(roomID);
    }),
    switchMap(({ payload: { roomID, messageProvider } }) => {
      return Message.setMessageProvider(messageProvider)
        .subscribeChatRoom(roomID)
        .pipe(map(message => receiveMessage(roomID, message)));
    })
  );

export const onSwitchingGlobalMessageProviderEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RECEIVED_KEEP_VIEW_RESULT),
    filter(({ payload: { globalMessageProvider } }) => {
      return (
        globalMessageProvider in MessageProviders &&
        globalMessageProvider !== Message.globalMessageProvider
      );
    }),
    switchMap(({ payload }) => {
      return getMessengerConfig(state$).pipe(
        map(messenger => {
          return {
            payload,
            messenger,
          };
        })
      );
    }),
    filter(({ messenger: { enableDynamicGlobalMessageProvider } }) => {
      return enableDynamicGlobalMessageProvider;
    }),
    tap(({ payload: { globalMessageProvider } }) => {
      Message.setGlobalMessageProvider(globalMessageProvider);
    }),
    concatMap(({ payload: { roomID } }) => {
      return concat(
        of(subscribeGlobalAnnouncementChannel()),
        of(subscribeGlobalChannel({ roomID }))
      );
    })
  );

export const publishStreamEpic: Epic = action$ =>
  action$.pipe(
    ofType(PUBLISH_STREAM),
    switchMap(({ payload: { liveStreamID, roomID } }) =>
      getLiveStreamInfoFromService(roomID, LiveStreamInfoConsumer.RTMP).pipe(
        map(liveStreamInfo => {
          return {
            isReadyPublish:
              liveStreamInfo.status === LIVE_STREAM_STATUS.CREATED,
          };
        }),
        switchMap(({ isReadyPublish }) => {
          if (isReadyPublish) {
            trackEvent(
              EVENT_CATEGORIES.UI,
              EVENT_ACTIONS.BUTTON_CLICK,
              EVENT_LABELS.RTMP_PUBLISH
            );
            Logger.info('LiveSetting', 'Click RTMP publish', {
              liveStreamID,
            });
            return publishStream(liveStreamID).pipe(
              concatMap(() =>
                concat(
                  of(keepStreamPolling(liveStreamID, roomID)),
                  of(
                    getLiveStreamInfo(roomID, {
                      calledFrom: LiveStreamInfoConsumer.RTMP,
                      isAfterPublish: true,
                    })
                  )
                )
              ),
              catchError(() => of(setIsLiveStreamLoading(false)))
            );
          }
          return timer(0).pipe(
            map(() =>
              getLiveStreamInfo(roomID, {
                calledFrom: LiveStreamInfoConsumer.RTMP,
              })
            )
          );
        })
      )
    )
  );

export const endStreamEpic: Epic = action$ =>
  action$.pipe(
    ofType(END_STREAM),
    switchMap(({ payload: { liveStreamID, roomID } }) =>
      endStream(liveStreamID).pipe(
        map(() =>
          getLiveStreamInfo(roomID, { calledFrom: LiveStreamInfoConsumer.RTMP })
        ),
        catchError(() => of(setIsLiveStreamLoading(false)))
      )
    )
  );

/**
 * @description For LiveSetting keep publish stream alive
 * keep stream alive 的過程中，只要發生 catchError
 * 必須讓 LiveSetting 回到可以重新開播的狀態
 * @param action$
 */
export const keepStreamEpic: Epic = action$ =>
  action$.pipe(
    ofType(KEEP_STREAM),
    switchMap(({ payload: { liveStreamID, roomID } }) =>
      keepStreamFromService(liveStreamID).pipe(
        map(result => receivedKeepStreamResult(result)),
        catchError(() => catchRtmpError(roomID))
      )
    )
  );

export const keepStreamPollingEpic: Epic = action$ =>
  action$.pipe(
    ofType(KEEP_STREAM_POLLING),
    switchMap(({ payload: { liveStreamID, roomID } }) => {
      return timer(0, KEEP_LIVE_POLLING_INTERVAL).pipe(
        map(() => keepStream(liveStreamID, roomID)),
        takeUntil(
          merge(
            action$.pipe(ofType(END_STREAM)),
            action$.pipe(ofType(LOGOUT_SUCCESS)),
            action$.pipe(ofType(LEAVE_LIVE_SETTING)),
            action$.pipe(
              ofType(UPDATE_LIVE_STREAM_STATUS),
              filter(
                ({ payload: { status } }) =>
                  status === LIVE_STREAM_STATUS.CLOSED
              )
            )
          )
        ),
        catchError(() =>
          of(
            setIsLiveStreamLoading(false),
            getLiveStreamInfo(roomID, {
              calledFrom: LiveStreamInfoConsumer.RTMP,
            })
          )
        )
      );
    })
  );

export const receiveGroupCallActionEpic: Epic = action$ =>
  action$.pipe(
    filter(filterMessageType(MessageType.GROUP_CALL_ACTION)),
    map(({ payload }: { payload: GroupCallActionPayload }) => {
      const {
        roomID,
        message: { groupCallActionMsg },
      } = payload;

      const { groupCallInfo } = groupCallActionMsg;

      /**
       * All of group call action case
       * https://github.com/17media/wiki/blob/master/pages/Feature-Design/groupcall.md#action
       */
      switch (groupCallActionMsg.action) {
        // 1. 一般直播間轉 Group Call 直播間
        // 2. 有人加入 Group Call 直播間
        case GroupCallAction.ready: {
          if (
            Array.isArray(groupCallInfo?.members) &&
            groupCallInfo.members.length === 1
          ) {
            // 因為 case 2 的 Group Call 直播間已經有每五秒打一次 getGroupCallInfo
            // 如果這邊再 call getGroupCallInfo 就有會多打新加入的 streamer 的每五秒一次 getGroupCallInfo
            // 所以這邊判斷是 case 1 才 call getGroupCallInfo
            // 原本想用 creator 和 userID 是否相等來判斷，但發現直播間交棒給下一個 host 後，creator 還會是最一開始開 Group Call 的人
            // 所以改判斷 Group Call 的 member 數，如果為一人就認定他是一般直播間轉 Group Call
            return getGroupCallInfo({
              roomID,
              streamerID: groupCallActionMsg.userID,
            });
          }

          return receiveGroupCallAction(payload);
        }
        /**
         * 1. close: leave and end the stream
         * 2. leave: leave but keep streaming (solo)
         */
        case GroupCallAction.close:
        case GroupCallAction.leave:
          return appendSystemComment(roomID, {
            token: {
              key: 'group_stream_handover_left',
              params: [{ value: groupCallActionMsg.displayName }],
            },
          });
        default: {
          return receiveGroupCallAction(payload);
        }
      }
    })
  );

export const receiveGroupCallEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_GROUP_CALL_INFO),
    switchMap(({ payload: { roomID, streamerID } }) => {
      const region = makeSelectRegion()(state$.value);
      const loginUserID = makeSelectLoginUserID()(state$.value);
      const appRegion = makeSelectLoginUserRegion()(state$.value);

      return getConfigWithReactQuery(region, loginUserID, appRegion).pipe(
        map(config => {
          const {
            getInfoFailedCount,
            viewerGetAPIIntervalMilliSeconds,
          } = config?.addOns?.groupCall;
          return {
            count: getInfoFailedCount ?? 5,
            seconds: viewerGetAPIIntervalMilliSeconds ?? 5000,
          };
        }),
        switchMap(({ count, seconds }) =>
          getGroupCallInfoService(streamerID).pipe(
            concatMap(groupCallInfo =>
              concat(
                of(receiveGroupCallInfo({ groupCallInfo, roomID })),
                of(enterGroupCallSuccess({ roomID, streamerID }))
              )
            ),
            retryWhen(() => interval(seconds).pipe(take(count)))
          )
        )
      );
    }),
    catchError(() => of(setIsLiveStreamLoading(false)))
  );

export const receiveGroupCallMemberStatusEpic: Epic = action$ =>
  action$.pipe(
    filter(filterMessageType(MessageType.GROUP_CALL_MEMBER_STATUS)),
    map(({ payload }: { payload: GroupCallMemberStatusPayload }) => {
      return receiveGroupCallMemberStatus(payload);
    })
  );

export const receiveChangeRoomEpic: Epic = action$ =>
  action$.pipe(
    filter(filterMessageType(MessageType.CHANGE_ROOM)),
    mergeMap(({ payload }) => {
      const {
        message: {
          changeRoomMsg: { leavingStreamerInfo },
        },
      } = payload;

      if (leavingStreamerInfo) {
        // Group Call to normal stream
        return concat(
          of(clearGroupCallInfo(leavingStreamerInfo.roomID)),
          of(receiveChangeRoom(payload)),
          of(
            appendSystemComment(leavingStreamerInfo.roomID, {
              token: {
                key: 'group_stream_handover_left',
                params: [{ value: leavingStreamerInfo.displayName }],
              },
            })
          )
        );
      }
      return of(receiveChangeRoom(payload));
    })
  );

export const filterGroupCallEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    switchMap(({ payload }) => {
      const { roomID, streamerID } = payload;

      return timer(0).pipe(
        map(() => {
          const liveStream = liveStreamSelector(state$.value, { roomID });
          // Because liveStreamInfo api response is slow sometimes
          // so we will retry when the liveStream is not empty.
          if (!liveStream) {
            throw new Error('no live stream data');
          }
        }),
        // To filter liveStreamInfo if has GroupCallInfo
        filter(() => liveStreamIsGroupCallSelector(state$.value, { roomID })),
        map(() => enterGroupCallSuccess({ roomID, streamerID })),
        retryWhen(() => action$.pipe(ofType(ON_RECEIVED_LIVE_STREAM_INFO)))
      );
    })
  );

export const keepViewGroupCallEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(ENTER_GROUP_CALL_SUCCESS),
    mergeMap(({ payload: { roomID, streamerID } }) => {
      const region = makeSelectRegion()(state$.value);
      const loginUserID = makeSelectLoginUserID()(state$.value);
      const appRegion = makeSelectLoginUserRegion()(state$.value);

      return getConfigWithReactQuery(region, loginUserID, appRegion).pipe(
        map(config => {
          return {
            viewerGetAPIIntervalMilliSeconds:
              config?.addOns?.groupCall?.viewerGetAPIIntervalMilliSeconds ||
              KEEP_VIEW_POLLING_INTERVAL,
          };
        }),
        switchMap(({ viewerGetAPIIntervalMilliSeconds }) => {
          return timer(0, viewerGetAPIIntervalMilliSeconds).pipe(
            switchMap(() => getGroupCallInfoService(streamerID)),
            takeUntil(
              merge(
                action$.pipe(ofType(LEAVE_LIVE_STREAM)),
                action$.pipe(
                  ofType(RECEIVED_GROUP_CALL_INFO),
                  filter(({ payload: { errorCode } }) => !!errorCode)
                )
              )
            ),
            map(groupCallInfo => {
              return receiveGroupCallInfo({
                groupCallInfo,
                roomID,
              });
            }),
            catchError(() => of(setIsLiveStreamLoading(false)))
          );
        })
      );
    })
  );

export const receiveLuckyBagCountNotifyEpic: Epic = action$ =>
  action$.pipe(
    ofType(RECEIVE_GLOBAL_MESSAGE),
    filter(
      ({ payload: { message } }) =>
        message.type === MessageType.LUCKYBAG_COUNT_NOTIFY
    ),
    map(getLuckyBagCeilingInfo)
  );

/**
 * 收到 service worker cache 更新時，更新 redux 裡的 gifts
 */
export const updateGiftFromCacheEpic: Epic = action$ =>
  action$.pipe(
    ofType(ENTER_LIVE_STREAM_SUCCESS),
    mergeMap(() => {
      if ('serviceWorker' in navigator) {
        // eslint-disable-next-line compat/compat
        return fromEvent<MessageEvent>(navigator.serviceWorker, 'message').pipe(
          filter(event => event.data && event.data.type === 'CACHE_UPDATED'),
          filter(event => {
            return new RegExp('/api/v1/gifts$').test(event.data.url);
          }),
          withLatestFrom(action$.pipe(ofType(SET_GIFT_INFO))),
          mergeMap(async ([event]) => {
            const { url, cacheName } = event.data;
            try {
              const cache = await caches.open(cacheName);
              const cachedResponse = await cache.match(url);
              if (cachedResponse) {
                const data = await cachedResponse.json();

                return setGiftsOnly(data.gifts);
              }
            } catch (error) {
              console.error('Error fetching data from cache:', error);
            }
          }),
          takeUntil(action$.pipe(ofType(LEAVE_LIVE_STREAM)))
        );
      }
      return EMPTY;
    })
  );

export default [
  subscribeGlobalChannelEpic,
  initGlobalChannelEpic,
  receiveMarqueeEpic,

  unlockPremiumRoomEpic,
  onReceivedLiveStreamInfoEpic,
  keepViewLiveStreamEpic,
  getLiveStreamInfoEpic,
  getEnterLiveStreamInfoEpic,
  enterLiveStreamEpic,
  leaveLiveStreamEpic,
  endLiveStreamEpic,
  startToKeepViewStreamPollingEpic,
  keepViewStreamPollingEpic,
  leveaLiveStreamEpic,
  liveStreamNotAvailableEpic,
  updateGiftFromCacheEpic,

  // chatRoom
  subscribeChatRoomEpic,
  unsubscribeChatRoomEpic,
  onSwitchingMessageProviderEpic,
  onSwitchingGlobalMessageProviderEpic,
  receiveLuckyBagCountNotifyEpic,

  // streaming
  publishStreamEpic,
  endStreamEpic,
  keepStreamEpic,
  keepStreamPollingEpic,

  // group
  receiveGroupCallActionEpic,
  receiveGroupCallMemberStatusEpic,
  receiveGroupCallEpic,
  receiveChangeRoomEpic,
  filterGroupCallEpic,
  keepViewGroupCallEpic,
];
