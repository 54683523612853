import styled from 'styled-components';

import { COLOR_PRIMARY_BLACK } from '@17live/design-system/color';

const VLiveCharacterSectionTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  color: ${COLOR_PRIMARY_BLACK};
`;

export default VLiveCharacterSectionTitle;
