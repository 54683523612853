// @see https://github.com/17media/api/blob/c058d61da21a2cb00ff57f31d4bf96ef727b1e80/protos/models/marquee/marquee.proto#L17-L43
export enum FeatureType {
  DEFAULT = 0,
  SYSTEM = 1,
  OPERATION = 2,
  POKE_CHALLENGE_SUCCESS = 3,
  LEADERBOARD_DAILY_RANK = 4,
  LUCKYBAG = 5,
  RED_ENVELOP_NOTICE = 6,
  RED_ENVELOP_PROMOTE = 7,
  RED_ENVELOP_CUSTOM = 8,
  GIFT_SLOT = 9,
  SPORTS_CAR_STREAMER = 10,
  SPORTS_CAR_X50_PRIZE = 11,
  MARQUEE_COMMENT = 12,
  GIFT_PRODUCER = 13,
  GIFT_BARRAGE = 14,
  GIFT_MARQUEE = 15,
  POINT_ACHIEVEMENT = 16,
  MONSTER_BATTLE_SUCCESS = 17,
  SUPERSTAR = 18,
  GIFT_GOLDEN_BARRAGE = 19,
  GIFT_LUCKYDRAW = 20,
  LEAGUE_LIVE_CROWD = 21,
  ARCADE_BEAT_RANK_ONE = 22,
  BONUS_BOX_NOTICE = 23,
  GAME = 24,
}
