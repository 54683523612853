import {
  GET_GROUP_CALL_INFO,
  RECEIVED_GROUP_CALL_INFO,
} from '@17live/app/containers/GroupCall/GroupCall.constants';

export const getGroupCallInfo = ({ roomID, streamerID }) => ({
  type: GET_GROUP_CALL_INFO,
  payload: {
    roomID,
    streamerID,
  },
});

export const receiveGroupCallInfo = ({ groupCallInfo, roomID }) => ({
  type: RECEIVED_GROUP_CALL_INFO,
  payload: {
    groupCallInfo,
    roomID,
  },
});
