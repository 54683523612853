import { Epic, ofType } from 'redux-observable';

import { interval, of } from 'rxjs';
import {
  endWith,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {
  runCountdown,
  setCountdownStatus,
  setInitTimer,
  updateTimer,
} from '@17live/app/containers/Snack/Snack.actions';
import {
  ORIGINAL_DURATION_SEC,
  RUN_SNACK_COUNTDOWN,
  START_SNACK_COUNTDOWN,
  STOP_SNACK_COUNTDOWN,
} from '@17live/app/containers/Snack/Snack.constants';
import { CountdownStatus } from '@17live/app/enums/Snack.enums';

export const runCountdownEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RUN_SNACK_COUNTDOWN),
    map(() => setCountdownStatus(CountdownStatus.RUNNING)),
    switchMap(() => {
      const state = state$.value;
      const originalDurationSec = state.getIn(['snack', ORIGINAL_DURATION_SEC]);

      return interval(1000).pipe(
        take(originalDurationSec),
        map(sec => updateTimer(originalDurationSec - sec - 1)),
        startWith(setCountdownStatus(CountdownStatus.RUNNING)),
        endWith(setCountdownStatus(CountdownStatus.END)),
        takeUntil(action$.pipe(ofType(STOP_SNACK_COUNTDOWN)))
      );
    })
  );

export const stopCountdownEpic: Epic = action$ =>
  action$.pipe(
    ofType(STOP_SNACK_COUNTDOWN),
    switchMap(() =>
      of(setCountdownStatus(CountdownStatus.STOP), setInitTimer())
    )
  );

export const startCountdownEpic: Epic = action$ =>
  action$.pipe(
    ofType(START_SNACK_COUNTDOWN),
    switchMap(({ payload: { durationSec } }) =>
      of(setInitTimer(durationSec), runCountdown())
    )
  );

export default [runCountdownEpic, stopCountdownEpic, startCountdownEpic];
