import { Map } from 'immutable-v4';
import { createSelector } from 'reselect';

import { FOLLOW_STATUS } from '@/containers/FollowButton/constants';
import {
  LIVE_STREAM_TYPE,
  LOCAL_KEYS,
  VIEWING_STATUS,
} from '@/services/Video/constants';
import getOwnPropsParams from '@/utils/getOwnPropsParams';

import { BARRAGE_BUTTON_WHITELIST } from '@17live/app/constants/Barrage.constants';
import {
  LIVE_STREAM_TYPES,
  PREMIUM_TYPES,
} from '@17live/app/containers/LiveStream/constants';

/**
 * domain selector
 */
export const selectLiveStream = state => {
  return state.get('liveStream');
};

/**
 * selectors
 */
export const selectRoomID = (_, ownProps) =>
  getOwnPropsParams(ownProps, 'roomID');

export const selectUsers = state => state.get('users');

export const selectUserID = (_, ownProps) =>
  getOwnPropsParams(ownProps, 'userID');

export const selectUser = createSelector(
  selectUsers,
  selectUserID,
  (users, id) => users.get(id)
);

export const selectLiveStreamList = (domain, list) =>
  list.map(roomID => domain.get(roomID)).filter(Boolean);

export const selectLiveStreamType = liveStream => {
  if (!liveStream) {
    return LIVE_STREAM_TYPES.NONE;
  } else if (liveStream.has('triviaGame')) {
    return LIVE_STREAM_TYPES.TRIVIA;
  } else if (liveStream.has('gamingInfo')) {
    return LIVE_STREAM_TYPES.GAMING;
  }

  return LIVE_STREAM_TYPES.NORMAL;
};

export const selectLiveStreamDuration = liveStream =>
  liveStream &&
  Number(liveStream.get('endTime')) - Number(liveStream.get('beginTime'));

/**
 * selector makers
 */

export const makeSelectRoomID = () =>
  createSelector(selectRoomID, roomID => roomID);

export const makeSelectUserID = () =>
  createSelector(selectUserID, userID => userID);

export const makeSelectCurrentLiveStreamID = () =>
  createSelector(selectLiveStream, liveStream =>
    liveStream?.get('currentLiveStreamID')
  );

export const makeSelectStreamID = () =>
  createSelector(selectLiveStream, selectRoomID, (liveStream, roomID) => {
    return liveStream?.getIn([roomID, 'streamID']);
  });

export const makeSelectLiveStream = () =>
  createSelector(selectLiveStream, selectRoomID, (liveStream, roomID) => {
    const liveStreamInfo = liveStream.get(roomID);

    // ensure that the liveStreamInfo has essential data available (there might be a better option?)
    return (liveStreamInfo &&
      liveStreamInfo.get('roomID') &&
      // 檢查 `status` 欄位是想排除 profile 頁會存入 userInfo 的 liveStreamInfo，
      // 因為從 getLiveStreamInfo API 來的話一定有 status
      // See `getUserInfoEpic` in UserProvider/epics
      // status may be `0` (see `LIVE_STREAM_STATUS`), so check if not undefined
      liveStreamInfo.get('status')) !== undefined
      ? liveStreamInfo
      : null;
  });

const liveStreamSelector = makeSelectLiveStream();

export const makeSelectIsFollowing = () =>
  createSelector(
    liveStreamSelector,
    state => state.getIn(['login', 'following']),
    (liveStream, followingList) => {
      return (
        followingList.get(liveStream?.get('userID')) === FOLLOW_STATUS.FOLLOWING
      );
    }
  );
export const makeSelectLiveStreamFollowerOnlyCommentRemainingMins = () =>
  createSelector(liveStreamSelector, liveStream =>
    liveStream?.get('followerOnlyCommentRemainingMins')
  );

export const makeSelectLiveStreamIsConcert = () =>
  createSelector(
    liveStreamSelector,
    liveStream => liveStream?.get('streamerType') === LIVE_STREAM_TYPE.CONCERT
  );

export const makeSelectLiveStreamIsLandscape = () =>
  createSelector(
    liveStreamSelector,
    liveStream => !!(liveStream && liveStream.get('landscape'))
  );

export const makeSelectUserObject = () =>
  createSelector(
    selectUsers,
    selectLiveStream,
    selectRoomID,
    selectUserID,
    (users, liveStream, roomID, userID) => {
      let userKey = userID;

      if (!userKey) {
        userKey =
          // yes they exists in both places
          liveStream.getIn([String(roomID), 'userID']) ||
          liveStream.getIn([String(roomID), 'userInfo', 'userID']);
      }

      if (users) {
        return users.get(userKey);
      }

      if (liveStream) {
        return liveStream.getIn([String(roomID), 'userInfo']);
      }

      return Map();
    }
  );

const userSelector = makeSelectUserObject();

export const makeSelectLiveStreamIsOnline = () =>
  createSelector(liveStreamSelector, userSelector, (liveStream, user) => {
    return !!liveStream?.get('isOnline') || user?.get('isOnline');
  });

export const makeSelectLiveStreamEventList = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.get('eventList');
  });

export const makeSelectLiveStreamViewingStatus = roomID =>
  roomID
    ? createSelector(selectLiveStream, liveStream => {
        return liveStream?.getIn([roomID, LOCAL_KEYS.VIEWING_STATUS]);
      })
    : createSelector(
        selectLiveStream,
        selectRoomID,
        (liveStream, roomIDFromSelector) => {
          return liveStream?.getIn([
            roomIDFromSelector,
            LOCAL_KEYS.VIEWING_STATUS,
          ]);
        }
      );

const liveStreamViewingStatusSelector = makeSelectLiveStreamViewingStatus();

export const makeSelectLiveStreamIsBlocked = () =>
  createSelector(
    liveStreamViewingStatusSelector,
    viewingStatus => viewingStatus === VIEWING_STATUS.BLOCKED
  );

export const makeSelectLiveStreamIsLoaded = () =>
  createSelector(
    liveStreamViewingStatusSelector,
    viewingStatus => viewingStatus > VIEWING_STATUS.NONE
  );

export const makeSelectLiveStreamIsAvailable = () =>
  createSelector(
    liveStreamViewingStatusSelector,
    viewingStatus => viewingStatus === VIEWING_STATUS.AVAILABLE
  );

export const makeSelectLiveStreamRegion = () =>
  createSelector(
    liveStreamSelector,
    liveStream => liveStream && liveStream.get('region')
  );

export const makeSelectBarrageMarqueeInfos = () =>
  createSelector(selectLiveStream, liveStream => {
    return liveStream?.get('marquees');
  });

export const makeSelectBarrageInfos = () =>
  createSelector(selectLiveStream, liveStream => {
    return liveStream
      ?.get('barrageInfos')
      ?.filter(barrage =>
        BARRAGE_BUTTON_WHITELIST.includes(barrage.get('type'))
      );
  });

export const makeSelectBarrageError = () =>
  createSelector(selectLiveStream, liveStream => {
    return liveStream?.get('barrageError');
  });

export const makeSelectProgramInfoOfLiveStream = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.getIn(['userInfo', 'programInfo']);
  });

export const makeSelectFollowerComment = () =>
  createSelector(liveStreamSelector, liveStream => {
    const followAt = liveStream?.get('followAt');
    const followerOnlyCommentMins = liveStream?.get('followerOnlyCommentMins');

    const isFollowerOnlyMode = followerOnlyCommentMins > -1;
    const isFollowing = followAt > -1;

    const followerOnlyCommentRemainingMins = liveStream?.get(
      'followerOnlyCommentRemainingMins'
    );

    const canLeaveComment = (() => {
      if (!isFollowerOnlyMode) return true;
      if (!isFollowing && followerOnlyCommentRemainingMins !== 0) return false;

      const nowDate = new Date();
      const followDate = new Date(followAt * 1000);

      followDate.setMinutes(followDate.getMinutes() + followerOnlyCommentMins);

      return nowDate.getTime() - followDate.getTime() >= 0;
    })();

    return {
      followerOnlyCommentRemainingMins,
      canLeaveComment,
      isFollowerOnlyMode,
      isFollowing,
      followAt,
      followerOnlyCommentMins,
    };
  });
export const makeSelectCommentMaxInputLength = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.getIn(['commentInfo', 'maxInputLength']);
  });

export const makeSelectLiveStreamDefaultComments = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream && liveStream.get('defaultComments');
  });

export const makeSelectLiveStreamUserDisplayName = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.getIn(['userInfo', 'displayName']);
  });

export const makeSelectLiveStreamUserID = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.getIn(['userInfo', 'userID']);
  });

export const makeSelectLiveStreamType = () =>
  createSelector(liveStreamSelector, selectLiveStreamType);

export const makeSelectLiveStreamDuration = () =>
  createSelector(liveStreamSelector, selectLiveStreamDuration);

export const makeSelectIsTrivia = () =>
  createSelector(
    liveStreamSelector,
    liveStream => !!(liveStream && liveStream.get('triviaGame'))
  );

export const makeSelectLiveStreamTriviaExtraInfo = () =>
  createSelector(
    liveStreamSelector,
    liveStream => liveStream && liveStream.get('triviaExtraInfo')
  );

export const liveStreamPremiumSelector = liveStream => {
  if (liveStream) {
    const premiumType = liveStream.getIn(['premiumContent', 'premiumType']);
    const isPremium = premiumType === PREMIUM_TYPES.PAID;
    const isArmy = premiumType === PREMIUM_TYPES.ARMY;
    const isNewUser = premiumType === PREMIUM_TYPES.NEW_USER;
    const isUserPaid = !!liveStream.getIn([
      'premiumContent',
      'paymentInfo',
      'paid',
    ]);
    const isLocked = !!premiumType && !isUserPaid;

    return {
      previewURL: liveStream.getIn(['premiumContent', 'previewURL']),
      premiumType,
      isPremium,
      isArmy,
      isNewUser,
      isUserPaid,
      isLocked,
    };
  }

  return {
    previewURL: null,
    premiumType: null,
    isPremium: false,
    isArmy: false,
    isUserPaid: false,
    isLocked: false,
  };
};

export const makeSelectLiveStreamPremium = (roomID = '') =>
  roomID
    ? createSelector(selectLiveStream, liveStreamWithRoom =>
        liveStreamPremiumSelector(liveStreamWithRoom.get(roomID))
      )
    : createSelector(liveStreamSelector, liveStreamPremiumSelector);

export const makeSelectLiveStreamCustomEvent = () =>
  createSelector(liveStreamSelector, liveStream =>
    liveStream?.get('customEvent')
  );

export const makeSelectLiveStreamLoading = () =>
  createSelector(selectLiveStream, liveStream => liveStream?.get('isLoading'));

export const makeSelectLiveStreamIsGroupCall = () =>
  createSelector(
    liveStreamSelector,
    liveStream => !!(liveStream && liveStream.get('groupCallInfo'))
  );

export const makeSelectLiveStreamGroupCallInfo = () =>
  createSelector(liveStreamSelector, liveStream =>
    liveStream?.get('groupCallInfo')?.toJS()
  );

export const makeSelectLiveStreamGroupCallMembers = () =>
  createSelector(
    liveStreamSelector,
    liveStream => liveStream?.getIn(['groupCallInfo', 'members'])?.toJS() || []
  );

export const makeSelectLiveStreamIsRoomIDAliveCalled = roomID =>
  createSelector(selectLiveStream, liveStream => {
    return liveStream.getIn(['isRoomIDAliveCalledMapping', `${roomID}`]);
  });

export const makeSelectLiveStreamIsEntered = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream && liveStream.get('isEntered', false);
  });

export const makeSelectorGameCenterSetting = () =>
  createSelector(liveStreamSelector, liveStream => {
    return liveStream?.get('gameCenterSetting');
  });
