/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 *
 */

import React, { Fragment, PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { changeLang } from './actions';
import {
  makeSelectLang,
  makeSelectMessages,
  makeSelectRegion,
} from './selectors';

type Props = {
  messages: { [key: string]: { [key: string]: string } };
  changeLang: (locale: string) => void;
  lang: string;
  region: string;
};

export class LanguageProvider extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    props.changeLang(props.lang);
  }

  render() {
    const { messages, children, lang, region } = this.props;

    const htmlAttributes = {
      lang,
    };

    if (!messages[lang]) {
      return null;
    }

    return (
      <IntlProvider
        key={`${lang}-${region}`}
        locale={lang}
        messages={messages[lang]}
      >
        <Fragment>
          <Helmet htmlAttributes={htmlAttributes} />
          {React.Children.only(children)}
        </Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  messages: makeSelectMessages(),
  lang: makeSelectLang(),
  region: makeSelectRegion(),
});

const mapDispatchToProps = dispatch => ({
  changeLang: lang => dispatch(changeLang(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
