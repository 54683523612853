import React from 'react';

import LoadingSVG from '@/components/LoadingSVG';

import AIAssistantIntroductionInteractiveGameOff from '@17live/app/components/AIAssistant/AIAssistantIntroductionInteractiveGameOff';
import AIAssistantIntroductionInteractiveGameOn from '@17live/app/components/AIAssistant/AIAssistantIntroductionInteractiveGameOn';
import { useHasAiAssistantGameFeature } from '@17live/app/hooks/useHasConfigFeatures';

const AIAssistantIntroduction = () => {
  const {
    data: hasAiAssistantGameFeatureConfig,
    isLoading: isAiAssistantGameConfigLoading,
  } = useHasAiAssistantGameFeature();

  if (isAiAssistantGameConfigLoading) {
    return <LoadingSVG />;
  }

  if (hasAiAssistantGameFeatureConfig) {
    return <AIAssistantIntroductionInteractiveGameOn />;
  }

  return <AIAssistantIntroductionInteractiveGameOff />;
};

export default AIAssistantIntroduction;
