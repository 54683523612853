import {
  differenceInDays,
  differenceInMinutes,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
} from 'date-fns';

import Logger from '@17live/app/services/Logger';
import { ConcertInfo } from '@17live/app/types/LiveStreams';

import { CONCERT_PREPARE_MINUTES, ConcertStatus } from './Concert.constants';

export const getIsConcertNear = (currentDate: Date, concertStartDate: Date) => {
  if (isValid(currentDate) && isValid(concertStartDate)) {
    return differenceInDays(currentDate, concertStartDate) === 0;
  }
  Logger.error('Concert', 'getIsConcertNear date is not set', {
    currentDate,
    concertStartDate,
  });
  return false;
};

export const getIsConcertPrepare = (
  currentDate: Date,
  concertStartDate: Date
) => {
  if (isValid(currentDate) && isValid(concertStartDate)) {
    const diffMinutes = differenceInMinutes(currentDate, concertStartDate);

    return diffMinutes < 0 && diffMinutes > -CONCERT_PREPARE_MINUTES;
  }
  Logger.error('Concert', 'getIsConcertPrepare date is not set', {
    currentDate,
    concertStartDate,
  });
  return false;
};

export const getIsVODStart = (currentDate: Date, vodStartDate: Date) => {
  if (isValid(currentDate) && isValid(vodStartDate)) {
    return isAfter(currentDate, vodStartDate);
  }
  Logger.error('Concert', 'getIsVODStart date is not set', {
    currentDate,
    vodStartDate,
  });
  return false;
};

export const getIsVODEnd = (currentDate: Date, vodEndDate: Date) => {
  if (isValid(currentDate) && isValid(vodEndDate)) {
    return isAfter(currentDate, vodEndDate);
  }
  Logger.error('Concert', 'getIsVODEnd date is not set', {
    currentDate,
    vodEndDate,
  });
  return false;
};

export const getIsVODCanReplay = (currentDate: Date, vodEndDate: Date) => {
  if (isValid(currentDate) && isValid(vodEndDate)) {
    return isBefore(currentDate, vodEndDate);
  }
  Logger.error('Concert', 'getIsVODCanReplay date is not set', {
    currentDate,
    vodEndDate,
  });
  return false;
};

export const getIsVODAvailable = (
  currentDate: Date,
  vodStartDate: Date,
  vodEndDate: Date
) => {
  if (isValid(currentDate) && isValid(vodStartDate) && isValid(vodEndDate)) {
    try {
      return isWithinInterval(currentDate, {
        start: vodStartDate,
        end: vodEndDate,
      });
    } catch (error) {
      Logger.error('Concert', 'Verify getIsVODAvailable date is failed', {
        error,
        currentDate,
        vodStartDate,
        vodEndDate,
      });
      return false;
    }
  }
  Logger.error('Concert', 'getIsVODAvailable date is not set', {
    currentDate,
    vodStartDate,
    vodEndDate,
  });
  return false;
};

export const getIsVODWaiting = (
  currentDate: Date,
  concertStartDate: Date,
  vodStartDate: Date
) => {
  if (
    isValid(currentDate) &&
    isValid(concertStartDate) &&
    isValid(vodStartDate)
  ) {
    try {
      return isWithinInterval(currentDate, {
        start: concertStartDate,
        end: vodStartDate,
      });
    } catch (error) {
      Logger.error('Concert', 'Verify getIsVODWaiting date is failed', {
        error,
        currentDate,
        concertStartDate,
        vodStartDate,
      });
      return false;
    }
  }
  Logger.error('Concert', 'getIsVODWaiting date is not set', {
    currentDate,
    concertStartDate,
    vodStartDate,
  });
  return false;
};

export const getIsConcertFromOnelink = () => {
  const url = new URL(location.href);
  const oneLinkCampaignID = url.searchParams.get('af_c_id');

  return oneLinkCampaignID === 'concert';
};

export const getConcertStatus = (concertInfo: ConcertInfo): ConcertStatus => {
  const {
    startTimestamp,
    vodStartTimestamp,
    vodEndTimestamp,
    isLive,
  } = concertInfo;

  const currentDate = new Date();
  const concertStartDate = new Date(startTimestamp * 1000);
  const vodStartDate = new Date(vodStartTimestamp * 1000);
  const vodEndDate = new Date(vodEndTimestamp * 1000);

  const isConcertPrepare = getIsConcertPrepare(currentDate, concertStartDate);
  const isVODWaiting = getIsVODWaiting(
    currentDate,
    concertStartDate,
    vodStartDate
  );
  const isVODAvailable = getIsVODAvailable(
    currentDate,
    vodStartDate,
    vodEndDate
  );
  const isVODEnd = getIsVODEnd(currentDate, vodEndDate);

  if (isLive) {
    return ConcertStatus.ON_LIVE;
  }

  if (isConcertPrepare) {
    return ConcertStatus.PREPARE;
  }

  if (isVODWaiting) {
    return ConcertStatus.VOD_WAITING;
  }

  if (isVODAvailable) {
    return ConcertStatus.VOD_AVAILABLE;
  }

  if (isVODEnd) {
    return ConcertStatus.VOD_END;
  }

  return ConcertStatus.OFF_LIVE;
};

/**
 * @name normalizeExpiredTime
 * @description API 回來的 expiredTime 可能會出現 'nil', '-171717', 所以 client 做驗證擋下來
 * @param expiredTime
 * @returns
 */
export const normalizeExpiredTime = (expiredTime: string): number =>
  ['nil', '-171717'].includes(expiredTime) ? 0 : Number(expiredTime);
