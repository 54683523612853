/*
 *
 * Loyalty Point constants
 *
 */

export const CHANGE_LOYALTY_POINT_ACTIVE_TAB =
  'app/LoyaltyPoint/CHANGE_LOYALTY_POINT_ACTIVE_TAB';
export const GET_LOYALTY_POINT_HISTORY =
  'app/LoyaltyPoint/GET_LOYALTY_POINT_HISTORY';
export const SET_LOYALTY_POINT_HISTORY =
  'app/LoyaltyPoint/SET_LOYALTY_POINT_HISTORY';

export const LOYALTY_POINT_HISTORY = 'loyaltyPointHistory';

// Jerry TODO: using "as const" syntax after upgrade typescript version
// ex. const TABS_LIST = [LOYALTY_POINT_HISTORY] as const
export const TABS_LIST = [LOYALTY_POINT_HISTORY] as [
  typeof LOYALTY_POINT_HISTORY
];

export const DEFAULT_COUNT: number = 20;
