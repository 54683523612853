import DevTools from '@/services/DevTools';
import { LocalStorage } from '@/services/Storage';
import { KEY_LOGIN_INFO } from '@/services/Storage/constants';

import {
  BRAZE_API_KEY,
  BRAZE_BASE_URL,
  BRAZE_DEFAULT_EVENT_PROPERTY,
  BRAZE_EVENT_PROPERTY,
} from '@17live/app/services/Braze/constants';
import { isUserInMainRegionForBraze } from '@17live/app/services/Braze/utils';
import Logger from '@17live/app/services/Logger';

import * as brazeWebSDK from '@braze/web-sdk';

const LOGGER_LABEL = '[Braze]';

/**
 * @name initBraze
 * @see https://drive.google.com/file/d/17oA7rKqMMRKI755QLrwMS8SEB7nitWGZ/view?usp=sharing
 */
export const initBraze = () => {
  const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO);

  if (!loginInfo) {
    // Don't init SDK if user is guest
    brazeWebSDK.disableSDK();
    return;
  }

  /**
   * Why disableSDK and initSDK
   * brazeWebSDK.disableSDK 這邊只需要關閉 SDK 的功能，
   * 例如 send custom event，但是仍然需要 initSDK 以用來追蹤 userID
   */
  if (isUserInMainRegionForBraze()) {
    if (
      process.env.DEPLOY_ENV === 'production' ||
      DevTools.isBrazeCustomEventsCanUseInStage()
    ) {
      brazeWebSDK.enableSDK();
    } else {
      // Disabled SDK when we use stage key and DevTool not enable.
      brazeWebSDK.disableSDK();
    }
  } else {
    // Disabled SDK when user not in main region.
    brazeWebSDK.disableSDK();
  }

  try {
    brazeWebSDK.initialize(BRAZE_API_KEY, {
      // If you provide a value for this option, user events sent to Braze will be associated with the given version,
      // which can be used for user segmentation.
      appVersion: process.env.VERSION || 'development',
      // This option is required to configure the Braze Web SDK to use the appropriate endpoint for your integration
      baseUrl: BRAZE_BASE_URL,
      // Set to true to enable logging by default. Note that this will cause Braze to log to the javascript console,
      // which is visible to all users! You should probably remove this
      // or provide an alternate logger with appboy.setLogger before you release your page to production.
      enableLogging: process.env.DEPLOY_ENV !== 'production',
      // By default, appboy.registerAppboyPushMessages/appboy.unregisterAppboyPushMessages assume
      // that they control and can register and unregister the site's service worker.
      // If you have your own service worker that you register and control the lifecycle of,
      // set this option to true and the Braze SDK will not register or unregister a service worker.
      // If you set this option to true, in order for push to function correctly
      // you must register the service worker yourself BEFORE calling appboy.registerAppboyPushMessages,
      // and ensure that it contains Braze's service worker code,
      // either with self.importScripts('https://js.appboycdn.com/web-sdk-develop/4.0/service-worker.js');
      // or by including the content of that file directly. When this option is true,
      // the serviceWorkerLocation option is irrelevant and is ignored.
      manageServiceWorkerExternally: true,
      // By default, when registering users for web push notifications Braze will look for the required service worker file
      // in the root directory of your web server at /service-worker.js.
      // If you want to host your service worker at a different path on that server,
      // provide a value for this option that is the absolute path to the file, e.g. /mycustompath/my-worker.js.
      // VERY IMPORTANT: setting a value here limits the scope of push notifications on your site.
      // For instance, in the above example,
      // because the service worker file is located within the /mycustompath/ directory,
      // requestPushPermission MAY ONLY BE CALLED from web pages that start with http://yoursite.com/mycustompath/.
      serviceWorkerLocation: '/sw.js',
    });

    // Optionally show all in-app messages without custom handling
    brazeWebSDK.automaticallyShowInAppMessages();

    // For a browser to receive push notifications, This will immediately request push permission from the user.
    brazeWebSDK.requestPushPermission();

    // Start (or continue) a session
    brazeWebSDK.openSession();
  } catch (error) {
    Logger.error(LOGGER_LABEL, 'initBraze failed', error);
  }
};

export const identifyBrazeUser = (userID = null) => {
  if (userID) {
    if (brazeWebSDK.isDisabled()) {
      initBraze();
    }

    try {
      // Optionally set the current user's External ID
      brazeWebSDK.changeUser(userID);
    } catch (error) {
      Logger.error(LOGGER_LABEL, 'changeUser failed', error);
    }
  } else {
    try {
      brazeWebSDK.destroy();
    } catch (error) {
      Logger.error(LOGGER_LABEL, 'destroy failed', error);
    } finally {
      brazeWebSDK.disableSDK();
    }
  }
};

/**
 * @name trackBrazeCustomEvents
 * @description send custom events to braze
 * @see https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/tracking_custom_events
 */
export const trackBrazeCustomEvents = (eventName, customDimension = {}) => {
  if (brazeWebSDK.isDisabled()) {
    return;
  }

  const dimension = {
    [BRAZE_EVENT_PROPERTY.timestamp]: new Date().getTime(),
    [BRAZE_EVENT_PROPERTY.platform]:
      BRAZE_DEFAULT_EVENT_PROPERTY[BRAZE_EVENT_PROPERTY.platform],
    ...customDimension,
  };

  console.debug('[Braze] Log Custom Event:', eventName, dimension);

  try {
    brazeWebSDK.logCustomEvent(eventName, dimension);
  } catch (error) {
    Logger.error(LOGGER_LABEL, 'logCustomEvent failed', error);
  }
};

/**
 * @name setBrazeCustomAttribute
 * @description send custom attributes to braze
 * @see https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/setting_custom_attributes/
 */
export const setBrazeCustomAttribute = (key, value) => {
  if (brazeWebSDK.isDisabled()) {
    return;
  }

  try {
    brazeWebSDK.getUser()?.setCustomUserAttribute(key, value);
  } catch (error) {
    Logger.error(LOGGER_LABEL, 'getUser failed', error);
  }
};
