/**
 * Refer to backend
 * ref: https://github.com/17media/api/blob/66b387e1033f0bdd4a021e847cbbd1ac785cc9ca/models/barrage/barrage.go#L75-L86
 */
export enum BarrageType {
  Baby = 0,
  Normal = 1,
  Rocket = 2,
  Spaceship = 3,
  Assistant = 4,
  System = 5,
  Purple = 6,
  BlueBerry = 7,
  BerryShip = 8,
  BerryRocket = 9,
}
