import { useEffect } from 'react';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';
import { updatePreference } from '@17live/app/utils';

export const useLocaleChange = () => {
  const { lang, region } = useLocale();

  useEffect(() => {
    updatePreference('lang', lang);
  }, [lang]);

  useEffect(() => {
    updatePreference('region', region);
  }, [region]);
};
