import React, { memo, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Helmet from '@/components/Helmet';
import ResponsiveWindowSize from '@/components/ResponsiveWindowSize';
import ServiceTermsModalLoadable from '@/containers/ServiceTermsModal/components/Loadable';
import { trackPageView } from '@/services/Analytics';

import PerimeterX from '@17live/app/components/PerimeterX/PerimeterX';
import { LANG_ROUTE } from '@17live/app/constants/langs';
import { useLocaleChange } from '@17live/app/containers/App/hooks';
import { useBraze } from '@17live/app/containers/App/hooks/useBraze';
import MobileBlockLoadable from '@17live/app/containers/MobileBlock/MobileBlockLoadable';
import { useTrackEventAttrs } from '@17live/app/services/analytics';
import { getPathWithoutLang } from '@17live/app/utils';
import { DriverProvider } from '@17live/ui/Driver';
import { ModalProvider } from '@17live/ui/Modal';

import * as Sentry from '@sentry/react';
import AppRoute from './components/AppRoute';
import AppWrapper from './components/AppWrapper';

type Props = {};

const App: React.FC<Props> = () => {
  const location = useLocation();

  useLocaleChange();
  useTrackEventAttrs();
  useBraze();

  const pathWithoutLang = useMemo(() => getPathWithoutLang(location.pathname), [
    location.pathname,
  ]);

  useEffect(() => {
    // tracking in page layout for getting API response.
    if (
      !/^\/(profile|live|external)\//.test(pathWithoutLang) ||
      /^\/profile\/.+\/(post|clip|video)/.test(pathWithoutLang)
    ) {
      trackPageView();
    }
  }, [location.key, pathWithoutLang]);

  return (
    <AppWrapper>
      <Helmet />
      <ResponsiveWindowSize />
      <MobileBlockLoadable />
      <PerimeterX />
      <DriverProvider>
        <ModalProvider defaultProps={{}}>
          <Switch>
            <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
            <Route path={LANG_ROUTE} component={AppRoute} />
            <Route component={AppRoute} />
          </Switch>
        </ModalProvider>
      </DriverProvider>
      <ServiceTermsModalLoadable />
    </AppWrapper>
  );
};

export default Sentry.withProfiler(memo(App));
