// @flow
import React from 'react';
import LoadingSVG from '@/components/LoadingSVG';

import ModalBody from './ModalBody';

/**
 * @deprecated
 * Please use GlobalModal/ModalLoading instead.
 */
const ModalLoading = () => (
  // don't apply id to loading component yet
  <ModalBody id={undefined}>
    <LoadingSVG />
  </ModalBody>
);

export default ModalLoading;
