import { ofType } from 'redux-observable';

import { replace } from 'connected-react-router';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import omit from 'lodash/omit';
import { asyncScheduler, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  observeOn,
  switchMap,
  tap,
} from 'rxjs/operators';

import { FOLLOW_STATUS } from '@/containers/FollowButton/constants';
import { openModal } from '@/containers/Modal/actions';
import { MODAL_LOGIN, MODAL_REGISTER } from '@/containers/Modal/constants';
import { getBalances, getFollowStatus } from '@/services/17App';
import { getSelfInfo, loginAction } from '@/services/17App/auth';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  identifyUser,
  pageView,
  trackEvent,
  trackPageView,
} from '@/services/Analytics';
import {
  KEY_EVENTS,
  KEY_LOGIN_INFO,
  LocalStorage,
  REMOVE_WHEN_LOGOUT_KEYS,
  SessionStorage,
} from '@/services/Storage';
import { errorAction } from '@/utils/errorAction';

import { INIT_APP } from '@17live/app/containers/App/constants';
import { SET_SENT_RESPONSE } from '@17live/app/containers/GiftBox/constants';
import { SET_LOCALE } from '@17live/app/containers/LanguageProvider/constants';
import { getPreference } from '@17live/app/containers/PreferenceProvider/actions';
import { SET_RED_ENVELOPE_RESPONSE } from '@17live/app/containers/RedEnvelope/constants';
import { pushSnackbar } from '@17live/app/containers/Snackbars/actions';
import { getPathWithoutLang } from '@17live/app/utils';

import {
  loginAdditional,
  loginFailed,
  loginSuccess,
  logoutSuccess,
  setBalances,
  setFollowing,
  updateLoginState,
} from './actions';
import {
  GET_BALANCES,
  GET_FOLLOW_STATUS,
  ID_PASSWORD_LOGIN,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_THEN,
  LOGOUT,
  LOGOUT_SUCCESS,
  REGISTER_THEN,
} from './constants';
import { isUser, withLoginThen } from './utils';

export const processLoginResponse = ({
  result,
  message,
  userInfo,
  registerType,
  jwtAccessToken,
  refreshToken,
}) => {
  if (result !== 'success') {
    return of(loginFailed({ message }, registerType));
  }

  const loginInfo = { ...userInfo, jwtAccessToken, refreshToken };

  LocalStorage.setItem(KEY_LOGIN_INFO, loginInfo);
  identifyUser(loginInfo.userID);

  if (registerType) {
    trackEvent(EVENT_CATEGORIES.REGISTER, EVENT_ACTIONS.REGISTER, registerType);
  }

  return of(
    loginSuccess(loginInfo),
    pushSnackbar(!registerType ? 'haveLoggedIn' : 'haveRegisteredSuccessfully')
  );
};

export const loginEpic = action$ =>
  action$.pipe(
    ofType(LOGIN),
    mergeMap(({ payload: { openID, password } }) =>
      loginAction({ openID, password }).pipe(
        concatMap(processLoginResponse),
        catchError(err => loginFailed(err, ID_PASSWORD_LOGIN))
      )
    )
  );

export const logoutEpic = action$ =>
  action$.pipe(
    ofType(LOGOUT),
    observeOn(asyncScheduler),
    tap(() =>
      REMOVE_WHEN_LOGOUT_KEYS.forEach(key => LocalStorage.removeItem(key))
    ),
    tap(() => identifyUser(null)),
    concatMap(({ payload }) =>
      of(logoutSuccess(payload), pushSnackbar('haveLoggedOut'))
    )
  );

export const logoutSuccessEpic = action$ =>
  action$.pipe(
    ofType(LOGOUT_SUCCESS),
    filter(({ payload }) => !!payload),
    map(({ payload }) => replace(payload))
  );

export const initLoginEpic = action$ =>
  action$.pipe(
    ofType(INIT_APP),
    map(() => LocalStorage.getItem(KEY_LOGIN_INFO)),
    // do not get cache `unreadTerm` from localStorage, always get from network
    map(loginInfo => loginInfo && omit(loginInfo, 'unreadTerm')),
    tap(loginInfo => identifyUser(loginInfo && loginInfo.userID)),
    tap(
      () =>
        pageView.shouldAutoTrackUrlChange(getPathWithoutLang()) &&
        pageView.send() &&
        trackPageView()
    ),
    map(loginInfo => (loginInfo && loginSuccess(loginInfo)) || logoutSuccess())
  );

export const getAdditionalLoginInfoEpic = action$ =>
  action$.pipe(
    // also re-fetch user self info when user switched language
    ofType(LOGIN_SUCCESS, SET_LOCALE),
    filter(() => isUser()),
    mergeMap(() =>
      getSelfInfo().pipe(
        filter(loginInfo => !!loginInfo),
        map(newLoginInfo => {
          const loginInfo = LocalStorage.getItem(KEY_LOGIN_INFO) ?? {};
          const decoded = jwt_decode(loginInfo.jwtAccessToken) ?? {};

          return {
            ...loginInfo,
            ...newLoginInfo,
            jwtAccessTokenExpiredTime: decoded.exp ?? '',
          };
        }),
        tap(additionalLoginInfo =>
          LocalStorage.setItem(KEY_LOGIN_INFO, additionalLoginInfo)
        ),
        map(additionalLoginInfo => loginAdditional(additionalLoginInfo)),
        catchError(err => of(errorAction(LOGIN_SUCCESS, err)))
      )
    )
  );

export const getFollowStatusEpic = action$ =>
  action$.pipe(
    ofType(GET_FOLLOW_STATUS),
    filter(() => isUser()),
    mergeMap(({ payload: { targetUserIDs } }) =>
      getFollowStatus(
        LocalStorage.getItem(KEY_LOGIN_INFO).userID,
        targetUserIDs
      ).pipe(
        map(followStatusList =>
          followStatusList
            .map((followStatus, index) => ({
              userID: targetUserIDs[index],
              ...followStatus,
            }))
            .filter(({ status }) => status === FOLLOW_STATUS.FOLLOWING)
        ),
        map(following => setFollowing(following)),
        catchError(err => of(errorAction(GET_FOLLOW_STATUS, err)))
      )
    )
  );

export const getBalancesEpic = action$ =>
  action$.pipe(
    ofType(GET_BALANCES, SET_SENT_RESPONSE, SET_RED_ENVELOPE_RESPONSE),
    switchMap(() =>
      getBalances().pipe(
        map(balances => setBalances(balances)),
        catchError(err => of(errorAction(GET_BALANCES, err)))
      )
    )
  );

export const loginThenEpic = withLoginThen(
  LOGIN_THEN,
  ({ additionalOptions }) => openModal({ name: MODAL_LOGIN, additionalOptions })
);

export const registerThenEpic = withLoginThen(
  REGISTER_THEN,
  ({ additionalOptions }) =>
    openModal({ name: MODAL_REGISTER, additionalOptions })
);

export const loginSuccessEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_SUCCESS),
    // we assume that when user has logged in in the same session has the same meaning of opening the login modal
    tap(({ payload: loginInfo }) => {
      LocalStorage.setItem(KEY_LOGIN_INFO, loginInfo);

      SessionStorage.updateItem(KEY_EVENTS, {
        hasOpenLoginOrRegisterModal: true,
      });
    }),
    concatMap(({ payload: loginInfo }) =>
      of(updateLoginState(loginInfo), getPreference())
    )
  );

export default [
  loginEpic,
  logoutEpic,
  logoutSuccessEpic,
  initLoginEpic,
  getAdditionalLoginInfoEpic,
  getFollowStatusEpic,
  getBalancesEpic,
  loginThenEpic,
  registerThenEpic,
  loginSuccessEpic,
];
