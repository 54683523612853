// @flow
import React, { Node, useMemo } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import type { IntlShape, MessageDescriptor } from 'react-intl';
import { injectIntl } from 'react-intl';

import { useLocale } from '@17live/app/containers/LanguageProvider/hooks';
import { getPathWithoutLang } from '@17live/app/utils';

import DeepLink from './DeepLink';
import Description from './Description';
import Image from './Image';
import Keywords from './Keywords';
import messages from './messages';
import SiteName from './SiteName';
import Title from './Title';
import type { Props as TypeProps } from './Type';
import Type from './Type';

type Props = TypeProps & {
  intl: IntlShape,
  title?: string | MessageDescriptor,
  titleTemplate?: string | MessageDescriptor,
  ogTitle?: string | MessageDescriptor,
  twitterTitle?: string | MessageDescriptor,
  description?: string | MessageDescriptor,
  ogDescription?: string | MessageDescriptor,
  twitterDescription?: string | MessageDescriptor,
  picture?: string,
  twitterPicture?: string,
  deepLinkURL?: string,
  values?: { [string]: * },
  children?: Node,
  region?: ?string,
  siteName?: string,
  keywords?: string,
};

const Helmet = ({
  intl,
  title,
  titleTemplate,
  ogTitle,
  twitterTitle,
  description,
  ogDescription,
  twitterDescription,
  picture,
  twitterPicture,
  deepLinkURL,
  children,
  values,
  type,
  region,
  siteName,
  keywords,
  ...props
}: Props) => {
  // accepts simple string or react-intl config
  const formatMessage = (msg: string | MessageDescriptor): string =>
    typeof msg === 'string' ? msg : intl.formatMessage(msg, values);

  const { lang } = useLocale();
  const host = useMemo(() => location.origin, []);
  const pathWithoutLang = useMemo(() => {
    const url = new URL(
      `${location.origin}${getPathWithoutLang()}${location.search}${
        location.hash
      }`
    );
    url.searchParams.delete('lang');

    return `${url.pathname}${url.search}${url.hash}`;
  }, []);
  const fullURL = useMemo(() => `${host}/${lang}${pathWithoutLang}`, [
    host,
    lang,
    pathWithoutLang,
  ]);

  return (
    <>
      <Title
        titleTemplate={formatMessage(titleTemplate || messages.titleTemplate)}
        defaultTitle={formatMessage(messages.defaultTitle)}
        title={formatMessage(title || messages.defaultTitle)}
        region={region && formatMessage(region)}
        ogTitle={formatMessage(ogTitle || title || messages.defaultTitle)}
        twitterTitle={formatMessage(
          twitterTitle || title || messages.defaultTitle
        )}
      />
      <Description
        description={formatMessage(description || messages.description)}
        ogDescription={formatMessage(
          ogDescription || description || messages.description
        )}
        twitterDescription={formatMessage(
          twitterDescription || description || messages.description
        )}
      />
      <Image picture={picture} twitterPicture={twitterPicture} />
      <DeepLink url={fullURL} deepLinkURL={deepLinkURL} />
      <Type type={type} />
      {siteName && <SiteName siteName={formatMessage(siteName)} />}
      {keywords && <Keywords keywords={formatMessage(keywords)} />}

      <ReactHelmet {...props}>
        <meta property="og:url" content={fullURL} />
        <link rel="canonical" href={fullURL} />
        <link
          rel="alternate"
          href={`${host}/zh-Hant${pathWithoutLang}`}
          hrefLang="zh-TW"
        />
        <link
          rel="alternate"
          href={`${host}/zh-HK${pathWithoutLang}`}
          hrefLang="zh-HK"
        />
        <link
          rel="alternate"
          href={`${host}/ja${pathWithoutLang}`}
          hrefLang="ja-JP"
        />
        <link
          rel="alternate"
          href={`${host}/en-US${pathWithoutLang}`}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={`${host}/en${pathWithoutLang}`}
          hrefLang="en"
        />
        {children}
      </ReactHelmet>
    </>
  );
};

export default injectIntl(Helmet);
