// @flow
import React from 'react';
import LoadingSVG from '@/components/LoadingSVG';

import ModalBody from './ModalBody';

const ModalLoading = (props: {}) => (
  // don't apply id to loading component yet
  <ModalBody id={undefined} {...props}>
    <LoadingSVG />
  </ModalBody>
);

export default ModalLoading;
