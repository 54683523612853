import { ofType } from 'redux-observable';

import { filter, map, skip, tap } from 'rxjs/operators';

import { OPEN_MODAL } from '@/containers/Modal/constants';
import { history } from '@/store/history';

import modals from './modals';

export const openModalEpic = action$ =>
  action$.pipe(
    ofType(OPEN_MODAL),
    map(({ payload }) => payload),
    filter(({ name: modalID }) => Object.keys(modals).includes(modalID)),
    tap(({ name: modalID, replace }) => {
      history[replace ? 'replace' : 'push']({
        ...history.location,
        hash: `#${modalID}`,
      });
    }),
    skip()
  );

export default [openModalEpic];
