import React from 'react';

import VLiveCharacterSubtitle from '@17live/app/components/VLiveCharacter/VLiveCharacterSubtitle';
import VLiveCharacterText from '@17live/app/components/VLiveCharacter/VLiveCharacterText';
import { $t } from '@17live/app/utils';
import Box from '@17live/ui/Box';
import IgModalVliverVrmTutorial from '@17live/ui/Gui/IgModalVliverVrmTutorial';

const VLiveCharacterIntroductionFromAppVRM = ({
  hasTab,
}: {
  hasTab: boolean;
}) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <VLiveCharacterSubtitle>
        {$t('vlive_intro_3d_title')}
      </VLiveCharacterSubtitle>
      <Box mb={'8px'}>
        <IgModalVliverVrmTutorial width={150} height={150} />
      </Box>
      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_3d_content_1')}
        </VLiveCharacterSubtitle>
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_1_1')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_1_2')}
        </VLiveCharacterText>
      </Box>

      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_3d_content_2')}
        </VLiveCharacterSubtitle>
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_2_1')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_2_2')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_2_3')}
        </VLiveCharacterText>
      </Box>

      <Box mb={'24px'}>
        <VLiveCharacterSubtitle>
          {$t('vlive_intro_3d_content_3')}
        </VLiveCharacterSubtitle>
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_3_1')}
        </VLiveCharacterText>
        <br />
        <VLiveCharacterText>
          {$t('vlive_intro_3d_content_3_2')}
        </VLiveCharacterText>
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppVRM;
