import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';

import { $t } from '@17live/app/utils';
import {
  COLOR_NEUTRAL_100,
  COLOR_NEUTRAL_600,
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from '@17live/design-system/color';
import { Flex } from '@17live/ui/Box';

const FileInfo = styled.div<{ isMobile: boolean }>`
  border-radius: ${({ isMobile }) => (isMobile ? '12px' : '4px')};
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  margin-bottom: 12px;
`;

const FileInfoTitle = styled.div`
  background: ${COLOR_PRIMARY_BLACK};
  color: ${COLOR_PRIMARY_WHITE};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  padding: 8px;
`;

const FileInfoHead = styled.div`
  background: ${COLOR_NEUTRAL_600};
  color: ${COLOR_PRIMARY_WHITE};
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  padding: 4px;
`;

const FileInfoBody = styled.div`
  background: ${COLOR_NEUTRAL_100};
  color: ${COLOR_NEUTRAL_700};
  font-size: 12px;
  line-height: 133%;
  padding: 12px;
  text-align: left;
  flex: 1;
  display: flex;
  align-items: center;
`;

export default function VLiveCharacterIntroductionFileInfoLive2D({
  isMobile,
  characterSizeLimitMB,
}: {
  isMobile: boolean;
  characterSizeLimitMB?: number;
}) {
  return (
    <>
      <FileInfo isMobile={isMobile}>
        <FileInfoTitle>{$t('vlive_intro_content_4')}</FileInfoTitle>
        <Flex flexDirection={isMobile ? 'column' : 'row'}>
          <Flex
            flexDirection={'column'}
            flexGrow={1}
            mr={isMobile ? '0' : '1px'}
            flexBasis={'33.33%'}
          >
            <FileInfoHead>{$t('vlive_intro_content_4_7')}</FileInfoHead>
            <FileInfoBody>
              {$t('vlive_intro_content_4_1', {
                param_1: characterSizeLimitMB,
              })}
            </FileInfoBody>
          </Flex>
          <Flex
            flexDirection={'column'}
            flexGrow={1}
            mr={isMobile ? '0' : '1px'}
            flexBasis={'33.33%'}
          >
            <FileInfoHead>{$t('vlive_intro_content_4_2')}</FileInfoHead>
            <FileInfoBody>
              <FormattedMessage id="vlive_intro_content_4_3">
                {text => (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                )}
              </FormattedMessage>
            </FileInfoBody>
          </Flex>
          <Flex flexDirection={'column'} flexGrow={1} flexBasis={'33.33%'}>
            <FileInfoHead>{$t('vlive_intro_content_4_4')}</FileInfoHead>
            <FileInfoBody>
              <FormattedMessage id="vlive_intro_content_4_5">
                {text => (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                )}
              </FormattedMessage>
            </FileInfoBody>
          </Flex>
        </Flex>
      </FileInfo>
    </>
  );
}
