/**
 * Subscribe to PubNub and on SUBSCRIBE_CHAT_ROOM action
 */
import { ofType } from 'redux-observable';

import { fromJS } from 'immutable';
import { of, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { areUsersOnline } from '@/services/17App';
import { errorAction } from '@/utils/errorAction';

import { receivedLiveStreamInfo } from '@17live/app/containers/LiveStream/actions';
import { RECEIVED_LIVE_STREAM_INFO } from '@17live/app/containers/LiveStream/constants';
import {
  LiveStreamInfoConsumer,
  LiveStreamInfoRequestTag,
} from '@17live/app/containers/LiveStream/enums';
import { setPreference } from '@17live/app/containers/PreferenceProvider/actions';
import { makeSelectHistoryLiveStreamsList } from '@17live/app/containers/PreferenceProvider/selectors';

import { CHECK_IS_ONLINE_INTERVAL, SET_IS_CHECKING_ENABLED } from './constants';
import { getValidHistoryList } from './utils';

const historyLiveStreamsListSelector = makeSelectHistoryLiveStreamsList();

export const saveHistoryLiveStreamEpic = (action$, state$) =>
  action$.pipe(
    ofType(RECEIVED_LIVE_STREAM_INFO),
    filter(
      ({ payload }) =>
        payload.calledFrom === LiveStreamInfoConsumer.LIVE &&
        payload.requestTag === LiveStreamInfoRequestTag.INITIAL
    ),
    map(({ payload }) => {
      const currentLiveStream = fromJS(payload);

      const historyLiveStreams = getValidHistoryList(
        historyLiveStreamsListSelector(state$.value).toJS()
      );

      historyLiveStreams[currentLiveStream.get('roomID')] = {
        roomID: currentLiveStream.get('roomID'),
        openID: currentLiveStream.getIn(['userInfo', 'openID']),
        userID: currentLiveStream.get('userID'),
        picture: currentLiveStream.getIn(['userInfo', 'picture']),
        level: currentLiveStream.getIn(['userInfo', 'level']),
        isTV: currentLiveStream.hasIn(['userInfo', 'programInfo']),
        timestamp: Date.now(),
      };

      return setPreference('historyLiveStreams', historyLiveStreams);
    })
  );

export const checkUsersAreOnlineEpic = (action$, state$) =>
  action$.pipe(
    ofType(SET_IS_CHECKING_ENABLED),
    filter(({ payload }) => !!payload),
    switchMap(() =>
      timer(0, CHECK_IS_ONLINE_INTERVAL).pipe(
        mergeMap(() => {
          const historyLiveStreams = historyLiveStreamsListSelector(
            state$.value
          ).toArray();

          return areUsersOnline(
            historyLiveStreams.map(live => live.get('userID'))
          ).pipe(
            map(onlineRoomIDs =>
              receivedLiveStreamInfo(
                historyLiveStreams.map((live, index) => ({
                  roomID: live.get('roomID'),
                  isOnline: onlineRoomIDs[index] !== -1,
                }))
              )
            ),
            catchError(err => of(errorAction(SET_IS_CHECKING_ENABLED, err)))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(SET_IS_CHECKING_ENABLED),
            filter(({ payload }) => !payload)
          )
        )
      )
    )
  );

export default [saveHistoryLiveStreamEpic, checkUsersAreOnlineEpic];
