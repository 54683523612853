import styled from 'styled-components';

import { COLOR_NEUTRAL_700 } from '@17live/design-system/color';

const VLiveCharacterText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  white-space: pre-wrap;
`;

export default VLiveCharacterText;
