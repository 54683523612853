// @flow
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Props = {
  siteName: string,
};

const SiteName = ({ siteName }: Props) => {
  if (!siteName) {
    return null;
  }

  return (
    <ReactHelmet>
      <meta property="og:site_name" content={siteName} />
    </ReactHelmet>
  );
};

export default SiteName;
