/*
 *
 * GiftBox constants
 *
 */
import {
  GiftTab,
  GiftType,
} from '@17live/app/containers/GiftBox/GiftBox.enums';

export const SWITCH_TAB: string = 'app/GiftBox/SWITCH_TAB';
export const SEND_GIFT: string = 'app/GiftBox/SEND_GIFT';
export const SET_SENT_RESPONSE: string = 'app/GiftBox/SET_SENT_RESPONSE';
export const SET_SEND_ERROR: string = 'app/GiftBox/SET_SEND_ERROR';
export const SEND_BAGGAGE: string = 'app/GiftBox/SEND_BAGGAGE';
export const SEND_GOODS: string = 'app/GiftBox/SEND_GOODS';
export const SEND_GIFT_TO_ALL_IN_GROUP_CALL: string =
  'app/GiftBox/SEND_GIFT_TO_ALL_IN_GROUP_CALL';

export const SET_DEFAULT_GIFT_TAB: string = 'app/GiftBox/SET_DEFAULT_GIFT_TAB';
export const GET_DEFAULT_GIFT_TAB: string = 'app/GiftBox/GET_DEFAULT_GIFT_TAB';

export const GIFT_TAB_IDS = Object.freeze({
  [GiftTab.Normal]: 'Product', // 實體禮物
  [GiftTab.LuckyBag]: 'luckyBag', // 隨機禮
  [GiftTab.Slot]: 'TreasureChest', // 好運禮
  [GiftTab.Poke]: 'PokeBack', // 回戳禮
  [GiftTab.Poll]: 'Poll', // 投票
  [GiftTab.Fresh]: 'Newbie', // 新手禮
  [GiftTab.Event]: 'Event', // 活動禮
  [GiftTab.Army]: 'army', // 專屬禮
  [GiftTab.Baggage]: 'Baggage', // 背包
  [GiftTab.Birthday]: 'Birthday', // 生日禮
  [GiftTab.History]: 'Recent', // 最近送出
  [GiftTab.Texture]: 'Texture', // 濾鏡禮
  [GiftTab.Goods]: 'Goods', // 限量禮物
  [GiftTab.LevelUp]: 'LevelUp', // 升級禮
  [GiftTab.HandDrawSticker]: 'HandDrawSticker', // 塗鴉禮
  [GiftTab.Game]: 'Game', // 遊戲禮
  [GiftTab.Layout]: 'Layout', // 佈景禮
  [GiftTab.Topic]: 'Topic', // 話題禮
  [GiftTab.Extravagant]: 'Extravagant', // 經典禮
  [GiftTab.Audio]: 'Audio', // 音效禮
  [GiftTab.Heavenly]: 'Heavenly', // 奢華禮
  [GiftTab.Default]: 'default', // when use concert mode
});

// slack thread: https://17media.slack.com/archives/C029P2CJG91/p1667296319569719
// backend enum ref: https://github.com/17media/api/blob/4283ed1e99497c919a9993c58e6e6d1dc69e34c2/models/gift/gift.pb.go#L395-L418
export const TAB_TYPES: {
  [key: string]: number;
} = Object.freeze({
  [GiftTab.Normal]: 0, // 實體禮物
  [GiftTab.NoLabel]: 1,
  [GiftTab.LuckyBag]: 2, // 隨機禮
  [GiftTab.Slot]: 3, // 好運禮
  [GiftTab.Poke]: 4, // 回戳禮
  [GiftTab.Poll]: 5, // 投票
  [GiftTab.Fresh]: 6,
  [GiftTab.Event]: 7, // 活動禮
  [GiftTab.Army]: 8, // 專屬禮
  [GiftTab.Voucher]: 9,
  [GiftTab.Baggage]: 10, // 背包
  [GiftTab.Elite]: 11,
  [GiftTab.Birthday]: 12, // 生日禮
  [GiftTab.History]: 13, // 最近送出
  [GiftTab.Texture]: 14, // 濾鏡禮
  [GiftTab.Goods]: 15, // 限量禮物
  [GiftTab.LevelUp]: 16, // 升級禮
  [GiftTab.HandDrawSticker]: 17, // 塗鴉禮
  [GiftTab.Game]: 18, // 遊戲禮
  [GiftTab.Layout]: 19, // 佈景禮
  [GiftTab.Custom]: 9999, // 自訂禮物, 來源是liveStream內的customEvent
});

export const GIFT_TAB_TYPES: {
  [key: number]: string;
} = Object.keys(TAB_TYPES).reduce(
  (tabs, tab) => ({
    ...tabs,
    [TAB_TYPES[tab]]: tab,
  }),
  {}
);

export const GIFT_TAB_TRACK_SOURCE_URL = Object.freeze({
  [GiftTab.Fresh]: 'Newbie', // 新手禮
  [GiftTab.LevelUp]: 'LvUp', // 升級禮
  [GiftTab.Topic]: 'Topic', // 話題禮
  [GiftTab.Heavenly]: 'Heavenly', // 奢華禮
  [GiftTab.Extravagant]: 'Extravagant', // 經典禮
  [GiftTab.Birthday]: 'Birthday', // 生日禮
  [GiftTab.Audio]: 'Audio', // 音效禮
  [GiftTab.GFA]: 'GFA', // 金羽獎
});

/**
 * TODO: website not support interactive gift now
 * 1. HandDrawSticker
 * 2. Game
 */
export const GIFT_TAB_TYPES_WHITE_LIST: GiftTab[] = [
  GiftTab.Normal,
  GiftTab.NoLabel,
  GiftTab.LuckyBag,
  GiftTab.Slot,
  GiftTab.Poke,
  GiftTab.Poll,
  GiftTab.Fresh,
  GiftTab.Event,
  GiftTab.Army,
  GiftTab.Voucher,
  GiftTab.Baggage,
  GiftTab.Elite,
  GiftTab.Birthday,
  GiftTab.History,
  GiftTab.Texture,
  GiftTab.Goods,
  GiftTab.LevelUp,
  GiftTab.Default,
];

export const CUSTOM_GIFT_TAB_IDS_WHITE_LIST = [
  GIFT_TAB_IDS[GiftTab.LuckyBag],
  GIFT_TAB_IDS[GiftTab.Slot],
  GIFT_TAB_IDS[GiftTab.Event],
  GIFT_TAB_IDS[GiftTab.Army],
  GIFT_TAB_IDS[GiftTab.Birthday],
  GIFT_TAB_IDS[GiftTab.Texture],
  GIFT_TAB_IDS[GiftTab.LevelUp],
  GIFT_TAB_IDS[GiftTab.Extravagant],
  GIFT_TAB_IDS[GiftTab.Audio],
  GIFT_TAB_IDS[GiftTab.Heavenly],
  GIFT_TAB_IDS[GiftTab.Default],
];

/**
 * 基本的預設 gitf tab 順序
 * @see getDefaultGiftTabEpic packages/app/containers/GiftBox/epics.ts
 */
export const DEFAULT_GIFT_TABS: GiftTab[] = [
  GiftTab.Event,
  GiftTab.Fresh,
  GiftTab.NoLabel,
];

export const ASYNC_GIFT_TABS: GiftTab[] = [
  GiftTab.History,
  GiftTab.LuckyBag,
  GiftTab.Slot,
  GiftTab.Baggage,
  GiftTab.Goods,
];

// 需要 always 顯示的 tabs 清單 (除 concert mode 之外)
export const ALWAYS_DISPLAY_GIFT_TABS: GiftTab[] = [
  GiftTab.History,
  GiftTab.Baggage,
  GiftTab.Goods,
];

/**
 * 不顯示 whitelist icon 標記的 tabs
 */
export const NOT_DISPLAY_WHITELIST_ICON_TABS: GiftTab[] = [
  GiftTab.History,
  GiftTab.Baggage,
];

/**
 * Box Tabs
 */
export const BOX_TABS = {
  GIFT: 0,
  RED_ENVELOPE: 1,
};
/**
 * Styles
 */
export const TOP_BAR_HEIGHT: number = 48; // px
export const DEFAULT_BOX_HEIGHT: number = 350 + TOP_BAR_HEIGHT; // px
export const MAX_BOX_HEIGHT: number = 0.7; // %
export const GIFT_WIDTH: number = 50; // px

/**
 * Others
 */
export const GIFT_COMBO_THRESHOLD: number = 3000; // ms
export const MAX_ACCUMULATED_LENGTH: number = 9;
export const MAX_LEVEL_UP_THRESHOLD: number = 2147483647; // use max int32

/**
 * Error
 * refs api/models/error.pb.go
 */
export const ERROR_CODE_VALUE = {
  NOT_ENOUGH_POINT: 16,
  ARMY_USER_NOT_ARMY: 13000,
  ENVELOPE_NOT_ENOUGH_POINT: 12005,
  ENVELOPE_MULTIPLE_EVENT: 12004,
  STREAMER_INVALID: 10008,
  /**
   * including error_vote_limit error
   * ref: https://17media.slack.com/archives/C02A1F78W49/p1662031542073829?thread_ts=1661912629.935949&cid=C02A1F78W49
   */
  GENERIC_ERROR: 35,
  GIFT_WRONG_REGION: 10000,
  MLEVEL_NOT_ENOUGH: 25000,
  SELF_FEEDING: 32,
  WHITELIST_GIFTS_ERROR_GROUPCALL_ALL: 10012,
  WHITELIST_GIFTS_ERROR_DISABLE: 10013,
};

// refs api/models/gift/gift.pb.go
export const BANNER_ACTION_TYPES = {
  NONE: 0,
  EVENT: 1,
  ARMY: 2,
  LINK: 3,
  ELITE: 4,
};

// 為了判斷 Error 訊息從哪裡來的
export enum ErrorMessageFrom {
  POKE = 'poke',
  GIFT = 'gift',
}

export const GIFT_TYPE_EXCLUDED = [
  GiftType.NonRealtime,
  GiftType.Limited,
  GiftType.LimitedLuckyBag,
];

export const KEEP_LUCKY_BAG_INFO_POLLING_INTERVAL = 5000; // 5 seconds

export const WHITELIST_GIFT_LABEL_KEY = 'gift_tag_whitelist';
