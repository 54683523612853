import React, { useCallback, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';

import { ELEMENT_ORDERS } from '@/constants/orders';

import Portal from '@17live/ui/Portal';

export type ModalProps = {
  isOpened: boolean;
  onESC?: (ev: KeyboardEvent) => void;
  onClickMask?: (e: React.MouseEvent) => void;
  top?: number | 'center';
  bottom?: number | string;
  portalEl?: Element | null;
};

const ModalBox = styled.div`
  ${() => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: ${ELEMENT_ORDERS.MODAL};
    overflow: auto;
  `}
`;

const ModalContent = styled.div<Pick<ModalProps, 'top' | 'bottom'>>`
  ${p => css`
    display: inline-block;
    display: inline-flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    ${p.bottom !== undefined &&
      css`
        bottom: ${p.bottom};
      `}

    ${p.top === 'center'
      ? css`
          margin-top: 50vh;
          transform: translate(-50%, -50%);
        `
      : css`
          margin-top: ${p.top ?? 0}vh;
        `}
  `}
`;

const ModalGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Modal: React.FC<ModalProps> = ({
  isOpened,
  onESC,
  onClickMask,
  children,
  top = 10,
  portalEl,
  bottom,
  ...props
}) => {
  useEffect(() => {
    if (onESC) {
      const handleEsc = (e: KeyboardEvent) => {
        if (e.which !== 27 || !isOpened) return;

        if (typeof onESC === 'function') onESC(e);
      };

      window.addEventListener('keyup', handleEsc);

      return () => {
        window.removeEventListener('keyup', handleEsc);
      };
    }
  }, [isOpened, onESC]);

  const stopPropagation = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    e => {
      e.stopPropagation();
    },
    []
  );

  return isOpened ? (
    <Portal element={portalEl}>
      <ModalGlobalStyle />
      <ModalBox {...props} onClick={onClickMask}>
        <ModalContent bottom={bottom} top={top} onClick={stopPropagation}>
          {children}
        </ModalContent>
      </ModalBox>
    </Portal>
  ) : null;
};

export default Modal;
