import React, { createContext } from 'react';

export enum ActionType {
  TURN_ON,
  TURN_OFF,
}

export type Action = { type: ActionType; payload?: Record<string, any> };

export const DriverContext = createContext<React.Dispatch<Action>>(() => null);
