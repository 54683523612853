import { stringify } from 'query-string';
import { Observable } from 'rxjs';
import apis from '@/services/17App/shared/apis';
import { LoyaltyPointLogsResponse } from '@17live/app/types/LoyaltyPointHistory';
import { DEFAULT_COUNT } from '@17live/app/containers/LoyaltyPoint/constants';

type getLoyaltyPointLogsInputType = {
  cursor: string;
  count?: number;
};

/**
 * 取得VIP積分紀錄
 */
export const getLoyaltyPointLogs = ({
  cursor,
  count = DEFAULT_COUNT,
}: getLoyaltyPointLogsInputType): Observable<LoyaltyPointLogsResponse> =>
  apis.getJSON(`loyaltypoint/logs?${stringify({ cursor, count })}`);
