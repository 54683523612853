import { ofType } from 'redux-observable';

import { replace } from 'connected-react-router';
import omit from 'lodash/omit';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';

import { SET_LIST as SET_LEADERBOARD_LIST } from '@/containers/Leaderboard/constants';
import { SET_LIST as SET_RECOMMENDED_LIST } from '@/providers/Recommend/constants';
import { getUserInfo } from '@/services/17App';
import { getProfileInfo } from '@/services/17App/user';
import isUUID from '@/utils/isUUID';

import { makeSelectLang } from '@17live/app/containers/LanguageProvider/selectors';
import { receivedLiveStreamInfo } from '@17live/app/containers/LiveStream/actions';
import { RECEIVED_LIVE_STREAM_INFO } from '@17live/app/containers/LiveStream/constants';
import { setFollowingStatus } from '@17live/app/containers/LoginProvider/actions';
import { convertToUserFollowingStatus } from '@17live/app/containers/LoginProvider/utils';

import { receivedUserInfo } from './actions';
import { GET_USER_INFO } from './constants';

export const getUserInfoEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_USER_INFO),
    mergeMap(({ payload: userOrRoomID }) => {
      let stream$ = userOrRoomID
        ? getProfileInfo(userOrRoomID)
        : throwError(new Error(`Invalid userID: ${userOrRoomID}`));

      const isUid = isUUID(userOrRoomID);

      if (isUid) stream$ = getUserInfo(userOrRoomID);

      return stream$.pipe(
        concatMap(userInfo => {
          const actions = [
            receivedUserInfo(userInfo),
            setFollowingStatus(
              userInfo.userID,
              convertToUserFollowingStatus(
                userInfo.isFollowing,
                userInfo.followRequestTime,
                userInfo.privacyMode
              )
            ),
          ];

          if (!isUid) {
            actions.push(
              // 先繼續仿照前人將 userInfo 傳入接收 liveStream 的 action
              // 不過之前的結構不太正確，這邊仿照 liveStreamInfo 結構將 userInfo 改成 Object 中的一個欄位
              // 這邊加 `roomID` 是因為在 LiveStream Reducer 需要拿 roomID
              // (See `RECEIVED_LIVE_STREAM_INFO` in LiveStream/reducer.ts)
              // FIXME: 如果可以希望可以不要用 receivedLiveStreamInfo 接 userInfo，以免造成 debug 困難
              receivedLiveStreamInfo({ roomID: userInfo.roomID, userInfo })
            );
          }

          return of(...actions);
        }),
        catchError(() => {
          const lang = makeSelectLang()(state$.value);
          return of(replace(`/${lang}/404`));
        })
      );
    })
  );

export const setUserInfoFromLiveStreamInfoEpic = action$ =>
  action$.pipe(
    ofType(RECEIVED_LIVE_STREAM_INFO),
    map(({ payload }) =>
      []
        .concat(payload)
        .map(({ userInfo }) => userInfo)
        .filter(Boolean)
    ),
    map(userInfoList => receivedUserInfo(userInfoList))
  );

export const setUserInfoFromListEpic = action$ =>
  action$.pipe(
    ofType(SET_LEADERBOARD_LIST, SET_RECOMMENDED_LIST),
    map(({ type, payload: { list } }) =>
      receivedUserInfo(
        type === SET_LEADERBOARD_LIST
          ? // roomID from SET_LEADERBOARD_LIST is not accurate with this API so we omit it here
            list.map(l => omit(l.userInfo, 'roomID') || {})
          : list
      )
    )
  );

export default [
  getUserInfoEpic,
  setUserInfoFromLiveStreamInfoEpic,
  setUserInfoFromListEpic,
];
