/*
 *
 * Recommend constants
 *
 * @flow
 */

import { constants } from './list';

const { GET_LIST, SET_LIST, CLEAR_LIST, SET_ERROR, STATUS } = constants;

export { GET_LIST, SET_LIST, CLEAR_LIST, SET_ERROR, STATUS };

export const RECOMMENDED_PANEL_COUNT: number = 4;
export const RECOMMENDED_LIST_NAME: string = 'recommend';
