import React, { PureComponent } from 'react';
import { ContextRouter, Redirect } from 'react-router-dom';

import { parse } from 'query-string';

import {
  AUTH_WINDOW_NAME,
  THIRD_PARTY_TYPES,
} from '@/containers/ThirdPartyAction/constants';
import Preference from '@/services/Preference/session';

type Props = ContextRouter & {
  authWith17: (
    oauthType: string,
    action: string,
    data: { [key: string]: string }
  ) => void;
};

type State = {
  returnPath: string;
};

class RedirectFromState extends PureComponent<Props, State> {
  returnPath: string;
  oauthType: string;
  data: { [key: string]: string };
  isInChildWindow: boolean;
  action: string;

  constructor(props: Props) {
    super(props);

    this.isInChildWindow =
      (window.opener && window.opener !== window) ||
      window.name === AUTH_WINDOW_NAME;

    const { location, match } = props;

    // facebook will send back `redirect?#state=something` even if the document doesn't say so.
    const { oauthType, action, ...data } = parse(
      location.search || location.hash
    );
    this.returnPath = data.returnPath || Preference.get('returnPath');
    this.oauthType = match.params.oauthType || oauthType;
    this.action = match.params.action || action;
    this.data = data;

    if (this.oauthType === THIRD_PARTY_TYPES.FACEBOOK) {
      // try to get returnPath from state
      try {
        const parsedState = JSON.parse(data.state);

        const { returnPath, action: actionFromState } = parsedState;

        if (returnPath) {
          this.returnPath = returnPath;
        }
        if (actionFromState) {
          this.action = actionFromState;
        }
      } catch (err) {
        // do nothing
      }
    }
  }

  componentDidMount() {
    // it should always be true for the oauth providers we currently integrated with
    if (this.oauthType && !this.isInChildWindow && this.action) {
      this.props.authWith17(this.oauthType, this.action, this.data);
    }
  }

  render() {
    // inside authWindow, do not redirect
    if (this.isInChildWindow) {
      return null;
    }

    return <Redirect to={this.returnPath} />;
  }
}

export default RedirectFromState;
