import { Epic, ofType } from 'redux-observable';

import { replace } from 'connected-react-router';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';

import { getAnnouncement as getAnnouncementService } from '@/services/17App';

import { ErrorRoutePath } from '@17live/app/constants/routePath';
import { INIT_APP } from '@17live/app/containers/App/constants';
import { makeSelectLocation } from '@17live/app/containers/App/selectors';
import {
  makeSelectLang,
  makeSelectRegion,
} from '@17live/app/containers/LanguageProvider/selectors';
import { LOGIN_SUCCESS } from '@17live/app/containers/LoginProvider/constants';
import { getMessageProvider } from '@17live/app/services/17App/liveStreams';
import Message from '@17live/app/services/Message/Message';
import { MessageProviderFeatureTypes } from '@17live/app/services/Message/Message.enums';

import {
  getAnnouncement,
  setAnnouncement,
  subscribeGlobalAnnouncementChannel,
} from './StatusAnnouncement.actions';
import {
  GET_ANNOUNCEMENT,
  REDIRECT_TO_OVERLOAD,
  SET_ANNOUNCEMENT,
  SUBSCRIBE_GLOBAL_ANNOUNCEMENT_CHANNEL,
} from './StatusAnnouncement.constants';

const regionCodeSelector = makeSelectRegion();
const locationSelector = makeSelectLocation();

export const getAnnouncementEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(GET_ANNOUNCEMENT),
    mergeMap(() => {
      const regionCode = regionCodeSelector(state$.value);

      return getAnnouncementService(regionCode).pipe(
        map(data =>
          setAnnouncement({
            isMaintaining: !!data,
            ...(data || {}),
          })
        ),
        // ignore error
        catchError(() =>
          setAnnouncement({
            isMaintaining: false,
          })
        )
      );
    })
  );

export const redirectToMaintenanceEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_ANNOUNCEMENT),
    filter(({ payload: { isMaintaining } }) => isMaintaining),
    map(() => {
      const lang = makeSelectLang()(state$.value);
      return replace(`/${lang}/maintenance`);
    })
  );

export const redirectFromMaintenanceEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_ANNOUNCEMENT),
    filter(({ payload: { isMaintaining } }) => !isMaintaining),
    filter(() => locationSelector(state$.value).pathname === '/maintenance'),
    map(() => {
      const lang = makeSelectLang()(state$.value);
      return replace(`/${lang}`);
    })
  );

export const subscribeGlobalAnnouncementChannelEpic: Epic = action$ =>
  action$.pipe(
    ofType(SUBSCRIBE_GLOBAL_ANNOUNCEMENT_CHANNEL),
    tap(() => {
      Message.unsubscribeGlobalAnnouncementChannel();
    }),
    switchMap(() =>
      Message.subscribeGlobalAnnouncementChannel().pipe(
        // server sometimes will respond multiple messages at a time, we throttle to only catch one
        throttleTime(1000),
        map(() => getAnnouncement())
      )
    )
  );

/**
 * @name initGlobalAnnouncementChannelEpic
 * @description Immediately subscribe to announcementChannel on initial page load
 * The following items:
 * 1. initApp
 * 2. loginSuccess
 * we need call getGlobalMessageProvider and type=message_provider to initial remote value.
 * After this time when we need to update provider, use type=message_provider_dynamic
 */
export const initGlobalAnnouncementChannelEpic: Epic = action$ =>
  action$.pipe(
    ofType(INIT_APP, LOGIN_SUCCESS),
    switchMap(() => {
      return getMessageProvider(
        MessageProviderFeatureTypes.MESSAGE_PROVIDER
      ).pipe(
        tap(({ globalMessageProvider }) =>
          Message.setGlobalMessageProvider(globalMessageProvider)
        ),
        map(() => subscribeGlobalAnnouncementChannel())
      );
    })
  );

export const redirectToServiceUnavailableEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(REDIRECT_TO_OVERLOAD),
    map(() => {
      const lang = makeSelectLang()(state$.value);
      return replace(`/${lang}${ErrorRoutePath.OVERLOAD}`);
    })
  );

export default [
  getAnnouncementEpic,
  redirectToMaintenanceEpic,
  redirectFromMaintenanceEpic,
  subscribeGlobalAnnouncementChannelEpic,
  initGlobalAnnouncementChannelEpic,
  redirectToServiceUnavailableEpic,
];
